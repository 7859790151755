import { Link, useNavigate } from "react-router-dom";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import Button from "./Button";
// import Button from "../../components/common/Button";

const ProjectsNotCreate = ({
  heading = "Data not found",
  subHeading = "No data found to show on this page",
  showButton = false,
  buttonText = "+ Add",
  handleButtonClick = () => {},
  linkTo = "#",
}) => {
  return (
    <div className="flex flex-col rounded-lg bg-white px-[10px] py-[200px] mt-[40px] items-center justify-center">
      {" "}
      <Typography
        variant={TYPOGRAPHY_VARIANT.HEADING_4}
        className="text-center mb-2"
      >
        {heading}
      </Typography>
      <div>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_1}
          className="text-center mb-2"
        >
          {subHeading}
        </Typography>
      </div>
      {showButton && (
        <div className="ml-[28%]  mr-[28%] mt-[30px]">
          <Link to={linkTo} className="">
            <Button
              onClick={handleButtonClick}
              buttonClassName={"flex"}
              variant={"primary"}
            >
              {buttonText}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProjectsNotCreate;

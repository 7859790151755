import RotatingLoader from "../svg/RotatingLoader";

const OverlayLoader = () => {
  return (
    <div
      role="status"
      className="fixed top-[0] bottom-[0] left-[0] right-[0] z-9 flex items-center justify-center bg-[#1e1e4cf5] z-[60]"
    >
      <div className="flex flex-col gap-6 w-[218px] h-[218px]">
        <RotatingLoader />
        {/* <span className="text-white text-[32px]">Loading...</span> */}
      </div>
    </div>
  );
};

export default OverlayLoader;

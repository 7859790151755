import "./chart.scss";
import {
  AreaChart,
  Area,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Legend,
  YAxis,
} from "recharts";
import "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import Tabs, { TAB_VARIANT } from "../common/Tabs";
import Summary from "../summary/Summary";
import { useParams } from "react-router-dom";

const DEFAULT_CHART = [
  { name: "", value: "" },
  { name: "", value: "" },
  { name: "", value: "" },
  { name: "", value: "" },
  { name: "", value: "" },
];

const Chart = ({ aspect, title, chartData, collaboratorData }) => {
  const [collaboratorNamesMapper, setCollaboratorNamesMapper] = useState({});
  const [selectedSummaryData, setselectedSummaryData] = useState({});
  useEffect(() => {
    let collaboratorIdNameMapper = {};
    if (!chartData) {
      return;
    }
    Object.keys(chartData).forEach(() => {
      collaboratorData.forEach((collaborator) => {
        collaboratorIdNameMapper[
          collaborator.userId
        ] = `${collaborator.company} Summary`;
      });
    });
    const initialTab = Object.keys(collaboratorIdNameMapper)[0];
    setCollaboratorNamesMapper(collaboratorIdNameMapper);
    // setActiveTab(initialTab);
  }, [chartData, collaboratorData]);

  const id = useParams();
  const [activeTab, setActiveTab] = useState("accuracy");
  const [chart, setChart] = useState(DEFAULT_CHART);

  useEffect(() => {
    if (activeTab && chartData) {
      setChart(chartData[activeTab] || DEFAULT_CHART);
      setselectedSummaryData(chartData[activeTab]);
    }
  }, [activeTab, chartData]);

  const LABEL_MAPPER = {
    accuracy: "Accuracy Chart",
    // false_positive_rate: "False Positive Rate",
    // precision: "Precision",
  };
  const containerRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      console.log("Container resized");
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const allLabelMappers = { ...LABEL_MAPPER, ...collaboratorNamesMapper };

  const chartTabs = Object.keys(allLabelMappers).map((mapperKey) => {
    return { label: allLabelMappers[mapperKey], value: mapperKey };
  });
  const handleTabClick = (selectedKey) => {
    setActiveTab(selectedKey);
    // const chartValue = chartData?.[selectedKey]?.map((stats, ind) => {
    //   return { name: ind, Total: stats };
    // });
    // console.log("chartValue", chartValue);
    // setChart(chartValue ? chartValue : DEFAULT_CHART);
    setselectedSummaryData(chartData?.[selectedKey]);
  };

  return (
    <div className="flex-1 bg-white drop-shadow-lg flex-wrap 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
      <div className="text-center flex gap-10 justify-center">
        <Tabs
          names={chartTabs}
          active={activeTab}
          onChangeActive={handleTabClick}
          variant={TAB_VARIANT.BORDERED}
        />
      </div>
      {!Object.keys(LABEL_MAPPER).includes(activeTab) ? (
        <Summary summarydata={selectedSummaryData} />
      ) : (
        <>
          {" "}
          <div ref={containerRef} style={{ width: "100%", height: "40vh" }}>
            <ResponsiveContainer width="100%" height={"100%"}>
              <LineChart
                width={500}
                height={300}
                data={chart}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" stroke="gray" />
                <YAxis dataKey="value" />
                {/* <CartesianGrid stroke="#eee" strokeDasharray="5 5"/> */}
                <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
                <Tooltip />
                {/* <Legend /> */}
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default Chart;

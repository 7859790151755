import { GridActionsCellItem } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import EditPenIcon from "../../components/svg/EditPenIcon";
import DeleteBinIcon from "../../components/svg/DeleteBinIcon";
import TableDatagrid from "../../components/common/TableDatagrid/TableDatagrid";
import Search from "../../components/svg/Search";
import Tabs from "../../components/common/Tabs";
import DeleteDatasetModal from "../../components/modals/DeleteDatasetModal";
import { SnackbarContext } from "../../context/SnackbarContext";

const Datasets = ({ user }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteIdAssociatedPro, setDeleteItemIdAssociatedPro] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("My Datasets");
  const [isLoading, setIsLoading] = useState(false);
  const [userNames, setUserNames] = useState({});
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const fetchAllData = async () => {
    setIsLoading(true);
    setFilteredResults([]);
    try {
      await fetchUsersData();
      const q = query(
        collection(db, "datasets"),
        where("userId", "!=", user.uid)
      );
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setData(list);
      setFilteredResults(list);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dateOptions = { day: "2-digit", month: "long", year: "numeric" };

  const fetchMyData = async () => {
    setIsLoading(true);
    setFilteredResults([]);
    try {
      await fetchUsersData();
      const q = query(
        collection(db, "datasets"),
        where("userId", "==", user.uid)
      );
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setData(list);
      await updateAssociatedProjectCount(list);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const q = query(
        collection(db, "projects"),
        where("userId", "==", user.uid)
      );
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setProjects(list);
      return list;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const updateAssociatedProjectCount = async (datasets) => {
    const allProjects = !!projects.length ? projects : await fetchProjects();

    const projectCount = allProjects.reduce((asso, project) => {
      asso[project.datasetId] = (asso[project.datasetId] || 0) + 1;
      return asso;
    }, {});
    const updatedResults = datasets.map((associated) => {
      const matchingProjectCount = projectCount[associated.id] || 0;
      return {
        ...associated,
        associatedProjectCount:
          (associated.associatedProjectCount || 0) + matchingProjectCount,
      };
    });
    setFilteredResults(updatedResults);
  };

  const fetchUsersData = async () => {
    try {
      const q = query(collection(db, "users"));
      const snapShot = await getDocs(q);
      let list = {};
      snapShot.forEach((doc) => {
        list[doc.id] = doc.data().firstName;
      });
      setUserNames(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeTab === "My Datasets") {
      fetchMyData();
    } else if (activeTab === "All") {
      fetchAllData();
    }
  }, [activeTab]);

  const handleDeleteSingleDataset = async () => {
    setIsLoading(true);
    try {
      await deleteDoc(doc(db, "datasets", deleteItemId));
      snackbarDispatch({
        type: "SHOW_SUCCESS_SNACKBAR",
        payload: "Dataset deleted successfully.",
      });
      fetchMyData();
      setDeleteDialogOpen(false);
    } catch (err) {
      console.log(err);
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while deleting dataset.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDeleteDialog = (id, associatedProjectCount) => {
    setDeleteItemId(id);
    setDeleteItemIdAssociatedPro(associatedProjectCount);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemIdAssociatedPro(null);
    setDeleteItemId(null);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchResult = data.filter((item) =>
      item.datasetName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredResults(searchResult);
  };

  const userColumns = useMemo(() => {
    const columns = [
      {
        field: "userId",
        headerName: "Data Owner",
        flex: 1,
        renderCell: (params) => {
          return userNames[params.row.userId];
        },
      },
      {
        field: "datasetName",
        headerName: "Dataset Name",
        flex: 1,
        renderCell: (params) => {
          return (
            <span
              onClick={() => navigate(`/datasets/${params.row.id}`)}
              className="hover:font-semibold  cursor-pointer"
            >
              {params.row.datasetName}
            </span>
          );
        },
      },
      {
        field: "lastModifiedDate",
        headerName: "Last Modified Date",
        flex: 1,
        renderCell: (params) => {
          return (
            <span>
              {params.row?.lastModifiedDate
                ? new Date(params.row?.lastModifiedDate).toLocaleDateString(
                    "en-GB",
                    dateOptions
                  )
                : "-"}
            </span>
          );
        },
      },
    ];
    if (activeTab === "My Datasets") {
      columns.push({
        field: "associatedProjectCount",
        headerName: "Associated Project Count",
        flex: 1,
        renderCell: (params) => {
          const count = params.row.associatedProjectCount;
          return <span>{count === 0 ? "-" : count}</span>;
        },
      });
      columns.push({
        field: "actions",
        headerName: "Action",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditPenIcon />}
            label="Edit"
            onClick={() => navigate(`/datasets/register/${params.row.id}`)}
          />,
          <GridActionsCellItem
            icon={<DeleteBinIcon />}
            label="Delete"
            onClick={() =>
              handleOpenDeleteDialog(
                params.row.id,
                params.row.associatedProjectCount
              )
            }
          />,
        ],
      });
    }
    return columns;
  }, [activeTab, userNames]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      setFilteredResults(data);
    }
  };

  return (
    <div className="h-[695px] p-[32px]">
      <div className="datatableTitle flex flex-wrap justify-between mb-4 gap-4">
        <div className="flex flex-wrap gap-[20px] items-center lg:w-[48%] ">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
            Datasets
          </Typography>
          <div className="search border border-[#999999] py-[16px] px-[10px] rounded-full w-full max-w-[75%] bg-white shadow-md relative">
            <form onSubmit={(e) => handleSearch(e)}>
              <input
                type="text"
                placeholder="Search by Dataset Name"
                value={searchTerm}
                className="w-full"
                onChange={(e) => handleSearchInputChange(e)}
              />
              <button type="submit">
                <span className="absolute right-[6px] top-[6px] bg-[#A0CCFB] w-[45px] h-[45px] rounded-full flex items-center justify-center cursor-pointer">
                  <Search />
                </span>
              </button>
            </form>
          </div>
        </div>

        <Link to="/datasets/register" className="">
          <Button
            variant={BUTTON_VARIANT.SECONDARY}
            className="text-[#323582] uppercase"
            color="primary"
          >
            + Add New Dataset
          </Button>
        </Link>
      </div>

      <div className="flex flex-wrap gap-3 justify-between mb-4">
        <Tabs
          names={["My Datasets", "All"]}
          active={activeTab}
          onChangeActive={handleTabClick}
        />
      </div>
      <div className="w-full h-full shadow-lg bg-white">
        <TableDatagrid
          rows={filteredResults}
          columns={userColumns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          isLoading={isLoading}
          checkboxSelection={false}
        />
      </div>

      {/* Delete Confirmation Dialog */}
      {isDeleteDialogOpen && (
        <DeleteDatasetModal
          handleClose={handleCloseDeleteDialog}
          handleDelete={
            !!deleteIdAssociatedPro ? () => {} : handleDeleteSingleDataset
          }
          hideDelete={!!deleteIdAssociatedPro ? true : false}
          heading={
            !!deleteIdAssociatedPro ? "Deletion Restricted" : "Are you sure?"
          }
          body={
            !!deleteIdAssociatedPro
              ? `You are restricted to delete this dataset, because it is already assosiated with ${deleteIdAssociatedPro} projects.`
              : "This action cannot be undone. All values associated with this dataset will be lost."
          }
          deleteButtonText={"Delete Dataset"}
        />
      )}
    </div>
  );
};

export default Datasets;

import { signOut } from "firebase/auth";
import { useContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { auth } from "../firebase";

const TIMEOUT = 60000 * 30; // 30 min in milliseconds
const TIMER_THROTTLE = 1000; // 1 second in milliseconds

export const useSessionTimeOut = () => {
  const { dispatch } = useContext(AuthContext);
  const navitage = useNavigate();

  const logout = () => {
    signOut(auth);
    dispatch({ type: "LOGOUT" });
    navitage("/login");
  };

  useIdleTimer({
    timeout: TIMEOUT,
    throttle: TIMER_THROTTLE,
    stopOnIdle: true,
    onIdle: logout,
  });
};

import React from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Close from "../svg/Close";
import WarningFill from "../svg/WarningFill";

const DeleteDatasetModal = ({
  handleClose = () => {},
  handleDelete = () => {},
  hideDelete = false,
  heading = "",
  body = "",
  deleteButtonText = "",
}) => {
  return (
    <div
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
        <div className="flex flex-col gap-[26px] items-center justify-between p-7 md:p-5 border-b rounded-t dark:border-gray-600 bg-white w-[348px]">
          <div className="text-center flex flex-col gap-[24px] relative">
            <div>
              <button
                className="ml-auto absolute right-[0]"
                onClick={handleClose}
              >
                <Close />
              </button>
            </div>
            <div className="text-center">
              <span className="flex items-center justify-center bg-[#d2333a33] rounded-full p-[8px] m-auto w-[40px] min-w-[40px] h-[40px]">
                <WarningFill />
              </span>
            </div>
            <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}>
              {heading}
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>{body}</Typography>
            {!hideDelete && (
              <button
                onClick={handleDelete}
                className="px-10 py-2 bg-[#D2333A] text-white rounded-full w-full"
              >
                {deleteButtonText}
              </button>
            )}
            <button
              onClick={handleClose}
              className="px-10 py-2 bg-white border border-[#999999] rounded-full w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteDatasetModal;

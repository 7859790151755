import {
  AreaChart,
  Area,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Legend,
  YAxis,
  Label,
} from "recharts";
import "firebase/firestore";
import { useEffect, useRef } from "react";

const LABEL_MAPPER = {
  accuracy: "Accuracy",
  false_positive_rate: "False Positive Rate",
  precision: "Precision",
};

const Overview = ({ aspect, title }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      console.log("Container resized");
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const data = [
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
  ];

  return (
    <div className="flex-1">
      <div ref={containerRef} style={{ width: "100%", height: "30vh" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis label={""} dataKey="name" stroke="gray">
              <Label value="Month" offset={-5} position="insideBottom" />
            </XAxis>
            <YAxis>
              <Label
                value="US $"
                angle={-90}
                offset={-20}
                position="left"
                dy="-10"
              />
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#569EF8"
              activeDot={{ r: 8 }}
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Overview;

import { useEffect, useState } from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Summary = ({ summarydata }) => {
  const [data, setData] = useState(summarydata);

  useEffect(() => {
    setData(summarydata);
  }, [summarydata]);
  return (
    <div
      className="mt-[2%] ml-[2%] py-[20px] items-center justify-center h-full"
      style={{ width: "100%", minHeight: "100px" }}
    >
      {data ? (
        <>
          <div>
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
              Classification Algorithm Performance Summary
            </Typography>
          </div>
          <div className="mt-[20px]">
            <div className="mt-[15px]">
              <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
                1. Accuracy
              </Typography>
              <div className="mt-[15px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  Accuracy: {data.accuracy?.toFixed(2) || "N/A"}
                </Typography>
              </div>
            </div>
            <div className="mt-[20px]">
              <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
                2. False Positive Rate
              </Typography>
              <div className="mt-[15px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  False Positive Rate:{" "}
                  {data.false_positive_rate?.toFixed(2) || "N/A"}
                </Typography>
              </div>
            </div>
          </div>
          <div className="mt-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
              3. F1 Score, Precision & Recall
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ height: "auto", width: "96%", marginTop: "20px" }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#A0CCFB", color: "white" }}>
                    <TableCell>Label</TableCell>
                    <TableCell>F1 Score</TableCell>
                    <TableCell>Precision</TableCell>
                    <TableCell>Recall</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data.table || {}).map(([key, metrics]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>
                        {metrics["f1-score"]?.toFixed(2) || "N/A"}
                      </TableCell>
                      <TableCell>
                        {metrics.precision?.toFixed(2) || "N/A"}
                      </TableCell>
                      <TableCell>
                        {metrics.recall?.toFixed(2) || "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <>
          <Typography variant={TYPOGRAPHY_VARIANT.SUB_HEADING_4}>
            No Collaborator Summary Found
          </Typography>
        </>
      )}
    </div>
  );
};

export default Summary;

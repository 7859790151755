export default ({ className = "", id = "", fill, width, height }) => {
    return (
      <svg
        className={className}
        id={id}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5422 17.577L10.2615 11.2962C9.76146 11.7091 9.18646 12.0321 8.53646 12.2655C7.88646 12.4988 7.21403 12.6155 6.51916 12.6155C4.80999 12.6155 3.36348 12.0237 2.17961 10.8401C0.995742 9.65655 0.403809 8.21041 0.403809 6.50169C0.403809 4.79296 0.995592 3.34628 2.17916 2.16167C3.36273 0.977065 4.80888 0.384766 6.51761 0.384766C8.22633 0.384766 9.67299 0.9767 10.8576 2.16057C12.0422 3.34443 12.6345 4.79095 12.6345 6.50012C12.6345 7.21422 12.5147 7.89627 12.2749 8.54627C12.0352 9.19627 11.7153 9.76165 11.3153 10.2424L17.5961 16.5232L16.5422 17.577ZM6.51916 11.1155C7.80763 11.1155 8.89898 10.6684 9.79321 9.77417C10.6874 8.87993 11.1346 7.78858 11.1346 6.50012C11.1346 5.21165 10.6874 4.1203 9.79321 3.22607C8.89898 2.33183 7.80763 1.88472 6.51916 1.88472C5.23069 1.88472 4.13934 2.33183 3.24511 3.22607C2.35089 4.1203 1.90378 5.21165 1.90378 6.50012C1.90378 7.78858 2.35089 8.87993 3.24511 9.77417C4.13934 10.6684 5.23069 11.1155 6.51916 11.1155Z"
          fill="black"
        />
      </svg>
    );
  };
  
  
export default () => {
  return (
    <svg
      width="80"
      height="70"
      viewBox="0 0 80 70"
      className="m-auto"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6308 0H20.0455C20.0551 0 20.0495 1.3801 20.0455 2.07016H36.4124C36.4303 2.07016 37.4775 3.91029 37.9988 4.83036H17.2513V6.91871H39.219L40.8051 9.70673H14.4539L14.4451 11.7769H42.016L43.6113 14.5371H11.6526V16.6139H44.8074L46.4175 19.3675H8.86203L0 34.6406L1.79413 35.6527L10.0288 21.4376H13.203L2.80621 39.471L4.60035 40.529L15.5952 21.4376H18.8154L5.61242 44.3473L7.40656 45.3594L21.2076 21.4376H24.3818L8.37263 49.1777L10.1668 50.1898L26.774 21.4376H29.9804L11.1788 54.0081L20.0115 69.2812L21.8056 68.2231L13.571 54.0081L15.1811 51.2479L25.5779 69.2812L27.3721 68.2231L16.3772 49.1777L17.9873 46.4175L31.1903 69.2812L32.9845 68.2231L19.1834 44.3013L20.7936 41.5411L36.8028 69.2812L38.5969 68.2231L21.9896 39.471L23.5538 36.7108L42.3692 69.2812H59.9885V67.2111H43.5653L42.0011 64.4508L62.7947 64.4048V62.3347H40.7591L39.1949 59.5745H65.6009V57.5043H37.9761L36.3887 54.7441H68.3926V52.674H35.1926L33.5825 49.9137H71.1673L80 34.6406L78.2059 33.5825L69.9712 47.8436H66.797L77.1938 29.8102L75.3997 28.7522L64.4048 47.8436H61.1846L74.3876 24.9339L72.5934 23.9218L58.7924 47.8436H55.6182L71.5814 20.1035L69.7872 19.0914L53.18 47.8436H50.0057L68.8212 15.2731L59.9885 0L58.1944 1.05808L66.429 15.2731L64.8189 18.0334L54.3761 0L52.5819 1.05808L63.6228 20.1035L62.0126 22.8637L48.8097 0L47.0155 1.05808L60.8166 24.9339L59.2064 27.6941L43.1972 0L41.4031 1.05808L58.0103 29.8102L56.4002 32.5704L37.6308 0ZM32.387 21.4376H47.6207L55.2047 34.6406L47.6142 47.7976H32.387L24.7964 34.6406L32.387 21.4376Z"
        fill="url(#paint0_radial_187_3377)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_187_3377"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.3015 32.6421) rotate(116.787) scale(38.4317 44.3777)"
        >
          <stop offset="0.290742" stopColor="#569EF8" />
          <stop offset="0.99842" stopColor="#3138A8" />
        </radialGradient>
      </defs>
    </svg>
  );
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        currentUser: action.payload,
      };
    }
    case "UPDATE_PROFILE": {
      return {
        currentUser: { ...state.currentUser, userDetails: action.payload },
      };
    }
    case "UPDATE": {
      console.log("in UPDATE :  state", state, 'action.payload',action.payload);
      return {
        currentUser: action.payload,
      };
    }
    case "LOGOUT": {
      return {
        currentUser: null,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;

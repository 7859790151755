import { useEffect } from "react";
import InputTextField, {
  LABEL_TYPE,
} from "../../../components/common/InputTextField";
import SelectField from "../../../components/common/SelectField";
import { INFO_MESSAGE } from "../../../utils/const";
import constants from "./constants";

export const FIELDS_CONST = {
  isolationForest: {
    setting_n_estimators: {
      defaultValue: "100",
      placeholder: "",
    },
    setting_max_samples: {
      defaultValue: "none",
      placeholder: "none",
    },
    setting_contamination: {
      defaultValue: "",
      placeholder: "auto",
    },
    setting_max_features: {
      defaultValue: "1",
      placeholder: "",
    },
    setting_bootstrap: {
      defaultValue: "False",
      placeholder: "",
    },
    setting_random_state: {
      defaultValue: "none",
      placeholder: "none",
    },
  },
  logisticRegression: {
    setting_random_state: {
      defaultValue: "none",
      placeholder: "none",
    },
    setting_penalty: {
      defaultValue: "l2",
      placeholder: "",
    },
    setting_solver: {
      defaultValue: "lbfgs",
      placeholder: "",
    },
    setting_max_iter: {
      defaultValue: "100",
      placeholder: "",
    },
    setting_multi_class: {
      defaultValue: "auto",
      placeholder: "",
    },
  },
  linearRegression: {
    setting_n_jobs: {
      defaultValue: "none",
      placeholder: "none",
    },
  },
};

export const ALGORITHM_FIELD_NAMES = {
  isolationForest: [
    "setting_n_estimators",
    "setting_max_samples",
    "setting_contamination",
    "setting_max_features",
    "setting_bootstrap",
  ],
  logisticRegression: [
    "setting_penalty",
    "setting_solver",
    "setting_max_iter",
    "setting_multi_class",
    "setting_random_state",
  ],
  linearRegression: ["setting_n_jobs"],
};

const IsolationForest = ({
  setValue,
  register,
  formState,
  control,
  mlSettings,
}) => {
  const { errors } = formState;
  useEffect(() => {
    setValue(
      "setting_n_estimators",
      FIELDS_CONST.isolationForest.setting_n_estimators.defaultValue
    );
    setValue(
      "setting_max_features",
      FIELDS_CONST.isolationForest.setting_max_features.defaultValue
    );
    setValue(
      "setting_bootstrap",
      FIELDS_CONST.isolationForest.setting_bootstrap.defaultValue
    );
    setValue(
      "setting_contamination",
      FIELDS_CONST.isolationForest.setting_contamination.placeholder
    );
    setValue(
      "setting_max_samples",
      FIELDS_CONST.isolationForest.setting_max_samples.placeholder
    );
    setValue(
      "setting_random_state",
      FIELDS_CONST.isolationForest.setting_random_state.placeholder
    );
  }, []);
  return (
    <div className="py-8 px-16 bg-white rounded-md">
      <div className="mb-6">
        <InputTextField
          label="n_estimators"
          type="number"
          labelType={LABEL_TYPE.OUTLINED}
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.ISOLATATIONFOREST.N_ESTIMATORS
          }
          defaultValue={
            FIELDS_CONST.isolationForest.setting_n_estimators.defaultValue
          }
          innerRef={register("setting_n_estimators").ref}
          {...register("setting_n_estimators", {
            required: "setting_n_estimators is required",
          })}
          errorMessage={errors.setting_n_estimators?.message}
          isError={!!errors.setting_n_estimators?.message}
        />
      </div>
      <div className="mb-6">
        <InputTextField
          label="max_samples"
          type={"number"}
          labelType={LABEL_TYPE.OUTLINED}
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.ISOLATATIONFOREST.MAX_SAMPLES
          }
          innerRef={register("setting_max_samples").ref}
          placeholder={
            FIELDS_CONST.isolationForest.setting_max_samples.placeholder
          }
          {...register("setting_max_samples")}
        />
      </div>
      <div className="mb-6">
        <InputTextField
          control={control}
          label="contamination"
          type={"number"}
          labelType={LABEL_TYPE.OUTLINED}
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.ISOLATATIONFOREST.CONTAMINATION
          }
          defaultValue={
            FIELDS_CONST.isolationForest.setting_contamination.defaultValue
          }
          placeholder={
            FIELDS_CONST.isolationForest.setting_contamination.placeholder
          }
          name="setting_contamination"
          hasSlider={true}
          sliderSettings={{ min: 0.0, max: 5.0, step: 0.01 }}
        />
      </div>
      <div className="mb-6">
        <InputTextField
          label="max_features"
          type={"number"}
          labelType={LABEL_TYPE.OUTLINED}
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.ISOLATATIONFOREST.MAX_FEATURES
          }
          innerRef={register("setting_max_features").ref}
          defaultValue={
            FIELDS_CONST.isolationForest.setting_max_features.defaultValue
          }
          {...register("setting_max_features", {
            required: "setting_max_features is required.",
          })}
          errorMessage={errors?.setting_max_features?.message}
          isError={!!errors?.setting_max_features?.message}
        />
      </div>
      <div className="mb-6">
        <SelectField
          control={control}
          label="bootstrap"
          labelType={LABEL_TYPE.OUTLINED}
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.ISOLATATIONFOREST.BOOSTSRAP
          }
          options={constants.bootstrapOptions}
          defaultValue={
            mlSettings.bootstrap ??
            FIELDS_CONST.isolationForest.setting_bootstrap.defaultValue
          }
          innerRef={register("setting_bootstrap").ref}
          {...register("setting_bootstrap", {
            required: "setting_bootstrap is required.",
          })}
          errorMessage={errors?.setting_bootstrap?.message}
          isError={!!errors?.setting_bootstrap?.message}
        />
      </div>
      <div className="mb-6">
        <InputTextField
          label="random_state"
          type={"number"}
          labelType={LABEL_TYPE.OUTLINED}
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.ISOLATATIONFOREST.RANDOM_STATE
          }
          placeholder={
            FIELDS_CONST.isolationForest.setting_random_state.placeholder
          }
          innerRef={register("setting_random_state").ref}
          {...register("setting_random_state")}
        />
      </div>
    </div>
  );
};

const LogisticRegression = ({ setValue, register, formState, control }) => {
  const { errors } = formState;
  useEffect(() => {
    setValue(
      "setting_penalty",
      FIELDS_CONST.logisticRegression.setting_penalty.defaultValue
    );
    setValue(
      "setting_solver",
      FIELDS_CONST.logisticRegression.setting_solver.defaultValue
    );
    setValue(
      "setting_max_iter",
      FIELDS_CONST.logisticRegression.setting_max_iter.defaultValue
    );
    setValue(
      "setting_multi_class",
      FIELDS_CONST.logisticRegression.setting_multi_class.defaultValue
    );
    setValue(
      "setting_random_state",
      FIELDS_CONST.logisticRegression.setting_random_state.defaultValue
    );
  }, []);
  return (
    <div className="py-8 px-16 bg-white rounded-md">
      <div className="mb-6">
        <SelectField
          control={control}
          label="penalty"
          options={constants.penaltyOptions}
          labelType={LABEL_TYPE.OUTLINED}
          defaultValue={
            FIELDS_CONST.logisticRegression.setting_penalty.defaultValue
          }
          toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.LOGISTICREGESSION.PENALTY}
          innerRef={register("setting_penalty").ref}
          {...register("setting_penalty", {
            required: "penalty is required.",
          })}
          error={errors.setting_penalty?.message}
          isError={!!errors.setting_penalty}
        />
      </div>
      <div className="mb-6">
        <SelectField
          control={control}
          label="solver"
          options={constants.solverOptions}
          labelType={LABEL_TYPE.OUTLINED}
          defaultValue={
            FIELDS_CONST.logisticRegression.setting_solver.defaultValue
          }
          toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.LOGISTICREGESSION.SOLVER}
          innerRef={register("setting_solver").ref}
          {...register("setting_solver", {
            required: "solver is required.",
          })}
          error={errors?.setting_solver?.message}
          isError={!!errors?.setting_solver?.message}
        />
      </div>
      <div className="mb-6">
        <InputTextField
          label="max_iter"
          type={"number"}
          labelType={LABEL_TYPE.OUTLINED}
          defaultValue={
            FIELDS_CONST.logisticRegression.setting_max_iter.defaultValue
          }
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.LOGISTICREGESSION.MAX_ITER
          }
          innerRef={register("setting_max_iter").ref}
          {...register("setting_max_iter", {
            required: "max_iter is required.",
          })}
          errorMessage={errors?.setting_max_iter?.message}
          isError={!!errors?.setting_max_iter?.message}
        />
      </div>
      <div className="mb-6">
        <SelectField
          control={control}
          label="multi_class"
          labelType={LABEL_TYPE.OUTLINED}
          options={constants.multiOptions}
          defaultValue={
            FIELDS_CONST.logisticRegression.setting_multi_class.defaultValue
          }
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.LOGISTICREGESSION.MULTI_CLASS
          }
          innerRef={register("setting_multi_class").ref}
          {...register("setting_multi_class", {
            required: "multi_class is required.",
          })}
          error={errors?.setting_multi_class?.message}
          isError={!!errors?.setting_multi_class?.message}
        />
      </div>
      <div className="mb-6">
        <InputTextField
          label="random_state"
          type="number"
          labelType={LABEL_TYPE.OUTLINED}
          placeholder={
            FIELDS_CONST.logisticRegression.setting_random_state.placeholder
          }
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.LOGISTICREGESSION.RANDOM_STATE
          }
          innerRef={register("setting_random_state").ref}
          {...register("setting_random_state")}
          error={errors?.setting_random_state?.message}
          isError={!!errors?.setting_random_state?.message}
        />
      </div>
    </div>
  );
};
const LinearRegression = ({ setValue, register, formState }) => {
  const { errors } = formState;
  useEffect(() => {
    setValue(
      "setting_n_jobs",
      FIELDS_CONST.linearRegression.setting_n_jobs.defaultValue
    );
  }, []);
  return (
    <div className="py-8 px-16 bg-white rounded-md">
      <div className="mb-6">
        <InputTextField
          label="n_jobs"
          type={"number"}
          placeholder={FIELDS_CONST.linearRegression.setting_n_jobs.placeholder}
          labelType={LABEL_TYPE.OUTLINED}
          defaultValue={
            FIELDS_CONST.linearRegression.setting_n_jobs.defaultValue
          }
          toolTipTitle={
            INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.HIDDEN_LAYER_SIZES
          }
          innerRef={register("setting_n_jobs").ref}
          {...register("setting_n_jobs")}
          error={errors.setting_n_jobs?.message}
          isError={!!errors.setting_n_jobs}
        />
      </div>
    </div>
  );
};

// const NeutralNetworks = ({ register, control }) => {
//   return (
//     <div className="py-8 px-16 bg-white rounded-md">
//       <div className="mb-6">
//         <InputTextField
//           label="hidden_layer_sizes"
//           type={"number"}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.HIDDEN_LAYER_SIZES
//           }
//           innerRef={register("setting_n_jobs").ref}
//           {...register("setting_n_jobs", {
//             required: "hidden_layer_sizes is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <SelectField
//           control={control}
//           label="activation"
//           options={constants.activationOptions}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.ACTIVACTION
//           }
//           innerRef={register("setting_activation").ref}
//           {...register("setting_activation", {
//             required: "activation is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <SelectField
//           control={control}
//           label="solver"
//           options={constants.SolverOptions}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.SOLVER}
//           innerRef={register("setting_solver").ref}
//           {...register("setting_solver", {
//             required: "solver is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="alpha"
//           type={"number"}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.ALPHA}
//           innerRef={register("setting_alpha").ref}
//           {...register("setting_alpha", {
//             required: "alpha is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="batch_size"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.BATCH_SIZE
//           }
//           innerRef={register("setting_batch_size").ref}
//           {...register("setting_batch_size", {
//             required: "batch_size is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <SelectField
//           control={control}
//           label="learning_rate"
//           options={constants.learning_rateOptions}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.LEARNING_RATE
//           }
//           innerRef={register("setting_learning_rate").ref}
//           {...register("setting_learning_rate", {
//             required: "learning_rate is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="learning_rate_init"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.LEARNING_RATE_INIT
//           }
//           innerRef={register("setting_learning_rate_init").ref}
//           {...register("setting_learning_rate_init", {
//             required: "learning_rate_init is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="max_iter"
//           type={"number"}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.MAX_ITER}
//           innerRef={register("setting_max_iter").ref}
//           {...register("setting_max_iter", {
//             required: "max_iter is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="tol"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.TOL}
//           innerRef={register("setting_tol").ref}
//           {...register("setting_tol", {
//             required: "tol is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="momentum"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.MAOMENTUM
//           }
//           innerRef={register("setting_momentum").ref}
//           {...register("setting_momentum", {
//             required: "momentum is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="early_stopping"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.EARLY_STOPPING
//           }
//           innerRef={register("setting_early_stopping").ref}
//           {...register("setting_early_stopping", {
//             required: "early_stopping is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="validation_fraction"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.VALIDATION_FRACTION
//           }
//           innerRef={register("setting_validation_fraction").ref}
//           {...register("setting_validation_fraction", {
//             required: "validation_fraction is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="beta_1"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.BETA_1}
//           innerRef={register("setting_beta_1").ref}
//           {...register("setting_beta_1", {
//             required: "beta_1 is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="beta_2"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.BETA_2}
//           innerRef={register("setting_beta_2").ref}
//           {...register("setting_beta_2", {
//             required: "beta_2 is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="epsilon"
//           type={"number"}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.NEUTRAL_NETWORKS.ESILON}
//           innerRef={register("setting_epsilon").ref}
//           {...register("setting_epsilon", {
//             required: "epsilon is required.",
//           })}
//         />
//       </div>
//     </div>
//   );
// };

// const AutoEncoders = ({ register, control }) => {
//   return (
//     <div className="py-8 px-16 bg-white rounded-md">
//       <div className="mb-6">
//         <InputTextField
//           label="encoding_dim"
//           type={"number"}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.ENCODING_DIM}
//           innerRef={register("setting_encoding_dim").ref}
//           {...register("setting_encoding_dim", {
//             required: "encoding_dim is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <SelectField
//           control={control}
//           label="activation"
//           options={constants.activationOptions}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.ACTIVACTION}
//           innerRef={register("setting_activation").ref}
//           {...register("setting_activation", {
//             required: "activation is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <SelectField
//           control={control}
//           label="optimizer"
//           options={constants.SolverOptions}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.OPTIMIZER}
//           innerRef={register("setting_optimizer").ref}
//           {...register("setting_optimizer", {
//             required: "optimizer is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <SelectField
//           control={control}
//           label="loss"
//           type={"number"}
//           labelType={LABEL_TYPE.OUTLINED}
//           options={constants.lossOptions}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.LOSS}
//           innerRef={register("setting_loss").ref}
//           {...register("setting_loss", {
//             required: "loss is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="batch_size"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.BATH_SIZE}
//           innerRef={register("setting_batch_size").ref}
//           {...register("setting_batch_size", {
//             required: "batch_size is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="epochs"
//           type={"number"}
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.EPOCHS}
//           innerRef={register("setting_epochs").ref}
//           {...register("setting_epochs", {
//             required: "epochs is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="learning_rate"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.LEARNING_RATE
//           }
//           innerRef={register("setting_learning_rate").ref}
//           {...register("setting_learning_rate", {
//             required: "learning_rate is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="dropout_rate"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.DROPOUT_RETE}
//           innerRef={register("setting_dropout_rate").ref}
//           {...register("setting_dropout_rate", {
//             required: "dropout_rate is required.",
//           })}
//         />
//       </div>
//       <div className="mb-6">
//         <InputTextField
//           label="l2_regularization"
//           type="text"
//           labelType={LABEL_TYPE.OUTLINED}
//           toolTipTitle={
//             INFO_MESSAGE.PROJECT_REGISTER.AUTOENCODERS.REGULARIZANTION
//           }
//           innerRef={register("setting_l2_regularization").ref}
//           {...register("setting_l2_regularization", {
//             required: "l2_regularization is required.",
//           })}
//         />
//       </div>
//     </div>
//   );
// };

const MlSettingForms = {
  isolationForestForm: IsolationForest,
  logisticRegressionForm: LogisticRegression,
  // neutralNetworksForm: NeutralNetworks,
  // autoEncodersForm: AutoEncoders,
  linearRegressionFrom: LinearRegression,
};

export default MlSettingForms;

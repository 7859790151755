import { Tooltip } from "@mui/material";

const CustomTooltip = ({ title, children }) => {
  return (
    <Tooltip
      arrow
      disableFocusListener
      disableTouchListener
      title={title}
      // PopperProps={{ sx: { '.MuiTooltip-tooltip': { color: '#323582' } } }}
      PopperProps={{
        sx: {
          ".MuiTooltip-tooltip": {
            bgcolor: "#323582",
            ".MuiTooltip-arrow": {
              color: "#323582",
            },
          },
        },
      }}
      slotProps={{
        tooltip: {
          sx: { fontSize: "13px", fontWeight: 400, lineHeight: "20px" },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;

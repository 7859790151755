export default ({ className = "", id = "", fill, width, height }) => {
  return (
    <svg
      className={className}
      id={id}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.26668 9.66732L0.333344 8.73398L4.06668 5.00065L0.333344 1.26732L1.26668 0.333984L5.00001 4.06732L8.73334 0.333984L9.66668 1.26732L5.93334 5.00065L9.66668 8.73398L8.73334 9.66732L5.00001 5.93398L1.26668 9.66732Z"
        fill="#999999"
      />
    </svg>
  );
};

import "./featured.scss";
import "react-circular-progressbar/dist/styles.css";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import ArrowUpward from "../svg/ArrowUpward";
import ArrowDownward from "../svg/ArrowDownward";
import { Pie, PieChart, Tooltip } from "recharts";

const Featured = ({ diff }) => {
  const data01 = [
    {
      value: 1,
      fill: "#CCCCCC",
    },
  ];

  const diffIcon =
    diff == 0 ? (
      <ArrowUpward fill="#646464" />
    ) : diff < 0 ? (
      <ArrowDownward />
    ) : (
      <ArrowUpward />
    );

  return (
    <div className="flex-1">
      <div className="flex flex-col gap-4">
        <Typography
          variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}
          className="text-[#646464]"
        >
          Total Revenue
        </Typography>
        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_2}>{"$OK"}</Typography>
      </div>
      <div className="">
        <div className="">
          <PieChart width={380} height={250}>
            <Tooltip />
            <Pie
              data={data01}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#3138A8"
              label={""}
            />
          </PieChart>
        </div>
        <div className="summary">
          <div className="flex gap-2 w-full">
            {diffIcon}
            <Typography
              variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}
              className="flex-none text-[#999999]"
            >
              {"0%"}
            </Typography>
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}
              className="flex-none text-[#999999]"
            >
              than yesterday
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;

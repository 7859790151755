export default ({ className = "hover:bg-ui-grey-25 rounded-full", id = "", fill, width, height }) => {
  return (
    <svg
      fill="none"
      className={className}
      id={id}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#999999" />
      <path
        d="M17.825 23L23.425 28.6L22 30L14 22L22 14L23.425 15.4L17.825 21H30V23H17.825Z"
        fill="#999999"
      />
    </svg>
  );
};

import { useState } from "react";
import CustomTooltip from "../../../components/common/CustomTooltip/CustomTooltip";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../components/common/Typography";
import CopyIcon from "../../../components/svg/CopyIcon";
import { copyClientCode, getMonthName } from "../../../utils/helper";
import { FIELDS_CONST } from "./MlSettingForms";
import DefaultProfilePic from "../../../components/svg/DefaultProfilePic";
import CircleFill from "../../../components/svg/CircleFill";
import { useNavigate } from "react-router-dom";
import { Refresh } from "@mui/icons-material";

export const ClientCodeDrawerBody = ({
  clientKey,
  seletedAlgo,
  projectId,
  collaboratorId,
  datasetColumn,
  targetColumn,
}) => {
  const [tooltipText, setTooltipText] = useState("Copy Text");

  const pythonCode = () => {
    if (seletedAlgo === "Isolation Forest") {
      return (
        <></>
        // <div className="flex gap-4 p-3">
        //   <div>
        //     <ul className="text-[#999999]">
        //       {[...Array(27).keys()].map((val) => (
        //         <li>{val + 1}</li>
        //       ))}
        //     </ul>
        //   </div>
        //   <div
        //     id="client-code"
        //     style={{ whiteSpace: "nowrap", width: "30vw" }}
        //     className="overflow-x-auto"
        //   >
        //     <ul>
        //       <li>
        //         from pryvx_ml.fl_client import Client
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         import pandas as pd<span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         <span className="hidden">\n</span>
        //         <br />
        //       </li>
        //       <li>
        //         def preprocess(csv_file, columns):
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>"""
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>Reads a CSV file and extracts
        //         a DataFrame with only the specified columns.
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>{" "}
        //         <span className="hidden">\n</span>
        //         <br />
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>Parameters:
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>csv_file (str): Path to the
        //         CSV file.<span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>columns (list): List of column
        //         names to extract.<span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>
        //         <span className="hidden">\n</span>
        //         <br />
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>Returns:
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>pd.DataFrame: DataFrame
        //         containing only the specified columns.
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4 text-[#309E63]">
        //         <span className="hidden">\t</span>"""
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4">
        //         <span className="hidden">\t</span>df = pd.read_csv(csv_file)
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li className="ps-4">
        //         <span className="hidden">\t</span>return df[columns]
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         <span className="hidden">\n</span>
        //         <br />
        //       </li>
        //       <li className="text-[#309E63]">
        //         # Example usage<span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         columns = ['feature1', 'feature2', 'feature3']
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         df = preprocess('YOUR_DATASET_PATH.csv', columns)
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         <span className="hidden">\n</span>
        //         <br />
        //       </li>
        //       <li>
        //         X = df
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         <span className="hidden">\n</span>
        //         <br />
        //       </li>
        //       <li>
        //         trained_model = Client.train_isolation_forest(X)
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         response = Client.send_model_to_server(trained_model,
        //         PROJECT_ID = "{projectId}", COLLABORATOR_ID = "{collaboratorId}
        //         ", CLIENT_SECRET_KEY = "{clientKey}")
        //         <span className="hidden">\n</span>
        //       </li>
        //       <li>
        //         <span className="hidden">\n</span>
        //         <br />
        //       </li>
        //       <li>
        //         print(response)<span className="hidden">\n</span>
        //       </li>
        //     </ul>
        //     <div className="mb-[20px]" />
        //   </div>
        // </div>
      );
    } else if (seletedAlgo === "Logistic Regression") {
      return (
        <div className="flex gap-4 p-3">
          <div>
            <ul className="text-[#999999]">
              {[...Array(33).keys()].map((val) => (
                <li>{val + 1}</li>
              ))}
            </ul>
          </div>
          <div
            id="client-code"
            style={{ whiteSpace: "nowrap", width: "30vw" }}
            className="overflow-x-auto"
          >
            <ul>
              <li className="text-[#309E63]">
                # Import the required libraries{" "}
                <span className="hidden">\n</span>
              </li>
              <li>
                from pryvx_ml.fl_client import Client
                <span className="hidden">\n</span>
              </li>
              <li>
                import pandas as pd<span className="hidden">\n</span>
                <br />
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Import your dataset
                <span className="hidden">\n</span>
              </li>
              <li>
                df = pd.read_csv("YOUR_DATASET_PATH.csv")
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Selected Column Names
                <span className="hidden">\n</span>
              </li>
              <li>
                input_columns = [
                {datasetColumn.map((column, ind) => {
                  if (ind === datasetColumn.length - 1) {
                    return `"${column}"`;
                  }
                  return `"${column}", `;
                })}
                ]<span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Target Column
                <span className="hidden">\n</span>
              </li>
              <li>
                target_column = "{targetColumn}"
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # preprocess your dataset OR use our pre-defined function as
                follows
                <span className="hidden">\n</span>
              </li>
              <li>
                X_preprocessed, y = Client.preprocess_data(df, input_columns,
                target_column)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Split into Train and test
                <span className="hidden">\n</span>
              </li>
              <li>
                X_train, X_test, y_train, y_test =
                Client.split_train_test(X_preprocessed, y, test_sample_size=0.2)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Train a classifier
                <span className="hidden">\n</span>
              </li>
              <li>
                trained_model, metrics_dict =
                Client.train_logistic_regression(X_train, X_test, y_train,
                y_test)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Output test performance
                <span className="hidden">\n</span>
              </li>
              <li>
                print(metrics_dict)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Send the model params and metrics to pryvX federated server
                <span className="hidden">\n</span>
              </li>
              <li>
                response = Client.send_model_to_server
                <span className="pl-1">(trained_model, metrics_dict,</span>
                <span className="hidden">\n</span>
              </li>
              <li className="ps-20">
                <span className="hidden">\t</span>PROJECT_ID = "{projectId}"
                <span className="hidden">\n</span>
              </li>
              <li className="ps-20">
                <span className="hidden">\t</span>COLLABORATOR_ID = "
                {collaboratorId}"<span className="hidden">\n</span>
              </li>
              <li className="ps-20">
                <span className="hidden">\t</span>CLIENT_SECRET_KEY = "
                {clientKey}" )<span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # See response
                <span className="hidden">\n</span>
              </li>
              <li>
                print(response)
                <span className="hidden">\n</span>
              </li>
            </ul>
            <div className="mb-[20px]" />
          </div>
        </div>
      );
    } else if (seletedAlgo === "Linear Regression") {
      return (
        <div className="flex gap-4 p-3">
          <div>
            <ul className="text-[#999999]">
              {[...Array(33).keys()].map((val) => (
                <li>{val + 1}</li>
              ))}
            </ul>
          </div>
          <div
            id="client-code"
            style={{ whiteSpace: "nowrap", width: "30vw" }}
            className="overflow-x-auto"
          >
            <ul>
              <li className="text-[#309E63]">
                # Import the required libraries{" "}
                <span className="hidden">\n</span>
              </li>
              <li>
                from pryvx_ml.fl_client import Client
                <span className="hidden">\n</span>
              </li>
              <li>
                import pandas as pd<span className="hidden">\n</span>
                <br />
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Import your dataset
                <span className="hidden">\n</span>
              </li>
              <li>
                df = pd.read_csv("YOUR_DATASET_PATH.csv")
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Selected Column Names
                <span className="hidden">\n</span>
              </li>
              <li>
                input_columns = [
                {datasetColumn.map((column, ind) => {
                  if (ind === datasetColumn.length - 1) {
                    return `"${column}"`;
                  }
                  return `"${column}", `;
                })}
                ]<span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Target Column
                <span className="hidden">\n</span>
              </li>
              <li>
                target_column = "{targetColumn}"
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # preprocess your dataset OR use our pre-defined function as
                follows
                <span className="hidden">\n</span>
              </li>
              <li>
                X_preprocessed, y = Client.preprocess_data(df, input_columns,
                target_column)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Split into Train and test
                <span className="hidden">\n</span>
              </li>
              <li>
                X_train, X_test, y_train, y_test =
                Client.split_train_test(X_preprocessed, y, test_sample_size=0.2)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Train a classifier
                <span className="hidden">\n</span>
              </li>
              <li>
                trained_model, metrics_dict =
                Client.train_linear_regression(X_train, X_test, y_train, y_test)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Output test performance
                <span className="hidden">\n</span>
              </li>
              <li>
                print(metrics_dict)
                <span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # Send the model params and metrics to pryvX federated server
                <span className="hidden">\n</span>
              </li>
              <li>
                response = Client.send_model_to_server
                <span className="pl-1">(trained_model, metrics_dict,</span>
                <span className="hidden">\n</span>
              </li>
              <li className="ps-20">
                <span className="hidden">\t</span>PROJECT_ID = "{projectId}"
                <span className="hidden">\n</span>
              </li>
              <li className="ps-20">
                <span className="hidden">\t</span>COLLABORATOR_ID = "
                {collaboratorId}"<span className="hidden">\n</span>
              </li>
              <li className="ps-20">
                <span className="hidden">\t</span>CLIENT_SECRET_KEY = "
                {clientKey}" )<span className="hidden">\n</span>
              </li>
              <li>
                <span className="hidden">\n</span>
                <br />
              </li>
              <li className="text-[#309E63]">
                # See response
                <span className="hidden">\n</span>
              </li>
              <li>
                print(response)
                <span className="hidden">\n</span>
              </li>
            </ul>
            <div className="mb-[20px]" />
          </div>
        </div>
      );
    }
  };

  const packageInstallationCode = () => {
    return (
      <div className="flex gap-4 p-3">
        <div>
          <ul className="text-[#999999]">
            {[...Array(2).keys()].map((val) => (
              <li>{val + 1}</li>
            ))}
          </ul>
        </div>
        <div
          id="package-install-code"
          style={{ whiteSpace: "nowrap", width: "30vw" }}
          className="overflow-x-auto"
        >
          <ul>
            <li className="text-[#309E63]">
              # Install the following libraries
              <span className="hidden">\n</span>
            </li>
            <li>
              pip install pryvx-ml pandas<span className="hidden">\n</span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-[20px]">
      {""}
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
        Client Access Key
      </Typography>
      <div className="mt-[10px]">
        <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
          Your access token to connect to the federated server.
        </Typography>
      </div>
      <div className="p-[10px] pl-[30px] mt-[25px] border-2 bg-[#A0CCFB] flex justify-between rounded-[10px]">
        <div id="access-key" className="w-[90%] break-all">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {clientKey}
          </Typography>
        </div>{" "}
        <CustomTooltip title={tooltipText}>
          <span
            onClick={() => {
              copyClientCode("access-key");
              setTooltipText("Text Copied...");
            }}
            onMouseLeave={() =>
              setTimeout(() => setTooltipText("Copy Text"), 1000)
            }
          >
            <CopyIcon fill="#000000" />
          </span>
        </CustomTooltip>
      </div>
      <div className="mt-[40px]">
        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
          Client Python Code
        </Typography>
        <div className="mt-[20px]">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            Data pre-processing and ML model functions to be run locally client
            side on your private data.
          </Typography>
        </div>
      </div>
      <div className="p-[10px] pl-[30px] mt-[25px] border-2 bg-[#A0CCFB] flex justify-between rounded-[10px]">
        <div id="access-key" className="w-[90%] break-all">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            pryvx-ml requires Python 3.10 to be installed.
          </Typography>
        </div>
      </div>
      <div className="py-[20px] px-[10px]  mt-[25px] text-white border-2 bg-[#1E1E4C] rounded-[10px]">
        <div className="border-b text-end">
          <CustomTooltip title={tooltipText}>
            <span
              className="inline-block mb-[10px]"
              onClick={() => {
                copyClientCode("package-install-code");
                setTooltipText("Text Copied...");
              }}
              onMouseLeave={() =>
                setTimeout(() => setTooltipText("Copy Text"), 1000)
              }
            >
              <CopyIcon />
            </span>
          </CustomTooltip>
        </div>
        {packageInstallationCode()}
      </div>
      <div className="pt-[20px] px-[10px]  mt-[25px] text-white border-2 bg-[#1E1E4C] rounded-[10px]">
        <div className="border-b text-end">
          <CustomTooltip title={tooltipText}>
            <span
              className="inline-block mb-[10px]"
              onClick={() => {
                copyClientCode("client-code");
                setTooltipText("Text Copied...");
              }}
              onMouseLeave={() =>
                setTimeout(() => setTooltipText("Copy Text"), 1000)
              }
            >
              <CopyIcon />
            </span>
          </CustomTooltip>
        </div>
        {pythonCode()}
      </div>
      <div className="mt-[40px]">
        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
          How to Run?
        </Typography>
        <div className="mt-[20px]">
          {/* <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}> */}
          <ul className="list-disc ms-[40px]">
            <li>Start your jupyter notebook insider vertex AI on GCP</li>
            <li>Paste the code provided in the cell</li>
            <li>Replace the data url with your dataset path</li>
            <li>Click the run button (training will start automatically).</li>
          </ul>
          {/* </Typography> */}
        </div>
      </div>
    </div>
  );
};

// For API hub
export const ClientApikeyDrawerBody = () => {
  const [tooltipText, setTooltipText] = useState("Copy Text");

  return (
    <div className="mt-[20px] ">
      {""}
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
        Client Access Key
      </Typography>
      <div className="mt-[10px]">
        <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
          Your access token to connect to the federated server.
        </Typography>
      </div>
      <div className="p-[10px] pl-[30px] mt-[25px] border-2 bg-[#A0CCFB] flex justify-between rounded-[10px]">
        <div id="access-key" className="w-[90%] break-all">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            0x78598738dhjfki8290lksu789shjdlldaiw7cldfdrrbh79ghnjt66btgbhfd444oah62...
          </Typography>
        </div>
        {}
        <CustomTooltip title={tooltipText}>
          <span
            onClick={() => {
              copyClientCode("access-key");
              setTooltipText("Text Copied...");
            }}
            onMouseLeave={() =>
              setTimeout(() => setTooltipText("Copy Text"), 1000)
            }
          >
            <CopyIcon fill="#000000" />
          </span>
        </CustomTooltip>
      </div>
      <div className="ml-[102%] items-center mt-[-43px]">
        <Refresh />
      </div>
    </div>
  );
};

export const ProjectInfoDrawerBody = ({
  projectdata,
  task,
  algorithm,
  datasetColumn,
  targetColumn,
}) => {
  return (
    <div className="mt-[10%]">
      <div className="flex relative mb-8">
        <div className="border border-[#F8F8F8]   rounded-md py-[16px] px-[8px] w-full">
          <label className="absolute top-[-10px] bg-white">
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_2}
              className="text-[#999999]"
            >
              ProjectName
            </Typography>
          </label>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {projectdata}
          </Typography>
        </div>
      </div>
      <div className="flex relative mb-8">
        <div className="border border-[#F8F8F8] rounded-md py-[16px] px-[8px] w-full">
          <label className="absolute top-[-10px] bg-white">
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_2}
              className="text-[#999999]"
            >
              Task
            </Typography>
          </label>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>{task}</Typography>
        </div>
      </div>
      <div className="flex relative mb-8">
        <div className="border border-[#F8F8F8] rounded-md py-[16px] px-[8px] w-full">
          <label className="absolute top-[-10px] bg-white">
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_2}
              className="text-[#999999]"
            >
              Choose Algorithm
            </Typography>
          </label>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {algorithm}
          </Typography>
        </div>
      </div>
      <div className="flex relative mb-8">
        <div className="border border-[#F8F8F8] rounded-md py-[16px] px-[8px] w-full">
          <label className="absolute top-[-10px] bg-white">
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_2}
              className="text-[#999999]"
            >
              Selected Columns
            </Typography>
          </label>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {datasetColumn}
          </Typography>
        </div>
      </div>
      <div className="flex relative mb-8">
        <div className="border border-[#F8F8F8] rounded-md py-[16px] px-[8px] w-full">
          <label className="absolute top-[-10px] bg-white">
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_2}
              className="text-[#999999]"
            >
              Target Coulmn
            </Typography>
          </label>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {targetColumn}
          </Typography>
        </div>
      </div>
      {/* <div className="border-2 w-full bg-[#DDDDDD] rounded-[7px]">
        <Typography
          className="text-[#323582] ml-[10px]  mt-[10px] "
          variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}
        >
          ML CONFIGURE PARAMETERS
        </Typography>
        <div className="bg-white mt-[5%] py-[5%] ml-[5%] mr-[5%]">
          {Object.keys(mlSettings)?.map((mlSettingKey) => (
            <div className="flex relative mb-4">
              <div className="border border-[#F8F8F8] ml-[20px] mr-[20px] rounded-md py-[14px] px-[7px] w-full">
                <label className="absolute top-[-10px] bg-white">
                  <Typography
                    variant={TYPOGRAPHY_VARIANT.BODY_2}
                    className="text-[#999999]"
                  >
                    {mlSettingKey}
                  </Typography>
                </label>
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  {!!mlSettings?.[mlSettingKey]
                    ? mlSettings?.[mlSettingKey]
                    : FIELDS_CONST?.["setting_" + mlSettingKey]?.placeholder}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export const NotificationsMenuBody = ({ notifications = [] }) => {
  const navitage = useNavigate();
  const currentTime = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(currentTime.getDate() - 7);
  const filteredNotifications = notifications.filter(
    (notification) => new Date(notification.time) >= sevenDaysAgo
  );
  return (
    <div className="">
      <div className="bg-[#F8F8F8] py-1 mb-[5px] px-2">
        <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_2}>{}</Typography>
      </div>
      {!!filteredNotifications.length ? (
        filteredNotifications.map((notification) => {
          const { from, picUrl, purpose, seen, targetName, targetId, time } =
            notification;

          const message =
            purpose === "ADDED_COLLABORATOR" ? (
              <>
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  is requesting to collaborate on
                </Typography>{" "}
                <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
                  {targetName}
                </Typography>
                .
              </>
            ) : (
              ""
            );

          const notifiTime = new Date(time);

          const showDate = `${notifiTime.getDate()} ${getMonthName(
            notifiTime.getMonth()
          )} ${notifiTime.getFullYear()}`;

          const showTime = `${notifiTime.getHours()}:${notifiTime.getMinutes()}`;

          const redirectTarget = () => {
            const redirectUrl =
              purpose === "ADDED_COLLABORATOR" ? `/projects/${targetId}` : "";
            navitage(redirectUrl);
          };
          return (
            <div
              onClick={redirectTarget}
              className="flex gap-2 px-5 py-5 border-b hover:bg-[#f8f8f8] cursor-pointer"
            >
              <div className="flex gap-2">
                <div>
                  {!!picUrl ? (
                    <img
                      src={picUrl}
                      alt="profile img"
                      className="w-[40px] h-[40px] max-w-[40px] max-h-[40px] rounded-full"
                    />
                  ) : (
                    <span className="bg-[#dddddd] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                      <DefaultProfilePic height="20px" width="20px" />
                    </span>
                  )}
                </div>
                <div>
                  <p className="text-[#646464]">
                    <span className="font-bold text-[#2c2c2c]">{from}</span>{" "}
                    {message}
                  </p>
                  <div className="flex gap-2 text-[#646464]">
                    <Typography
                      variant={TYPOGRAPHY_VARIANT.BODY_3}
                      className="flex gap-1 items-center"
                    >
                      <span>{showDate}</span>
                      <span>
                        <CircleFill height="1" width="1" fill="#FF0000" />
                      </span>
                      <span>{showTime}</span>
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="pt-5">{!seen ? <CircleFill /> : ""}</div>
            </div>
          );
        })
      ) : (
        <div className="p-10 text-[#999999]">No New Notifications</div>
      )}
    </div>
  );
};

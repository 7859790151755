import ClockIcon from "../../../components/svg/ClockIcon.js";
import {
  Box,
  CardContent,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import IconEllipse from "../../../components/svg/IconEllipse";
import ProjectsIcons from "../../../components/svg/ProjectsIcons";
import ClassificationIcons from "../../../components/svg/ClassificationIcons";
import RegressionIcons from "../../../components/svg/RegressionIcons";
import CustomTooltip from "../../../components/common/CustomTooltip/CustomTooltip.js";
import { useState } from "react";

const ProjectCard = (props) => {
  const { project, isRowLayout } = props;
  const [tooltipText, setTooltipText] = useState([]);
  const loadIcon = () => {
    if (project.task === "Classification") {
      return <ClassificationIcons />;
    } else if (project.task === "Anomaly Detection") {
      return <ProjectsIcons />;
    } else if (project.task === "Regression") {
      return <RegressionIcons />;
    }
  };

  const formatDate = (date) => {
    const now = new Date();
    const diff = now - date;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (hours <= 24) {
      return `Updated ${hours} hours ago`;
    } else if (hours > 24) {
      return `Updated ${days} days ago`;
    } else {
      return date.toDateString();
    }
  };

  return (
    <>
      {isRowLayout ? (
        <>
          <div className="flex flex-row border-[#999999] rounded-[5%]">
            <CardContent>
              <Box
                sx={{
                  justifyContent: "center",
                  marginTop: "1%",
                }}
              >
                <Typography variant="h4"></Typography>
                <div className="flex relative">
                  <IconEllipse />
                  <div className="z-[1] ml-[20px] justify-center mb-4 w-[72px] min-w-[72px] h-[72px] min-h-[72px]">
                    {loadIcon()}
                  </div>
                </div>
              </Box>
              <Typography gutterBottom variant="h4" color="#2C3850">
                {project.projectName}
              </Typography>
              <Typography variant="body1" color="#646464" marginBottom="5px">
                {project.task}
              </Typography>
            </CardContent>
            <Box sx={{ flexGrow: 1, height: "5px" }} />
            <Divider />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={10}
              sx={{ p: 2 }}
            >
              <Stack alignItems="center" direction="row" spacing={1}>
                <SvgIcon
                  style={{ color: "#999999", marginLeft: "20px" }}
                  fontSize="large"
                >
                  <ClockIcon />
                </SvgIcon>
                <Typography color="#999999" display="inline" variant="body2">
                  {formatDate(new Date(project.lastModifiedDate))}
                </Typography>
              </Stack>
            </Stack>
          </div>
        </>
      ) : (
        <div className="w-full">
          <div className="basis-2/6 flex-col border-[#999999] bg-white rounded-[12%]">
            <CardContent>
              <Box
                sx={{
                  justifyContent: "center",
                  marginTop: "10%",
                  py: "15px",
                }}
              >
                <Typography variant="h4"></Typography>
                <div className="flex justify-center relative mb-[5px]">
                  <IconEllipse />
                  <div className="z-[1] justify-center mb-4 w-[72px] min-w-[72px] h-[72px] min-h-[72px]">
                    {loadIcon()}
                  </div>
                </div>
              </Box>
              <Typography
                align="center"
                gutterBottom
                variant="h4"
                color="#2C3850"
                className="truncate"
              >
                <CustomTooltip title={tooltipText}>
                  <span
                    onMouseLeave={() => setTooltipText(project.projectName)}
                  >
                    {project.projectName}
                  </span>
                </CustomTooltip>
              </Typography>
              <Typography
                align="center"
                variant="body1"
                color="#646464"
                marginBottom="30px"
              >
                {project.task}
              </Typography>
            </CardContent>
            <Box sx={{ flexGrow: 1, height: "10px" }} />
            <Divider />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={10}
              sx={{ p: 2 }}
            >
              <Stack alignItems="center" direction="row" spacing={1}>
                <SvgIcon
                  style={{ color: "#999999", marginLeft: "20px" }}
                  fontSize="large"
                >
                  <ClockIcon />
                </SvgIcon>
                <Typography color="#999999" display="inline" variant="body2">
                  {formatDate(new Date(project.lastModifiedDate))}
                </Typography>
              </Stack>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectCard;

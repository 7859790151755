import { Link } from "react-router-dom";
import LogoSignBlue from "../svg/LogoSignBlue";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
// import Button, { BUTTON_VARIANT } from "../common/Button";
import CrossIcon from "../svg/CrossIcon";

const PopupModal = ({onClose}) => {
  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabindex="-1"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full">
        <div className="relative bg-white rounded-lg shadow flex p-[24px] flex-col gap-[15px] lg:max-w-[45%] max-w-[90%]">
          <div className="flex items-center justify-between p-4 md:p-5 rounded-t ">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="static-modal"
              onClick={onClose}
            >
              <CrossIcon/>
            </button>
          </div>
          <div>
            <LogoSignBlue />
          </div>
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
            Welcome to PryvX!
          </Typography>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            An email verification link has been sent to your inbox.
          </Typography>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            Please click on the link to continue using the platform.
          </Typography>
          {/* <Button variant={BUTTON_VARIANT.SECONDARY} type="button">
            RESEND EMAIL
          </Button> */}
          <div className="flex flex-row items-center justify-center p-2 text-center text-text-marine-blue">
            <div className="relative">
              <span className="leading-[16px]">{`Need help? `}</span>
              <Link
                to="#"
                className="leading-[24px] font-bold cursor-pointer decoration-none"
              >
                CONTACT US
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;

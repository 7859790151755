import "./style.scss";
import Chart from "../../components/chart/Chart";
import { Link, useParams } from "react-router-dom";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import ArrowBack from "../../components/svg/ArrowBack";
import CodeFill from "../../components/svg/CodeFill";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useCallback, useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import OverlayLoader from "../../components/common/OverlayLoader";
import { useNavigate } from "react-router-dom";
import SideDrawer from "../../components/common/SideDrawer";
import {
  ClientCodeDrawerBody,
  ProjectInfoDrawerBody,
} from "./components/drawerBody.jsx";
import api, { API_CONFIG, API_REQUEST_TYPE } from "../../api/api.js";
import EditPenIcon from "../../components/svg/EditPenIcon";
import CollaboratorInviteAction from "../../components/modals/CollaboratorInviteAction.js";
import Table from "../../components/table/Table";
import { AuthContext } from "../../context/AuthContext.js";
import NotFound from "../404notfound/NotFound.jsx";
import { SnackbarContext } from "../../context/SnackbarContext.js";

const ViewProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const [values, setValues] = useState({
    projectName: "",
    projectOwnerName: "",
    task: "",
    algorithm: "",
    dataset: "",
    datasetId: null,
    mlSettings: [],
    collaborators: [],
    isCollaborator: false,
    collaboratorInvitationStatus: null,
    collaboratorTrainingStatus: null,
    readyToDeploy: false,
    readyToDownload: false,
    selectedDatasetColumns: [],
    targetColumn: "",
  });

  const selectedCoulumnString = useMemo(() => {
    const str = values.selectedDatasetColumns.map((column, ind) => {
      if (ind === values.selectedDatasetColumns.length - 1) {
        return `${column}`;
      }
      return `${column}, `;
    });
    return str;
  }, [values]);

  const [isLoading, setIsLoading] = useState(true);
  const [showClientCodeDrawer, setShowClientCodeDrawer] = useState(false);
  const [showProjectInfoDrawer, setShowProjectInfoDrawer] = useState(false);
  const [isCollaboratorActionModalOpen, setIsCollaboratorActionModalOpen] =
    useState(false);
  const [collaboratorActionModalMessage, setCollaboratorActionModalMessage] =
    useState("");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const datasetData = await api(
        API_REQUEST_TYPE.GET,
        API_CONFIG.END_POINTS.PROJECT.GET_PROJECT(id)
      ).then((res) => res.json());
      console.log("res--------------------", datasetData);
      if (datasetData?.project_data) {
        setValues({
          projectName: datasetData?.project_data?.projectName || "",
          task: datasetData?.project_data?.task || "",
          algorithm: datasetData?.project_data?.algorithm || "",
          dataset: datasetData?.project_data?.datasetName || "",
          datasetId: datasetData?.project_data?.datasetId || "",
          client_key: datasetData?.client_key || null,
          mlSettings: datasetData?.project_data?.mlSettings || {},
          projectOwnerName: datasetData?.project_data?.projectOwnerName || "",
          collaborators: datasetData?.collaborators || [],
          metrics: datasetData?.metrics || null,
          isOwner: datasetData?.project_data?.isOwner,
          isCollaborator: datasetData?.project_data?.isCollaborator,
          collaboratorInvitationStatus:
            datasetData?.project_data?.collaboratorInvitationStatus,
          collaboratorTrainingStatus:
            datasetData?.project_data?.collaboratorTrainingStatus,
          readyToDeploy: datasetData?.project_data?.readyToDeploy,
          readyToDownload: datasetData?.project_data?.readyToDownload,
          selectedDatasetColumns:
            datasetData?.project_data?.selectedDatasetColumns || [],
          targetColumn: datasetData.project_data.selectedTargetColumn || "",
        });
        if (
          datasetData?.project_data?.isCollaborator &&
          datasetData?.project_data?.collaboratorInvitationStatus ===
            "Pending" &&
          datasetData?.project_data?.collaboratorTrainingStatus === "No Status"
        ) {
          setIsCollaboratorActionModalOpen(true);
          setCollaboratorActionModalMessage(`
            To view the project, please accept the request to collaborate!
            "${values.projectName}" project.`);
        }
        setIsLoading(false);
      } else if (datasetData?.detail === "Project not found") {
        setValues(null);
        console.error("Project not found!");
        setIsLoading(false);
      } else if (
        datasetData?.detail === "You are not authorised to access this project"
      ) {
        setValues(null);
        console.error("You are not authorised to access this project");
        setIsLoading(false);
      }
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while fetching project.",
      });
      console.error("Error fetching project data:", error);
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const collaboratorInvitaionAction = (action) => {
    const collaborationData = [];
    for (const collaborator of values.collaborators) {
      if (collaborator.userId === currentUser.uid) {
        collaborationData.push({
          userId: collaborator.userId,
          accessKeyId: collaborator.accessKeyId,
          invitationStatus: action,
          trainingStatus: collaborator.trainingStatus,
          date: Date.now(),
        });
      } else {
        collaborationData.push({
          userId: collaborator.userId,
          accessKeyId: collaborator.accessKeyId,
          invitationStatus: collaborator.invitationStatus,
          trainingStatus: collaborator.trainingStatus,
          date: collaborator.date,
        });
      }
    }
    const updatedData = {
      collaborators: collaborationData,
    };
    return updatedData;
  };

  const handleAcceptInvite = async () => {
    const docRef = doc(db, "projects", id);
    const updatedData = collaboratorInvitaionAction("Approved");
    await updateDoc(docRef, updatedData);
    setIsCollaboratorActionModalOpen(false);
    fetchData();
  };
  const handleDenyInvite = async () => {
    const docRef = doc(db, "projects", id);
    const updatedData = collaboratorInvitaionAction("Denied");
    await updateDoc(docRef, updatedData);
    setIsCollaboratorActionModalOpen(false);
    fetchData();
  };

  const showClientCode =
    values?.isCollaborator &&
    values?.collaboratorInvitationStatus === "Approved";

  if (values === null) {
    return <NotFound pageName="project" />;
  }

  const handleDownload = async () => {
    const response = await api(
      API_REQUEST_TYPE.GET,
      API_CONFIG.END_POINTS.PROJECT.DOWNLAOD_MODEL(id)
    );
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "model.zip"; // Name of the file to be downloaded
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      console.error("Failed to download file");
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Failed to download file.",
      });
    }
  };

  return (
    <div className="bg-white min-h-[100vh]">
      <div className="2xl:w-[85%] w-ful m-auto py-14">
        <div className="flex flex-wrap justify-between mb-8 gap-4 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
          <div className="flex flex-wrap blur-none items-center gap-4 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
            <Link to="/projects">
              <ArrowBack />
            </Link>
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
              {values.projectName}
            </Typography>
          </div>
          <div className="flex gap-[10px] 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
            {values?.isOwner && (
              <div>
                <Button
                  variant={BUTTON_VARIANT.SECONDARY}
                  className="flex items-center gap-2"
                  buttonClassName="flex items-center gap-2"
                  onClick={() => navigate(`/projects/new/${id}`)}
                >
                  <EditPenIcon />
                  EDIT PROJECT
                </Button>
              </div>
            )}
            {showClientCode && (
              <div>
                <Button
                  variant={BUTTON_VARIANT.SECONDARY}
                  className="flex items-center"
                  buttonClassName="flex items-center gap-2"
                  onClick={() => setShowClientCodeDrawer(true)}
                >
                  <CodeFill />
                  CLIENT CODE
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="top">
          <div className="right">
            {values.metrics && (
              <Chart
                chartData={values?.metrics}
                collaboratorData={values?.collaborators}
                aspect={3 / 1}
                title="Federated Test Accuracy"
              />
            )}
          </div>
        </div>
        <div className="bottom mt-8 flex xl:flex-row lg:flex-col md:flex-col gap-4">
          <div className="left xl:basis-1/4 lg:basis-full md:basis-full">
            <div className="item bg-[#f8f8f8] p-5 rounded-lg">
              <div
                className="details flex flex-col gap-6 cursor-pointer"
                onClick={() => setShowProjectInfoDrawer(true)}
              >
                <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}>
                  Project Information
                </Typography>
                <div className="detailItem flex flex-wrap">
                  <span className="itemKey font-bold mr-2">Model Owner:</span>
                  <span className="itemValue">{values?.projectOwnerName}</span>
                </div>

                <div className="detailItem flex flex-wrap">
                  <span className="itemKey font-bold mr-2">Task:</span>
                  <span className="itemValue">{values.task}</span>
                </div>

                <div className="detailItem flex flex-wrap">
                  <span className="itemKey font-bold mr-2">Algorithm:</span>
                  <span className="itemValue">{values.algorithm}</span>
                </div>

                <div className="detailItem flex flex-wrap">
                  <span className="itemKey font-bold mr-2">Dataset:</span>
                  <span
                    className="itemValue hover:font-semibold"
                    onClick={() => navigate(`/datasets/${values.datasetId}`)}
                  >
                    {values.dataset}
                  </span>
                </div>

                <div className="detailItem flex flex-wrap">
                  <span className="itemKey font-bold mr-2">
                    No. of Collaborators:
                  </span>
                  <span className="itemValue">
                    {values.collaborators.length}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="2xl:basis-3/4 lg:basis-full md:basis-full">
            <Table
              tableData={values.collaborators}
              openCollaboratorActionModal={() => {
                setIsCollaboratorActionModalOpen(true);
                setCollaboratorActionModalMessage(`
                  Edit your Invitation request for
                  "${values.projectName}" project.`);
              }}
            />
            {values.readyToDeploy && (
              <div className="pull-right flex justify-end mt-2">
                <Button
                  variant={BUTTON_VARIANT.PRIMARY}
                  className="flex items-center justify-center !w-auto"
                  buttonClassName="flex items-center gap-2 !w-auto"
                  onClick={() => {}}
                >
                  DEPLOY
                </Button>
              </div>
            )}
            {values.readyToDownload && (
              <div className="pull-right flex justify-end mt-2">
                <Button
                  variant={BUTTON_VARIANT.PRIMARY}
                  className="flex items-center justify-center !w-auto"
                  buttonClassName="flex items-center gap-2 !w-auto"
                  onClick={handleDownload}
                >
                  DOWNLOAD
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <OverlayLoader />}
      {/* drawer component */}
      <SideDrawer
        show={showClientCodeDrawer}
        hideDrawer={() => setShowClientCodeDrawer(false)}
        title="Client Code"
        body={
          <ClientCodeDrawerBody
            clientKey={values?.client_key}
            seletedAlgo={values.algorithm}
            projectId={id}
            collaboratorId={currentUser.uid}
            datasetColumn={values.selectedDatasetColumns}
            targetColumn={values.targetColumn}
          />
        }
      />
      <SideDrawer
        show={showProjectInfoDrawer}
        hideDrawer={() => setShowProjectInfoDrawer(false)}
        title="Project Information"
        body={
          <ProjectInfoDrawerBody
            projectdata={values.projectName}
            mlSettings={values.mlSettings}
            task={values.task}
            algorithm={values.algorithm}
            datasetColumn={selectedCoulumnString}
            targetColumn={values.targetColumn}
          />
        }
      />
      {isCollaboratorActionModalOpen && (
        <CollaboratorInviteAction
          handleClose={() => setIsCollaboratorActionModalOpen(false)}
          handleAccept={() => handleAcceptInvite()}
          handleDeny={() => handleDenyInvite()}
          body={collaboratorActionModalMessage}
        />
      )}
    </div>
  );
};

export default ViewProject;

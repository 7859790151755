export default ({ className = "", id = "", fill="white", width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      id={id}
    >
      <path
        d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z"
        fill={fill}
      />
    </svg>
  );
};

import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../firebase";
import CrossIcon from "../svg/CrossIcon";
import InputTextField from "../common/InputTextField";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Button from "../common/Button";
import PopupMessage, { POPUP_VARIANT } from "../common/PopupMessage";

const ForgotPasswordModal = ({
  handleClose = () => { },
  setLoading = () => { },
  setShowPasswordResetMessage = () => { }
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const handleResetPass = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email, {
        url: process.env.REACT_APP_REDIRECT_URL,
        handleCodeInApp: true,
      });
      setLoading(false);
      handleClose();
      setShowPasswordResetMessage(true);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };
  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabindex="-1"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full">
        <div className="relative bg-white rounded-lg shadow flex p-[24px] flex-col gap-[15px] lg:w-[480px] max-w-[90%]">
          <div className="flex items-center justify-between p-4 md:p-5 rounded-t">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="static-modal"
              onClick={handleClose}
            >
              <CrossIcon />
            </button>
          </div>
          <div className="flex flex-col gap-[32px]">
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3} className="mb-4">
              Forgot Password?
            </Typography>
            <InputTextField
              type="text"
              placeholder="abc******@**xyz.com"
              value={email}
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && (
              <PopupMessage variant={POPUP_VARIANT.WARNING} className="mt-1">
                {error}
              </PopupMessage>
            )}
            <Button onClick={handleResetPass}>RESET MY PASSWORD</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;

import React, { useState } from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Close from "../svg/Close";
import WarningFill from "../svg/WarningFill";
import UploadFill from "../svg/UploadFill";
import CloseFill from "../svg/CloseFill";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import InputWarningIcon from "../svg/InputWarningIcon";

const UploadDatasetModal = ({
  handleClose = () => { },
  heading = "",
  handleFileChange = () => { },
}) => {
  const [error, setError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const maxSize = 10 * 1024 * 1024;

    if (acceptedFiles.length === 0) {
      setError("No file selected. Please select a file.");
      return;
    }

    const file = acceptedFiles[0];

    if (!allowedTypes.includes(file.type)) {
      setError("Invalid file type. Please import a valid dataset file.");
      return;
    }

    if (file.size > maxSize) {
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      console.log(fileSizeMB, "bdskskkssjnnsnbdhdjndkmdicci");
      setError(`File size exceeds the limit of 10MB. Current size: ${fileSizeMB}MB`);
      return;
    }
    handleFileChange({ target: { files: [file] } });
    handleClose()
    setError(null);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full">
        <div className="flex flex-col gap-[26px] items-center justify-between p-7 md:p-5 border-b rounded-t dark:border-gray-600 bg-white w-[528px]">
          <div className="flex flex-col gap-[24px] relative w-full">
            <div>
              <button
                className="ml-auto absolute right-[0]"
                onClick={handleClose}
              >
                <Close />
              </button>
            </div>
            <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}>
              {heading}
            </Typography>
            <div
              {...getRootProps()}
              className={`border-[2px] border-dashed py-[64px] px-[40px]  rounded-md flex flex-col justify-center gap-[8px] ${isDragActive
                ? "border-brand-sea-blue bg-[#a0ccfb3d]"
                : "border-black"
                }`}
            >
              <UploadFill className="m-auto" />
              <div className="text-center">
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    Drag and Drop file here or{" "}
                    <span for="upload-file" className="cursor-pointer">
                      <u>Choose Dataset</u>
                    </span>
                  </p>
                )}
                <b>
                  <input
                    {...getInputProps()}
                    className="hidden"
                    type="file"
                    id="upload-file"
                    name="uploadedFile"
                    onChange={handleFileChange}
                    accept=".csv"
                  />
                </b>
              </div>
            </div>
            <div className="flex justify-between mt-[-5%] ">
              <Typography
                variant={TYPOGRAPHY_VARIANT.BODY_2}
                className="text-[#999999] text-[17px]"
              >
                Supported formats: CSV, XLSX
              </Typography>
              <Typography
                variant={TYPOGRAPHY_VARIANT.BODY_2}
                className="text-right text-[#999999] text-[17px]"
              >
                Maximum size: 10MB
              </Typography>
            </div>
          </div>
          {error ? (
            <>
              <Typography
                variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}
                className=" flex gap-[8px] text-[#D2333A] bg-[] border border-[#D2333A]  mt-[-6%] w-[100%] pl-[10px] py-[4px] text-[15px]"
              >
                <span className="">{<InputWarningIcon />}</span>
                {error}
                <h4 onClick={() => setError(null)} className="text-[#DDDDDD] pl-[40px] text-[25px] mt-[6.5px]">
                  <Close />
                </h4>
              </Typography>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadDatasetModal;

export default ({ className = "", id = "", fill, width, height }) => {
  return (
    <svg
      className={className}
      id={id}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#3138A8" />
      <path
        d="M15 20V11.85L12.4 14.45L11 13L16 8L21 13L19.6 14.45L17 11.85V20H15ZM10 24C9.45 24 8.97917 23.8042 8.5875 23.4125C8.19583 23.0208 8 22.55 8 22V19H10V22H22V19H24V22C24 22.55 23.8042 23.0208 23.4125 23.4125C23.0208 23.8042 22.55 24 22 24H10Z"
        fill="white"
      />
    </svg>
  );
};

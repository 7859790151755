import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./tableDatagrid.scss";

const TableDatagrid = ({
  rows = [],
  columns = [],
  pageSize = 10,
  rowsPerPageOptions = 10,
  onSelectionModelChange = () => {},
  checkboxSelection = true,
  isLoading = false,
}) => {
  return (
    <DataGrid
      loading={isLoading}
      className="table-datagrid"
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[rowsPerPageOptions]}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={onSelectionModelChange}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      sx={{
        "& .MuiDataGrid-sortIcon": {
          color: "white",
        },
        "& .MuiDataGrid-menuIconButton": {
          opacity: 1,
          color: "white",
        },
      }}
    />
  );
};
export default TableDatagrid;

export default () => {
  return (
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 8C5.95 8 5.47917 7.80417 5.0875 7.4125C4.69583 7.02083 4.5 6.55 4.5 6C4.5 5.45 4.69583 4.97917 5.0875 4.5875C5.47917 4.19583 5.95 4 6.5 4C7.05 4 7.52083 4.19583 7.9125 4.5875C8.30417 4.97917 8.5 5.45 8.5 6C8.5 6.55 8.30417 7.02083 7.9125 7.4125C7.52083 7.80417 7.05 8 6.5 8ZM6.5 12C4.83333 12 3.41667 11.4167 2.25 10.25C1.08333 9.08333 0.5 7.66667 0.5 6C0.5 4.33333 1.08333 2.91667 2.25 1.75C3.41667 0.583333 4.83333 0 6.5 0C7.61667 0 8.62917 0.275 9.5375 0.825C10.4458 1.375 11.1667 2.1 11.7 3H20.5L23.5 6L19 10.5L17 9L15 10.5L12.875 9H11.7C11.1667 9.9 10.4458 10.625 9.5375 11.175C8.62917 11.725 7.61667 12 6.5 12ZM6.5 10C7.43333 10 8.25417 9.71667 8.9625 9.15C9.67083 8.58333 10.1417 7.86667 10.375 7H13.5L14.95 8.025L17 6.5L18.775 7.875L20.65 6L19.65 5H10.375C10.1417 4.13333 9.67083 3.41667 8.9625 2.85C8.25417 2.28333 7.43333 2 6.5 2C5.4 2 4.45833 2.39167 3.675 3.175C2.89167 3.95833 2.5 4.9 2.5 6C2.5 7.1 2.89167 8.04167 3.675 8.825C4.45833 9.60833 5.4 10 6.5 10Z"
        fill="#323582"
      />
    </svg>
  );
};

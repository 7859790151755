import React from "react";
import { Box, Pagination, Unstable_Grid2 as Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import Tabs from "../../components/common/Tabs";
import Search from "../../components/svg/Search";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import OverlayLoader from "../../components/common/OverlayLoader";
import { makeStyles } from "@mui/styles";
import ColumnIcon from "../../components/svg/ColumnIcon";
import RowIcon from "../../components/svg/RowIcon";
import ApiCard from "./components/ApiCard";
import ApikeyIcon from "../../components/svg/ApikeyIcon";
import { ClientApikeyDrawerBody } from "../projects/components/drawerBody";
import SideDrawer from "../../components/common/SideDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root.MuiPaginationItem-root":
      {
        color: "#999999",
      },
    "& .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#A0CCFB",
      color: "black",
    },
  },
}));

const ApiHub = ({ user }) => {
  const classes = useStyles();
  const [islaoding, setIsLoading] = useState(false);
  const [activeClass, setActiveClass] = useState("datasetColumn");
  const [isRowLayout, setIsRowLayout] = useState(false);
  const [isColumnLayout, setIsColumnLayout] = useState(true);
  const [activeTab, setActiveTab] = useState("Wangiri");
  const [page, setPage] = useState(1);
  const [apis, setApis] = useState([]);
  const [apisOnPage, setApisOnPage] = useState([]);
  const [showClientApiKeyDrawer, setShowClientApiKeyDrawer] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const PER_PAGE = 6;
  const count = Math.ceil(apis.length / PER_PAGE);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "api_hub"),
        where("type", "==", activeTab)
      );
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setApis(list);
      setApisOnPage(list.slice(0, PER_PAGE));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const handleChange = (_, p) => {
    if (p === null) {
      return;
    }
    setPage(p);
    setApisOnPage(apis.slice(PER_PAGE * (p - 1), p * PER_PAGE));
  };
  const handleColmButtonClick = (active) => {
    setIsRowLayout(false);
    setIsColumnLayout(true);
    setActiveClass(active);
  };

  const handleRowButtonClick = (active) => {
    setIsRowLayout(true);
    setIsColumnLayout(false);
    setActiveClass(active);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setPage(1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchResult = apis.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setApisOnPage(searchResult);
    setApis(searchResult);
    setPage(1);
    setApisOnPage(searchResult.slice(0, PER_PAGE));
  };
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      fetchData();
    }
  };

  return (
    <div className="h-[695px] p-[32px] px-[40px] mt-8">
      <div className="datatableTitle flex flex-wrap justify-between mb-4  gap-4">
        <div className="flex flex-wrap text-nowrap items-center lg:w-[48%]">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
            Fraud detection APIs
          </Typography>
          <div className="search border border-[#999999] py-[15px] px-[10px] ml-[20px]  rounded-full xl:w-[75%] lg:w-[75%] md:w-full sm:w-full xs:w-full mq450:w-full bg-white shadow-md relative">
            <form onSubmit={(e) => handleSearch(e)}>
              <input
                type="text"
                placeholder="Search by APIs Name"
                value={searchTerm}
                className="w-full"
                onChange={(e) => handleSearchInputChange(e)}
              />
              <button type="submit">
                <span className="absolute right-[2px] top-[2px] bg-[#A0CCFB] w-[45px] h-[45px] rounded-full flex items-center justify-center cursor-pointer">
                  <Search />
                </span>
              </button>
            </form>
          </div>
        </div>
        <div className="flex gap-[7%]">
          <span
            onClick={() => handleColmButtonClick("datasetColumn")}
            className={`flex cursor-pointer drop-shadow-md ${
              activeClass === "datasetColumn" ? "bg-[#a0ccfb66]" : "bg-white"
            } active:bg-gray-200 items-center justify-center rounded-full w-[48px] min-w-[48px] h-[48px]`}
          >
            <ColumnIcon />
          </span>
          <span
            onClick={() => handleRowButtonClick("datasetRow")}
            className={`flex cursor-pointer drop-shadow-md ${
              activeClass === "datasetRow" ? "bg-[#a0ccfb66]" : "bg-white"
            } active:bg-gray-200 items-center  justify-center rounded-full w-[48px] min-w-[48px] h-[48px]`}
          >
            <RowIcon />
          </span>
        </div>
      </div>
      <div className="flex flex-wrap mt-[30px]  gap-3 justify-between mb-4">
        <Tabs
          names={["Wangiri", "SMS", "PBX hacking", "CLI spoofing", "SIM box"]}
          active={activeTab}
          onChangeActive={handleTabClick}
        />
        <button
          onClick={() => setShowClientApiKeyDrawer(true)}
          className="flex items-center  px-[32px] py-[10px] ml-[auto] text-[#323582] border border-[#323582] rounded-full gap-[px]  bg-white shadow-md relative"
        >
          <b className="flex items-center gap-[10px]">
            <ApikeyIcon />
            API Key
          </b>
        </button>
      </div>
      <div className="mt-[35px]">
        <Grid container spacing={isRowLayout ? 1 : 5}>
          {apisOnPage.map((api) => (
            <Grid item lg={isColumnLayout ? 4 : 12} key={api.id}>
              <Link to={`/apis/${api.id}`} style={{ textDecoration: "none" }}>
                <ApiCard isRowLayout={isRowLayout} api={api} />
              </Link>
            </Grid>
          ))}
        </Grid>
        <Box
          className="py-5"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            classes={{ root: classes.root }}
            count={count}
            size="small"
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </div>
      <SideDrawer
        show={showClientApiKeyDrawer}
        hideDrawer={() => setShowClientApiKeyDrawer(false)}
        title="API Key"
        body={<ClientApikeyDrawerBody />}
      />
      {islaoding && <OverlayLoader />}
    </div>
  );
};

export default ApiHub;

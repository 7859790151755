import "./table.scss";
import { default as MuiTable } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getMonthName } from "../../utils/helper";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

const Table = ({ tableData, openCollaboratorActionModal = () => {} }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <TableContainer component={Paper} className="table">
      <MuiTable
        sx={{ minWidth: 650 }}
        style={{ fontSize: "1rem" }}
        aria-label="simple table"
      >
        <TableHead
          sx={{ backgroundColor: "#A0CCFB" }}
          style={{ fontSize: "30px" }}
        >
          <TableRow>
            <TableCell className="tableCell">Company</TableCell>
            <TableCell className="tableCell">Collaborator</TableCell>
            <TableCell className="tableCell">Date</TableCell>
            <TableCell className="tableCell">Invitation</TableCell>
            <TableCell className="tableCell">Training</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => {
            const showDate = () => {
              console.log("row.date", row.date);
              if (!row.date) {
                return "-";
              }
              const statusTime = new Date(row.date);
              return `${getMonthName(
                statusTime.getMonth()
              )} ${statusTime.getDate()}, ${statusTime.getFullYear()}`;
            };
            return (
              <TableRow key={row.userId}>
                <TableCell className="tableCell" sx={{ borderBottom: "none" }}>
                  {row.company}
                </TableCell>
                <TableCell className="tableCell" sx={{ borderBottom: "none" }}>
                  <div className="cellWrapper">{row.firstName}</div>
                </TableCell>
                <TableCell className="tableCell" sx={{ borderBottom: "none" }}>
                  {showDate()}
                </TableCell>
                <TableCell className="tableCell" sx={{ borderBottom: "none" }}>
                  <span
                    className={`status ${row.invitationStatus} ${
                      currentUser.uid === row.userId &&
                      row.trainingStatus !== "Completed" &&
                      "cursor-pointer"
                    }`}
                    onClick={() => {
                      if (
                        currentUser.uid === row.userId &&
                        row.trainingStatus !== "Completed"
                      ) {
                        openCollaboratorActionModal();
                      }
                    }}
                  >
                    {row.invitationStatus}
                  </span>
                </TableCell>
                <TableCell className="tableCell" sx={{ borderBottom: "none" }}>
                  <span className={`training ${row.trainingStatus}`}>
                    {row.trainingStatus}
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
          {!tableData.length && <TableCell>No Contributors found.</TableCell>}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;

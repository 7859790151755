import React from "react";

export const BUTTON_VARIANT = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DISABLED: "disabled",
};
const Button = ({
  className = "",
  buttonClassName = "",
  variant = "primary",
  message = "",
  options = [],
  children,
  ...props
}) => {
  const borderColor = () => {
    if (variant === "primary") return "border-brand-sea-blue";
    else if (variant === "secondary") return "border-brand-sea-blue";
    else if (variant === "disabled") return "border-ui-grey-50";
    else return "";
  };

  const bgColor = () => {
    if (variant === "primary")
      return "bg-brand-sea-blue hover:bg-brand-marine-blue";
    else if (variant === "secondary") return "bg-white hover:bg-ui-grey-25";
    else if (variant === "disabled") return "bg-ui-grey-50";
    else return "";
  };

  const textColor = () => {
    if (variant === "primary") return "text-white";
    else if (variant === "secondary") return "text-black";
    else if (variant === "disabled") return "text-white";
    else return "text-black";
  };

  if (!Object.values(BUTTON_VARIANT).includes(variant)) {
    console.error("You need to access Button variant from BUTTON_VARIANT");
    return "";
  }
  return (
    <div
      className={`w-full relative rounded-[32px] ${bgColor()} flex flex-row items-start justify-center box-border text-center text-base ${textColor()} font-label-large-bold border-2 ${borderColor()} ${className}`}
    >
      <button
        className={`w-full relative rounded-[32px] leading-[24px] shadow-md font-bold inline-block shrink-0 px-[32px] py-[12px] ${bgColor()} ${textColor()} ${borderColor()} ${buttonClassName}`}
        {...props}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import EditPenIcon from "../../components/svg/EditPenIcon";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import ArrowBack from "../../components/svg/ArrowBack";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import OverlayLoader from "../../components/common/OverlayLoader";
import NotFound from "../404notfound/NotFound";
import { SnackbarContext } from "../../context/SnackbarContext";
import { AuthContext } from "../../context/AuthContext";

const ViewDataset = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    datasetName: "",
    dataAccessibility: "",
    desc: "",
    // dataOperator: [],
    createDate: null,
    lastModifiedDate: null,
    fileURL: "",
    fileName: "",
    userId: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthContext);
  const { uid } = currentUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datasetRef = doc(db, "datasets", id);
        const datasetSnapshot = await getDoc(datasetRef);
        if (datasetSnapshot.exists()) {
          const datasetData = datasetSnapshot.data();
          setValues({
            datasetName: datasetData.datasetName || "",
            dataAccessibility: datasetData.dataAccessibility || "",
            desc: datasetData.description || "",
            // dataOperator: datasetData.dataOperator || [],
            createDate: datasetData.createDate || null,
            lastModifiedDate: datasetData.lastModifiedDate || null,
            fileURL: datasetData.fileURL || "",
            fileName: datasetData.fileName || "",
            userId: datasetData.userId || "",
          });
          if (!datasetData.fileURL) setIsLoading(false);
        } else {
          setValues(null);
          console.error("Dataset not found!");
          setIsLoading(false);
        }
      } catch (error) {
        snackbarDispatch({
          type: "SHOW_ERROR_SNACKBAR",
          payload: "Error while fetching datset.",
        });
        console.error("Error fetching dataset:", error);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);
  console.log(values);
  const [csvData, setCsvData] = useState([]);
  console.log("csvData", csvData);
  useEffect(() => {
    if (!!values?.fileURL) {
      async function fetchCsvData() {
        try {
          const response = await fetch(values.fileURL);
          const text = await response.text();
          const { data } = Papa.parse(text, { header: true });
          setCsvData(data.splice(0, 5));
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error("Error fetching CSV:", error);
        }
      }

      fetchCsvData();
    }
  }, [values]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    ["&.MuiTableCell-root"]: {
      padding: "8px",
    },
  }));

  if (values === null) {
    return <NotFound pageName="dataset" />;
  }

  return (
    <div className="bg-white h-full overflow-auto">
      <div className="px-[40px] py-[32px] flex flex-col gap-[40px]">
        <div className="bg-white py-[40px] 2xl:w-[70%] m-auto lg:w-[70%] md:w-[100%] sm:w-[100%]">
          <div className="flex flex-col gap-[48px]">
            <div className="flex flex-wrap justify-between">
              <div className="flex flex-wrap gap-4 mb-8 w-full items-center">
                <Link
                  to="/datasets"
                  className="flex gap-2 hover:fill-brand-marine-blue"
                >
                  <ArrowBack />
                </Link>
                <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                  {values.datasetName}
                </Typography>
                {values.userId === uid && (
                  <div>
                    <Button
                      variant={BUTTON_VARIANT.SECONDARY}
                      className="flex items-center gap-2"
                      onClick={() => navigate(`/datasets/register/${id}`)}
                      buttonClassName="flex items-center gap-2"
                    >
                      <EditPenIcon /> EDIT
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex relative">
              <div className="border border-[#F8F8F8] rounded-md py-[16px] px-[8px] w-full">
                <label className="absolute top-[-10px] bg-white">
                  <Typography
                    variant={TYPOGRAPHY_VARIANT.BODY_2}
                    className="text-[#999999]"
                  >
                    Description
                  </Typography>
                </label>
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  {values.desc}
                </Typography>
              </div>
            </div>
            {csvData.length > 0 && (
              <div className="flex flex-col gap-[48px]">
                <div className="flex relative">
                  <div className="border border-[#F8F8F8] rounded-md py-[16px] px-[8px] w-full">
                    <label className="absolute top-[-10px] bg-white">
                      <Typography
                        variant={TYPOGRAPHY_VARIANT.BODY_2}
                        className="text-[#999999]"
                      >
                        Test Dataset
                      </Typography>
                    </label>
                    <div className="overflow-x-auto"></div>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          {Object.keys(csvData?.[0]).map((value) => {
                            return (
                              <StyledTableCell key={value}>
                                {value}
                              </StyledTableCell>
                            );
                          })}
                        </TableHead>
                        <TableBody>
                          {csvData.map((row, index) => (
                            <StyledTableRow key={index}>
                              {Object.values(row).map((value, idx) => (
                                <StyledTableCell key={idx}>
                                  {value}
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            )}
            {/* {values.dataOperator.length > 0 && (
              <div className="flex relative">
                <div className="border border-[#F8F8F8] rounded-md py-[16px] px-[8px] flex flex-col gap-4 w-[50%]">
                  <label className="absolute top-[-10px] bg-white">
                    <Typography
                      variant={TYPOGRAPHY_VARIANT.BODY_2}
                      className="text-[#999999]"
                    >
                      Data Operators
                    </Typography>
                  </label>
                  <div className="flex flex-wrap gap-[8px]">
                    <div className="flex flex-col">
                      <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                        {values.dataOperator.map((value) => {
                          return <p>{value}</p>;
                        })}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {isLoading && <OverlayLoader />}
    </div>
  );
};

export default ViewDataset;

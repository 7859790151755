import { Link } from "react-router-dom";
import CloseIcon from "../svg/CloseIcon";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";

const MenuNotificationDrawer = ({
  show = false,
  hideDrawer = () => { },
  title = "",
  body = <></>,
  isSidebarCompressed = false,
}) => {
  return (
    <div
      onClick={hideDrawer}
      className={`fixed top-0 z-40 h-screen overflow-y-auto transition-transform  w-full dark:bg-gray-800 bg-[#0000004d] ${show ? "translate-x-unset" : "translate-x-fulls hidden"
        } ${isSidebarCompressed ? "left-[256px]" : "left-[64px]"}`}
      tabIndex={-1}
      aria-labelledby="drawer-right-label"
    >
      <div className="w-[50vw] me-auto h-full ml-[20px] relative">
        <div className="absolute bottom-[80px] flex flex-wrap flex-col h-[80%] justify-between mb-8 gap-4 px-5">
          <div className="bg-white mt-auto rounded-md max-w-[356px] overflow-auto pt-0">
            <svg
              width="23"
              height="32"
              viewBox="0 0 23 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-[4px] bottom-[30px]"
            >
              <path
                d="M1.24719 17.6218C0.141071 16.8235 0.141072 15.1765 1.24719 14.3782L19.8296 0.967537C21.1523 0.0129395 23 0.958092 23 2.58931V29.4107C23 31.0419 21.1523 31.9871 19.8296 31.0325L1.24719 17.6218Z"
                fill="white"
              />
            </svg>
            <div className="px-5 py-5 sticky top-0 bg-white z-10">
              <div className="flex items-center gap-4 mb-2 top-0 sticky">
                <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}>
                  {title}
                </Typography>
                <Link onClick={hideDrawer}>
                  <CloseIcon />
                </Link>
              </div>
            </div>
            <div className="" onClick={hideDrawer}>
              {body}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuNotificationDrawer;

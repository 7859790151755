import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
// import Chart from "../../components/chart/Chart";
// import Table from "../../components/table/Table";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import HomeRevolutionDesign from "../../components/svg/HomeRevolutionDesign";
import Tabs, { TAB_VARIANT } from "../../components/common/Tabs";
import { useContext, useState } from "react";
import Overview from "../../components/overview/overview-sales";
import { AuthContext } from "../../context/AuthContext";
import Select from "react-select";

const Home = ({ updated }) => {
  const [activeTab, setActiveTab] = useState("Last 24 hours");
  const [selectedOption, setSelectedOption] = useState();

  const { currentUser } = useContext(AuthContext);
  const LABEL_MAPPER = {
    accuracy: "Last Year",
    false_positive_rate: "Last Quarter",
    precision: "Last Month",
  };

  const chartTabs = Object.keys(LABEL_MAPPER).map((mapperKey) => {
    return { label: LABEL_MAPPER[mapperKey], value: mapperKey };
  });

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleChange = (value) => {
    setSelectedOption(value);
  };

  const customStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      boxShadow: "none",
      borderRadius: "8px",
      padding: "7px",
      borderColor: isFocused ? "#323582" : "#000000",
      "&:hover": {
        borderColor: null,
      },
      width: "auto",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#F2F2F2" : null,
        color: "#333333",
        "&:active": {
          backgroundColor: "#F2F2F2",
        },
      };
    },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      transition: "transform ease-in-out",
    }),
  };

  return (
    <div className="">
      <div className="home">
        <div className="homeContainer">
          <div className="px-4">
            <div className="flex flex-wrap xl:items-center">
              <div className="px-6 py-6 flex-1">
                <div className="mb-2">
                  <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                    Hi {currentUser.displayName}!!!
                  </Typography>
                </div>
                <div>
                  <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                    Here’s the latest events.
                  </Typography>
                </div>
              </div>
              <div>
                <HomeRevolutionDesign />
              </div>
            </div>
            <div className="flex flex-wrap gap-5 bg-white w-full px-[20px] py-[14px] items-center">
              <Tabs
                names={[
                  "Last 24 hours",
                  "Last week",
                  "Last month",
                  "Last year",
                ]}
                active={activeTab}
                variant={TAB_VARIANT.UNDERLINE}
                onChangeActive={handleTabClick}
              />
              <div className="ml-[auto]">
                <Select
                  value={selectedOption}
                  onChange={handleChange}
                  placeholder="All projects"
                  options={[]}
                  styles={customStyles}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginLeft: 5 }}>{e.label}</span>
                    </div>
                  )}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        width: "100%",
                      },
                    },
                  }}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="widgets flex-wrap">
              <Widget type="user" value="0M" diff="0" />
              <Widget type="order" value="0K" diff="0" />
              <Widget type="earning" value="$ 0K" diff="0" />
            </div>
            <div className="grid 2xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-4">
              <div className="shadow-xl p-4 rounded-xl">
                <Featured diff="0" />
              </div>
              <div className="shadow-xl p-4 rounded-xl">
                <div className="flex flex-wrap justify-between my-4">
                  <div className="flex flex-wrap gap-2">
                    <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}>
                      Revenue
                    </Typography>
                    <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_2}>
                      {"Total Revenue: $0K"}
                    </Typography>
                  </div>
                  <Tabs
                    names={chartTabs}
                    active={activeTab}
                    variant={TAB_VARIANT.COLORED}
                    onChangeActive={handleTabClick}
                  />
                </div>
                <Overview title="Last 6 Months (Revenue)" aspect={2 / 1} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

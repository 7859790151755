export const ERROR_MAPPER = {
    "auth/invalid-login-credentials": "Wrong Email or Password!",
    "auth/too-many-requests":
        "Too many failed attempts. You can you can try again later!",
    "auth/weak-password": "Please enter a string password!",
    "auth/email-already-in-use": "You already have account with this Email.",
};

export const INFO_MESSAGE = {
    DATASET_REGISTER: {
        UPLOAD_BUTTON:
            "Dummy Dataset is as same format as real data but contains no sensitive information.",
        DATASET_NAME:
            "The data set title should be descriptive but relatively short (typically under 15 words).",
        DATASET_DESC:
            "Describe what the dataset is about and what it contains such as the size, column names, data types etc.",
        OPERATOR_EMAIL:
            "Add the email addresses of the person who can operate/run ML training on your data.",
        ACCESSIBILITY:
            "In Private mode, the dataset will only be viewed by the data operators you add. In Public mode, the dataset can be viewed by all users.",
    },
    PROJECT_REGISTER: {
        ISOLATATIONFOREST: {
            N_ESTIMATORS: " The number of base estimators in the ensemble.",
            MAX_SAMPLES:
                "The number of samples to draw from the dataset to train each base estimator.",
            CONTAMINATION: "The proportion of outliers in the data set.",
            MAX_FEATURES:
                "The number of features to draw from the dataset to train each base estimator.",
            BOOSTSRAP: "Whether samples are drawn with replacement.",
            RANDOM_STATE: "Seed used by the random number generator.",
        },
        LOGISTICREGESSION: {
            PENALTY: "The norm used in the penalization.",
            DUAL: " Dual or primal formulation. Dual formulation is only implemented for l2 penalty with liblinear solver.",
            TOL: "Tolerance for stopping criteria.",
            REGULARIZATION:
                " Inverse of regularization strength; smaller values specify stronger regularization.",
            FIT_INTERCEPT:
                "Specifies if a constant (a.k.a. bias or intercept) should be added to the decision function.",
            INTERCEPT_SCALING:
                " Useful only when the solver ‘liblinear’ is used and fit_intercept is set to True.",
            SOLVER: " Algorithm to use in the optimization problem.",
            MAX_ITER:
                " Maximum number of iterations taken for the solvers to converge.",
            MULTI_CLASS:
                "If the option chosen is ‘ovr’, then a binary problem is fit for each label. Else, it minimizes the multinomial loss.",
            RANDOM_STATE: " Seed used by the random number generator.",
        },
        NEUTRAL_NETWORKS: {
            HIDDEN_LAYER_SIZES: "The number of neurons in the hidden layers.",
            ACTIVACTION:
                " Activation function for the hidden layer ('identity', 'logistic', 'tanh', 'relu').",
            SOLVER: "The solver for weight optimization ('lbfgs', 'sgd', 'adam').",
            ALPHA: "L2 penalty (regularization term) parameter.",
            BATCH_SIZE: "Size of minibatches for stochastic optimizers.",
            LEARNING_RATE:
                "Learning rate schedule for weight updates ('constant', 'invscaling', 'adaptive').",
            LEARNING_RATE_INIT: "The initial learning rate used.",
            MAX_ITER: "Maximum number of iterations.",
            TOL: "Tolerance for stopping criteria.",
            MAOMENTUM: "Momentum for gradient descent update.",
            EARLY_STOPPING:
                "Whether to use early stopping to terminate training when validation score is not improving.",
            VALIDATION_FRACTION:
                "The proportion of training data to set aside as validation set for early stopping.",
            BETA_1:
                "Exponential decay rate for estimates of first moment vector in adam.",
            BETA_2:
                "Exponential decay rate for estimates of second moment vector in adam.",
            ESILON: "Value for numerical stability in adam.",
        },

        AUTOENCODERS: {
            ENCODING_DIM: "Number of neurons in the bottleneck (encoded representation).",
            ACTIVACTION: "Activation function to use for hidden layers (e.g., 'relu', 'sigmoid').",
            OPTIMIZER: "Optimization algorithm to use (e.g., 'adam', 'sgd').",
            LOSS: "Loss function to use (e.g., 'binary_crossentropy', 'mean_squared_error').",
            BATH_SIZE: " Number of samples per gradient update.",
            EPOCHS: "Number of epochs to train the model.",
            LEARNING_RATE: "Learning rate for the optimizer.",
            DROPOUT_RETE: "Fraction of the input units to drop for the linear transformation of the inputs.",
            REGULARIZANTION: "L2 regularization factor.",
        }
    }
};
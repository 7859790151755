import { useContext, useState } from "react";
import "./login.scss";
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ERROR_MAPPER } from "../../utils/const";
import LoginRevolutionDesign from "../../components/svg/LoginRevolutionDesign";
import LogoWhite from "../../components/svg/LogoWhite";
import LoginLogoShadow from "../../components/svg/LoginLogoShadow";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import InputTextField from "../../components/common/InputTextField";
import InputWarningIcon from "../../components/svg/InputWarningIcon";
import ShowPasswordIcon from "../../components/svg/showPasswordIcon";
import HidePasswordIcon from "../../components/svg/hidePasswordIcon";
import ForgotPasswordModal from "../../components/modals/ForgotPasswordModal";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import PopupMessage, {
  POPUP_VARIANT,
} from "../../components/common/PopupMessage";
import OverlayLoader from "../../components/common/OverlayLoader";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const Login = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openForgotPass, setOpenForgotPass] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [showVerificationAlert, setShowVerificationAlert] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPasswordResetMessage, setShowPasswordResetMessage] =
    useState(false);

  const navitage = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    setLoading(true);
    e.preventDefault();
    setShowVerificationAlert(false);
    setIsEmailVerified(true);
    setError(false);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user", user);
        setIsEmailVerified(user.emailVerified);
        const userRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userRef);
        if (!userSnapshot.exists()) {
          throw new Error({ code: "User Not found" });
        }
        setLoading(false);
        if (user.emailVerified) {
          dispatch({ type: "LOGIN", payload: {...user, userDetails: userSnapshot.data()} });
          navitage("/");
        } else {
          setUserDetails(user);
        }
      })
      .catch((error) => {
        setError(error.code);
        setLoading(false);
      });
  };

  const handleSendVerificationMail = async () => {
    try {
      setLoading(true);
      await sendEmailVerification(userDetails, {
        url: process.env.REACT_APP_REDIRECT_URL,
        handleCodeInApp: true,
      });
      setIsEmailVerified(true);
      setShowVerificationAlert(true);
      setLoading(false);
    } catch (error) {
      setError(error.code);
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full relative bg-white flex flex-row items-start justify-start tracking-[normal] [row-gap:20px] text-center text-base text-text-marine-blue font-body-1 mq1125:flex-wrap">
      <div className="h-full flex-1 bg-ui-light-grey flex flex-col items-center justify-start  box-border gap-[98px] xl:min-w-[530px] max-w-full mq750:min-w-full mq450:gap-[49px_98px] mq450:box-border mq1050:box-border overflow-auto px-[16px]">
        <form onSubmit={(e) => handleLogin(e)} className="m-auto">
          <div className="xl:w-[416px] m-auto overflow-x-auto flex flex-col items-start justify-start gap-[10px] max-w-full mb-[-26px] pb-[26px] text-left text-xl text-text-marine-blue font-body-1">
            <div className="text-center w-full flex flex-row items-start justify-start py-0 pr-[9px] pl-1 box-border text-21xl">
              <Typography
                variant={TYPOGRAPHY_VARIANT.HEADING_2}
                className="text-[#323582]"
              >
                Login to the Platform
              </Typography>
            </div>
            <InputTextField
              className="relative leading-[16px] inline-block min-w-[83px]"
              type="email"
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
              label={<span className="text-brand-marine-blue">Email</span>}
            />
            <div className="w-full">
              <InputTextField
                className="relative leading-[16px] inline-block min-w-[83px]"
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
                label={<span className="text-brand-marine-blue">Password</span>}
                passWordEyeIcon={
                  <div onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {isPasswordVisible ? (
                      <HidePasswordIcon />
                    ) : (
                      <ShowPasswordIcon />
                    )}
                  </div>
                }
              />
              <Link
                href="#"
                className="forget-password"
                onClick={() => setOpenForgotPass(true)}
              >
                <Typography variant={TYPOGRAPHY_VARIANT.LABEL_REGULAR}>
                  Forgot Password?
                </Typography>
              </Link>
            </div>
          </div>

          <div className="xl:w-[416px] m-auto flex flex-col items-end justify-start gap-[32px] max-w-full pt-[60px]">
            <Button type="submit" variant={BUTTON_VARIANT.PRIMARY}>
              LOGIN
            </Button>
            {loading && <OverlayLoader />}
            <div className="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[21px]">
              <div className="w-[249px] relative inline-block z-[1]">
                <span className="leading-[16px]">{`Don’t have an account? `}</span>
                <Link
                  to="/register"
                  className="leading-[24px] font-bold cursor-pointer decoration-none"
                >
                  REGISTER
                </Link>
              </div>
            </div>
          </div>
          <div className="xl:w-[416px] m-auto">
            {error && (
              <PopupMessage variant={POPUP_VARIANT.WARNING} className="mt-1">
                Wrong email or password!
              </PopupMessage>
            )}
            {!isEmailVerified && (
              <PopupMessage variant={POPUP_VARIANT.WARNING} className="mt-1">
                You Need to verify your email first. Verification mail is
                already sent to your email account.
                <Link
                  onClick={handleSendVerificationMail}
                  to="#"
                  style={{ textDecoration: "none" }}
                >
                  Resent Verification mail
                </Link>
              </PopupMessage>
            )}
            {showPasswordResetMessage && (
              <PopupMessage variant={POPUP_VARIANT.SUCCESS} className="mt-1">
                Reset Password link sent to your email.
              </PopupMessage>
            )}
          </div>
        </form>
        {openForgotPass && (
          <ForgotPasswordModal
            handleClose={() => setOpenForgotPass(false)}
            setLoading={setLoading}
            setShowPasswordResetMessage={setShowPasswordResetMessage}
          />
        )}
      </div>

      {/* <Logo /> */}

      <div className="animation w-[624px] h-full overflow-hidden login items-start justify-start fpt-[370px] px-20 cpb-[374px] box-border relative min-w-[624px] max-w-full text-left text-29xl text-white font-body-1">
        <div className="login-gradient"></div>
        <div className="m-auto flex flex-col gap-[45px] z-[1]">
          <div className="min-w-[624px] relative bg-text-marine-blue hidden max-w-full z-[0]" />

          {/* <LoginLogoShadow className="absolute top-[0px] left-[0px] w-full" /> */}
          <div className="absolute top-[80px] w-full left-0 right-0 flex justify-center">
            <LogoWhite className="subtract-icon" />
            {/* <div className="h-[39px] flex-1 flex flex-col items-start justify-start pt-2.5 px-0 pb-0 box-border"></div> */}
          </div>
          <div>
            <span className="text-[40px] leading-[60px] text-white">
              Discover how PryvX can revolutionize your data collaboration
              efforts.
            </span>
          </div>
          <div className="">
            {/* <LoginRevolutionDesign className="absolute bottom-[0] w-full left-[0]" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

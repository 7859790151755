export default () => {
  return (
    <svg
      width="48"
      height="24"
      viewBox="0 0 48 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="24" rx="4" fill="#5D3BDA" />
      <path
        d="M5.264 5.672H9.68C10.1067 5.672 10.528 5.72533 10.944 5.832C11.36 5.928 11.728 6.09333 12.048 6.328C12.3787 6.552 12.6453 6.84533 12.848 7.208C13.0507 7.57067 13.152 8.008 13.152 8.52C13.152 9.16 12.9707 9.688 12.608 10.104C12.2453 10.52 11.7707 10.8187 11.184 11V11.032C11.536 11.0747 11.8613 11.176 12.16 11.336C12.4693 11.4853 12.7307 11.6827 12.944 11.928C13.1573 12.1627 13.3227 12.44 13.44 12.76C13.568 13.0693 13.632 13.4053 13.632 13.768C13.632 14.3867 13.5093 14.904 13.264 15.32C13.0187 15.7253 12.6933 16.056 12.288 16.312C11.8933 16.5573 11.4347 16.7333 10.912 16.84C10.4 16.9467 9.87733 17 9.344 17H5.264V5.672ZM7.28 10.28H9.12C9.792 10.28 10.2933 10.152 10.624 9.896C10.9653 9.62933 11.136 9.28267 11.136 8.856C11.136 8.36533 10.96 8.00267 10.608 7.768C10.2667 7.52267 9.712 7.4 8.944 7.4H7.28V10.28ZM7.28 15.272H9.136C9.392 15.272 9.664 15.256 9.952 15.224C10.2507 15.192 10.5227 15.1173 10.768 15C11.0133 14.8827 11.216 14.7173 11.376 14.504C11.536 14.28 11.616 13.9813 11.616 13.608C11.616 13.0107 11.4187 12.5947 11.024 12.36C10.64 12.1253 10.0373 12.008 9.216 12.008H7.28V15.272ZM15.639 5.672H23.143V7.496H17.655V10.28H22.855V12.104H17.655V15.176H23.431V17H15.639V5.672ZM27.7403 7.496H24.2683V5.672H33.2283V7.496H29.7563V17H27.7403V7.496ZM36.803 5.672H38.547L43.427 17H41.123L40.067 14.408H35.155L34.131 17H31.875L36.803 5.672ZM39.363 12.68L37.619 8.072L35.843 12.68H39.363Z"
        fill="white"
      />
    </svg>
  );
};

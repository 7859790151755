import { Link } from "react-router-dom";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import { useState } from "react";
import QuickStartModal from "../modals/QuickStartModal";
import RemoveVector from "../svg/RemoveVector";
import LightBulb from "../svg/LightBulb";
import Campaign from "../svg/Campaign";
import { DotIcon } from "../svg/DotIcon";

const QuickStartDrawer = ({
  show = false,
  hideDrawer = () => {},
  isSidebarCompressed = false,
}) => {
  const [quickStartModal, setQuiStartModal] = useState(false);

  return (
    <div
      className={`fixed top-0 z-40 h-screen overflow-y-auto transition-transform  w-full dark:bg-gray-800 bg-[#0000004d] ${
        show ? "translate-x-unset" : "translate-x-fulls hidden"
      } ${isSidebarCompressed ? "left-[256px]" : "left-[64px]"}`}
      tabIndex={-1}
      aria-labelledby="drawer-right-label"
      // onClick={hideDrawer}
    >
      <div className="w-[50vw] me-auto h-full ml-[20px] relative">
        <div
          className="absolute bottom-[80px] flex flex-wrap flex-col h-[80%] justify-between mb-8 gap-4 px-5"
          onClick={() => {}}
        >
          <div className="bg-white mt-auto rounded-lg border-2 border-slate-300 max-w-[380px] overflow-auto pt-0">
            <div className="bg-[#A0CCFB] py-[10px] flex gap-2">
              <div className="flex">
                <div className="ml-[10px]">
                  <LightBulb fill="#2C2C2C" />
                </div>
                <div className="ml-[10px]">
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                    Quickstart
                  </Typography>
                </div>
              </div>
              <div
                className="ml-[auto] mr-[10px] pt-[10px] cursor-pointer"
                onClick={hideDrawer}
              >
                <Link>
                  <RemoveVector />
                </Link>
              </div>
            </div>
            <div className="mt-[30px] ml-[10px]">
              <Typography variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}>
                Follow the Steps to Get Started
              </Typography>
            </div>
            <div className="border-2 mt-[30px] ml-[10px] mr-[10px] rounded-lg">
              <div className="mt-[30px] ml-[20px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  1. Register Dataset
                </Typography>
              </div>
              <div className="mt-[10px] ml-[20px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  Register the test dataset you would like to collaborate on.
                </Typography>
              </div>
              <div className="mt-[30px] ml-[20px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  2. Create Project
                </Typography>
              </div>
              <div className="mt-[10px] ml-[20px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  Create a new federated project.
                </Typography>
              </div>
              <div className="mt-[10px] ml-[28px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  <div className="flex items-center">
                    <DotIcon size={6} /> Select your task and algorithm.
                  </div>
                </Typography>
              </div>
              <div className="ml-[28px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  <div className="flex items-center">
                    <DotIcon size={6} />
                    Invite collaborators to join the project.
                  </div>
                </Typography>
              </div>
              <div className="mt-[30px] ml-[20px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  3. Download Global Model
                </Typography>
              </div>
              <div className="mt-[10px] ml-[20px]">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  Once all collaborators have finished training the model, you
                  can download the global model and start using it.
                </Typography>
              </div>
            </div>
            <div
              className="mt-[30px] bg-[#A0CCFB] bg-opacity-21 flex gap-2 py-[10px] cursor-pointer hover:bg-[#7fbaf9]"
              onClick={() => setQuiStartModal(true)}
            >
              <div className="ml-[40px]">
                <Campaign />
              </div>
              <div>
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  <Link>Leave Feedback</Link>
                </Typography>
              </div>
            </div>
            <div
              className="py-[15px] mt-[10px] bg-[#DDDDDD] cursor-pointer hover:bg-[#d3d3d3]"
              onClick={hideDrawer}
            >
              <div className="text-center">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  <Link className="text-[#323582]">Dismiss Quickstart</Link>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {quickStartModal && (
        <>
          <QuickStartModal handleClose={() => setQuiStartModal(false)} />
        </>
      )}
    </div>
  );
};

export default QuickStartDrawer;

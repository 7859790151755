import { useState, useEffect, useMemo, useContext } from "react";
import {
  Unstable_Grid2 as Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  collection,
  addDoc,
  getDocs,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";

import { db } from "../../firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import constants from "./components/constants.jsx";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import ArrowBack from "../../components/svg/ArrowBack";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import InputTextField, {
  LABEL_TYPE,
} from "../../components/common/InputTextField";
import SelectField from "../../components/common/SelectField";
import ExpandMore from "../../components/svg/ExpandMore.js";
import { useForm } from "react-hook-form";
import MlSettingForms, { FIELDS_CONST } from "./components/MlSettingForms.jsx";
import OverlayLoader from "../../components/common/OverlayLoader.js";
import { v4 as uuidv4 } from "uuid";
import api, { API_CONFIG, API_REQUEST_TYPE } from "../../api/api.js";
import { AuthContext } from "../../context/AuthContext.js";
import { SnackbarContext } from "../../context/SnackbarContext.js";
import NotFound from "../404notfound/NotFound.jsx";

const RegisterProject = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit: handleFormSubmit,
    setValue,
    formState,
    watch,
  } = useForm();
  const { errors } = formState;
  const [selectedAlgorithm, setSelectedAlgorithm] = useState("");
  const [values, setValues] = useState({
    projectName: "",
    task: "",
    algorithm: "",
    dataset: "",
    mlSettings: "",
    collaborators: "",
    userId: "",
  });
  const [datasetInfo, setDatasetInfo] = useState([]);
  const [collaboratorInfo, setCollaboratorInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { userDetails } = currentUser;
  const {
    email: currentEmail,
    firstName: currentUserName,
    profileUrl: currentUserPic,
  } = userDetails;
  // console.log(
  //   "getValues:",
  //   getValues(),
  //   "\nvalues:",
  //   values,
  //   "\nerrors:",
  //   errors,
  //   "\nselectedAlgorithm:",
  //   selectedAlgorithm
  // );
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const datasetData = await api(
          API_REQUEST_TYPE.GET,
          API_CONFIG.END_POINTS.PROJECT.GET_PROJECT(id)
        ).then((res) => res.json());
        if (datasetData?.project_data) {
          setValues({
            projectName: datasetData.project_data.projectName || "",
            task: datasetData.project_data.task || "",
            algorithm: datasetData.project_data.algorithm || "",
            dataset: datasetData.project_data.datasetId || "",
            client_key: datasetData.client_key || null,
            mlSettings: datasetData.project_data.mlSettings || {},
            collaborators: datasetData.collaborators || "",
            metrics: datasetData.metrics || null,
            columnNames: datasetData.project_data.selectedDatasetColumns || [],
            targetColumn: datasetData.project_data.selectedTargetColumn || "",
            userId: datasetData.project_data.projectOwnerId || "",
          });
          setValue("projectName", datasetData.project_data.projectName || "");
          setValue("client_key", datasetData.project_data.client_key || "");
          setValue("task", datasetData.project_data.task || "");
          setValue("dataset", datasetData.project_data.datasetId || "");
          setValue(
            "collaborators",
            datasetData.collaborators.map(
              (collaborator) => collaborator.userId
            ) || []
          );
          setValue("metrics", datasetData.metrics || null);
          setValue("algorithm", datasetData.project_data.algorithm || "");
          setValue(
            "columnNames",
            datasetData.project_data.selectedDatasetColumns || []
          );
          setValue(
            "targetColumn",
            datasetData.project_data.selectedTargetColumn || ""
          );
          Object.keys(datasetData.project_data.mlSettings).forEach(
            (settingKey) => {
              setValue(
                `setting_${settingKey}`,
                datasetData.project_data.mlSettings?.[settingKey] || ""
              );
            }
          );
          setSelectedAlgorithm(datasetData.project_data.algorithm);
          setIsLoading(false);
        } else if (datasetData?.detail === "Project not found") {
          setValues(null);
          console.error("Project not found!");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, setValue]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const q = query(collection(db, "users"));
        const snapShot = await getDocs(q);
        let list = [];
        snapShot.forEach((doc) => {
          const { email, firstName, company } = doc.data();
          list.push({ id: doc.id, email, firstName, company });
        });
        setCollaboratorInfo(list);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchDatasetData = async () => {
      try {
        const q = query(collection(db, "datasets"));
        const snapShot = await getDocs(q);
        let list = [];
        snapShot.forEach((doc) => {
          const { datasetName, datasetColumns } = doc.data();
          list.push({ id: doc.id, datasetName, datasetColumns });
        });
        setDatasetInfo(list);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDatasetData();
  }, []);

  useEffect(() => {
    watch((data, { name, type }) => {
      // console.log("watch", data, type, name, data[name], "errors", errors);
      if (name === "task") {
        setValue("algorithm", "");
        setSelectedAlgorithm("");
      } else if (name === "algorithm") {
        setSelectedAlgorithm(data[name]);
      }
    });
  }, [watch]);

  const getMlSettings = (data) => {
    const algorithmKeys = {
      "Isolation Forest": "isolationForest",
      "Logistic Regression": "logisticRegression",
      "Linear Regression": "linearRegression",
    };
    const keys = Object.keys(data).filter(
      (val) =>
        val.slice(0, 7) === "setting" &&
        FIELDS_CONST[algorithmKeys[data.algorithm]][val]
    );
    console.log("data", data, "keys", keys);
    let settings = {};
    keys.forEach((val) => {
      if (data[val] === "") {
        settings[val.slice(8)] =
          FIELDS_CONST[algorithmKeys[data.algorithm]][val].defaultValue;
      } else {
        settings[val.slice(8)] = data[val];
      }
    });
    return settings;
  };

  const handleSubmit = async (data) => {
    setIsLoading(true);
    let selectedCollaborators = [];
    const createNewCollaboratorAccessKey = async (id) => {
      const res = await addDoc(
        collection(db, "project_collaborator_access_key"),
        {
          accessKey: uuidv4(),
        }
      );

      const selectedCollaboratorData = collaboratorInfo.filter(
        (collaborator) => collaborator.id === id
      )[0];
      selectedCollaborators = [
        ...selectedCollaborators,
        selectedCollaboratorData,
      ];
      return res;
    };

    let notificationTargetId;
    try {
      if (id) {
        const collaborationData = [];
        let oldCollaboratorAccessKeyId = {};
        values?.collaborators?.forEach((collaborator) => {
          oldCollaboratorAccessKeyId = {
            [collaborator.userId]: {
              accessKeyId: collaborator.accessKeyId,
              invitationStatus: collaborator.invitationStatus,
              trainingStatus: collaborator.trainingStatus,
              date: collaborator.date,
            },
            ...oldCollaboratorAccessKeyId,
          };
        });
        for (const collaborator of data.collaborators ?? []) {
          if (!Object.keys(oldCollaboratorAccessKeyId).includes(collaborator)) {
            const res = await createNewCollaboratorAccessKey(collaborator);
            collaborationData.push({
              userId: collaborator,
              accessKeyId: res.id,
              invitationStatus: "Pending",
              trainingStatus: "No Status",
              date: "",
            });
          } else {
            collaborationData.push({
              userId: collaborator,
              accessKeyId: oldCollaboratorAccessKeyId[collaborator].accessKeyId,
              invitationStatus:
                oldCollaboratorAccessKeyId[collaborator].invitationStatus,
              trainingStatus:
                oldCollaboratorAccessKeyId[collaborator].trainingStatus,
              date: oldCollaboratorAccessKeyId[collaborator].date,
            });
          }
        }
        // Edit existing item
        const docRef = doc(db, "projects", id);
        const updatedData = {
          projectName: data.projectName,
          task: data.task,
          algorithm: data.algorithm,
          datasetId: data.dataset,
          mlSettings: getMlSettings(data),
          collaborators: collaborationData,
          lastModifiedDate: Date.now(),
          selectedDatasetColumns: data.columnNames,
          selectedTargetColumn: data.targetColumn,
        };
        console.log("Update data: ", updatedData);
        await updateDoc(docRef, updatedData);
        snackbarDispatch({
          type: "SHOW_SUCCESS_SNACKBAR",
          payload: "Project updated successfully.",
        });
        notificationTargetId = id;
      } else {
        const collaborationData = [];
        for (const collaborator of data.collaborators ?? []) {
          const res = await createNewCollaboratorAccessKey(collaborator);
          collaborationData.push({
            userId: collaborator,
            accessKeyId: res.id,
            invitationStatus: "Pending",
            trainingStatus: "No Status",
            date: "",
          });
        }
        const newProjectData = {
          userId: user.uid,
          projectName: data.projectName,
          task: data.task,
          algorithm: data.algorithm,
          datasetId: data.dataset,
          mlSettings: getMlSettings(data),
          collaborators: collaborationData,
          createDate: Date.now(),
          lastModifiedDate: Date.now(),
          selectedDatasetColumns: data.columnNames,
          selectedTargetColumn: data.targetColumn,
        };
        console.log("newProjectData", newProjectData);
        // Add new item
        const res = await addDoc(collection(db, "projects"), newProjectData);
        snackbarDispatch({
          type: "SHOW_SUCCESS_SNACKBAR",
          payload: "Project created successfully.",
        });
        notificationTargetId = res.id;
      }

      selectedCollaborators.forEach(
        async ({ email, firstName, id: userId }) => {
          // Send Emails
          await api(
            API_REQUEST_TYPE.POST,
            API_CONFIG.END_POINTS.MAIL.SEND_MAIL,
            {
              emailId: email,
              subject: "Invitation to Join PryvX Project",
              body: `<body><p>Dear ${firstName}<b></b>,</p><br /><p>You have been invited to join the <b>${data.projectName}</b> as data collaborator by <b>${currentUserName}</b>.</p><p> <a href=${process.env.REACT_APP_REDIRECT_URL}>Accept</a> | <a href=${process.env.REACT_APP_REDIRECT_URL}>Deny</a></p><br /><div>The request is valid for 7 days.</div><img src="https://firebasestorage.googleapis.com/v0/b/pryvx-base.appspot.com/o/assets%2FPryvx-email-footer-logo.png?alt=media&token=f00555d6-dd65-4cc3-8c81-f41bcdaa150a" alt="image" width="75px"><div>Collaborate to combat Cybercrime</div></body>`,
            }
          );

          // Send notifications
          const newNotificationData = {
            from: currentUserName,
            picUrl: currentUserPic ?? "",
            purpose: "ADDED_COLLABORATOR",
            time: Date.now(),
            seen: false,
            action: null,
            targetName: data.projectName,
            targetId: notificationTargetId,
          };
          await updateDoc(doc(db, "notifications", userId), {
            [Date.now()]: newNotificationData,
          });
        }
      );

      navigate("/projects");
      setIsLoading(false);
    } catch (error) {
      console.error("Error adding/editing document: ", error);
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error creating/updating project.",
      });
      setIsLoading(false);
    }
  };

  const filteredAlgorithmOptions = () => {
    const task = watch("task");
    if (task === "Anomaly Detection") {
      return constants.algorithmOptions.filter((option) =>
        ["Isolation Forest", ""].includes(option.value)
      );
    } else if (task === "Classification") {
      return constants.algorithmOptions.filter((option) =>
        ["Logistic Regression", ""].includes(option.value)
      );
    } else if (task === "Regression") {
      return constants.algorithmOptions.filter((option) =>
        ["Linear Regression", ""].includes(option.value)
      );
    } else {
      return [];
    }
  };

  const columnNameOptions = () => {
    const dataset = watch("dataset");
    console.log("dataset updated: ", dataset);
    // setSelectedDatasetId(dataset);
    const filteredDataset = datasetInfo.filter(
      (info) => info.id === dataset
    )[0];
    console.log("datasetInfo", datasetInfo, "filteredDataset", filteredDataset);
    return filteredDataset?.datasetColumns?.map((col) => {
      return { label: col, value: col };
    });
  };

  const columnTargetOptions = () => {
    const allColumns = columnNameOptions();
    const selectedColumns = watch("columnNames");
    const selectedvalues = allColumns?.filter(
      (option) => !selectedColumns?.includes(option?.value)
    );
    return selectedvalues;
  };

  const mlSettingsForm = (control) => {
    switch (selectedAlgorithm) {
      case "Isolation Forest":
        return (
          <MlSettingForms.isolationForestForm
            register={register}
            formState={formState}
            control={control}
            setValue={setValue}
            mlSettings={values?.mlSettings}
          />
        );
      case "Logistic Regression":
        return (
          <MlSettingForms.logisticRegressionForm
            register={register}
            formState={formState}
            control={control}
            setValue={setValue}
            mlSettings={values?.mlSettings}
          />
        );
      case "Linear Regression":
        return (
          <MlSettingForms.linearRegressionFrom
            register={register}
            formState={formState}
            control={control}
            setValue={setValue}
            mlSettings={values?.mlSettings}
          />
        );
      default:
        return <>Add form for selected algorithm</>;
    }
  };

  const datasetOptions = datasetInfo.map((data) => {
    return { label: data.datasetName, value: data.id };
  });

  const usercollaboratorOptions = useMemo(() => {
    return collaboratorInfo
      .filter((data) => data.email !== currentEmail)
      .map((data) => {
        return { label: `${data.firstName} (${data.company})`, value: data.id };
      });
  }, [collaboratorInfo]);

  const defaultCollaborators = useMemo(() => {
    if (
      id &&
      values?.collaborators?.length > 0 &&
      usercollaboratorOptions.length > 0
    ) {
      return values?.collaborators?.map((collaborator) => collaborator?.userId);
    } else {
      return [];
    }
  }, [id, usercollaboratorOptions, values]);

  const defaultDatasetColumns = useMemo(() => {
    if (id && values?.columnNames?.length > 0) {
      return values?.columnNames?.map((columns) => columns);
    } else {
      return [];
    }
  }, [id, values]);

  if (isLoading) {
    return <OverlayLoader />;
  }
  if (values === null || (id && user && values.userId !== user.uid)) {
    return <NotFound pageName="dataset" />;
  }

  return (
    <div className="new bg-white h-[100vh] overflow-auto">
      <div className="newContainer overflow-auto">
        <div className="px-[40px] py-[32px] flex flex-col gap-[40px]">
          <div className="bg-white py-[40px] 2xl:w-[60%] m-auto lg:w-[70%] md:w-[100%] sm:w-[100%]">
            <div className="flex gap-4 mb-8 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
              <Link
                to="/projects"
                className="flex gap-2 hover:fill-brand-marine-blue"
              >
                <ArrowBack />
              </Link>
              <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                {id ? "Edit Project" : "Create New Project"}
              </Typography>
            </div>
            <div>
              <form
                className="px-[50px]"
                onSubmit={handleFormSubmit(handleSubmit)}
              >
                <div className="flex flex-col gap-[40px]">
                  <div>
                    <InputTextField
                      label={"Project Name"}
                      labelType={LABEL_TYPE.OUTLINED}
                      placeholder="Enter Project Name"
                      type="text"
                      innerRef={register("projectName").ref}
                      {...register("projectName", {
                        required: "Project Name is required.",
                      })}
                      errorMessage={errors?.projectName?.message}
                      isError={!!errors?.projectName?.message}
                    />
                  </div>

                  <div>
                    <SelectField
                      control={control}
                      label={"Select Task"}
                      labelType={LABEL_TYPE.OUTLINED}
                      options={constants.taskOptions}
                      defaultValue={values?.task}
                      innerRef={register("task").ref}
                      {...register("task", {
                        required: "Task is required.",
                      })}
                      errorMessage={errors?.task?.message}
                      isError={!!errors?.task?.message}
                    />
                  </div>
                  <div>
                    <SelectField
                      control={control}
                      labelType={LABEL_TYPE.OUTLINED}
                      label="Choose Algorithm"
                      innerRef={register("algorithm").ref}
                      {...register("algorithm", {
                        required: "Algorithm is required.",
                      })}
                      options={filteredAlgorithmOptions()}
                      defaultValue={values?.algorithm}
                      multiple={false}
                      isError={!!errors.algorithm}
                      errorMessage={errors.algorithm?.message}
                    />
                  </div>
                  <div>
                    <SelectField
                      control={control}
                      labelType={LABEL_TYPE.OUTLINED}
                      label="Choose Dataset"
                      options={datasetOptions}
                      defaultValue={values?.dataset}
                      innerRef={register("dataset").ref}
                      {...register("dataset", {
                        required: "Dataset is required.",
                      })}
                      errorMessage={errors?.dataset?.message}
                      isError={!!errors?.dataset?.message}
                    />
                  </div>
                  <div>
                    <SelectField
                      control={control}
                      labelType={LABEL_TYPE.OUTLINED}
                      label="Select Column Names"
                      multiple={true}
                      options={columnNameOptions()}
                      defaultValue={defaultDatasetColumns}
                      innerRef={register("columnNames").ref}
                      {...register("columnNames", {
                        required: "Column Names is required.",
                      })}
                      errorMessage={errors?.columnNames?.message}
                      isError={!!errors?.columnNames?.message}
                    />
                  </div>
                  <div>
                    <SelectField
                      control={control}
                      labelType={LABEL_TYPE.OUTLINED}
                      label="Select target column"
                      multiple={false}
                      options={columnTargetOptions()}
                      defaultValue={values?.targetColumn}
                      innerRef={register("targetColumn").ref}
                      {...register("targetColumn", {
                        required: "Target Column is required.",
                      })}
                      errorMessage={errors?.targetColumn?.message}
                      isError={!!errors?.targetColumn?.message}
                    />
                  </div>
                  <div>
                    <Accordion className="bg-[#e9e9e9]">
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        CONFIGURE ML SETTINGS
                      </AccordionSummary>
                      <AccordionDetails>
                        {mlSettingsForm(control)}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    <SelectField
                      control={control}
                      labelType={LABEL_TYPE.OUTLINED}
                      label="Invite Collaborators"
                      multiple={true}
                      options={usercollaboratorOptions}
                      defaultValue={defaultCollaborators}
                      innerRef={register("collaborators").ref}
                      {...register("collaborators", {
                        // required: "Collaborators is required.",
                      })}
                      toolTipTitle={
                        "You can only invite users who have registered on the PryvX platform."
                      }
                      infoIconOutsideContainer={true}
                      errorMessage={errors?.collaborators?.message}
                      isError={!!errors?.collaborators?.message}
                    />
                  </div>
                  <div className="flex justify-end gap-[16px]">
                    <Button
                      type="button"
                      variant={BUTTON_VARIANT.SECONDARY}
                      onClick={() => navigate("/projects")}
                    >
                      CANCEL
                    </Button>
                    <Button variant={BUTTON_VARIANT.PRIMARY} type={"submit"}>
                      {id ? "UPDATE" : "CREATE"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <OverlayLoader />}
    </div>
  );
};

export default RegisterProject;

export default ({ className = "", id = "", fill, width, height }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="28" height="28" rx="14" fill="#D2333A" fill-opacity="0.2" />
      <path
        d="M15 6L15 18.175L20.6 12.575L22 14L14 22L6 14L7.4 12.575L13 18.175L13 6H15Z"
        fill="#D2333A"
      />
    </svg>
  );
};

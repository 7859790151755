import React, { useCallback, useEffect, useRef, useState } from "react";
import CrossIcon from "../svg/CrossIcon";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Button from "../common/Button";
import {
  Cropper,
  CircleStencil,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import UploadFill from "../svg/UploadFill";
import { useDropzone } from "react-dropzone";
import PopupMessage, { POPUP_VARIANT } from "../common/PopupMessage";
import { Refresh } from "@mui/icons-material";

const ProfileImageEditModal = ({
  handleClose = () => { },
  setLoading = () => { },
  setProfileImageSaved = () => { },
}) => {
  const [image, setImage] = useState(null);
  const [modifiedImage, setModifiedImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setModifiedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [error, setError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // const allowedTypes = [
    //   'image/png',
    //   'image/jpeg'
    // ];
    const maxSize = 10 * 1024 * 1024;

    if (acceptedFiles.length === 0) {
      setError("Please select appropriate file.");
      return;
    }

    const file = acceptedFiles[0];

    // if (!allowedTypes.includes(file.type)) {
    //   setError("Invalid file type. Please import a valid dataset file.");
    //   return;
    // }

    if (file.size > maxSize) {
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      setError(
        `File size exceeds the limit of 10MB. Current size: ${fileSizeMB}MB`
      );
      return;
    }
    handleFileChange({ target: { files: [file] } });
    setError(null);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 1,
  });

  const handleSave = async () => {
    setLoading(true);
    try {
      setProfileImageSaved(modifiedImage);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const onChange = (cropper) => {
    cropper.getCanvas() && setModifiedImage(cropper.getCanvas()?.toDataURL());
  };

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabindex="-1"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full">
        <div className="relative bg-white rounded-lg shadow flex p-[24px] flex-col  gap-[15px] lg:w-[480px] max-w-[90%]">
          <div className="flex items-center justify-between rounded-t border-b border-[#999999] mb-3 pb-2">
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
              Edit Image
            </Typography>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="static-modal"
              onClick={handleClose}
            >
              <CrossIcon />
            </button>
          </div>
          <div className="">
            <div className="flex flex-col gap-[10px]">
              {!image ? (
                <div>
                  <div
                    {...getRootProps()}
                    className={`border-[2px] border-dashed py-[64px] px-[40px]  rounded-md flex flex-col justify-center gap-[8px] ${isDragActive
                      ? "border-brand-sea-blue bg-[#a0ccfb3d]"
                      : "border-black"
                      }`}
                  >
                    <UploadFill className="m-auto" />
                    <div className="text-center">
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <p>
                          Drag and Drop file here or{" "}
                          <span for="upload-file" className="cursor-pointer">
                            <u>Choose Dataset</u>
                          </span>
                        </p>
                      )}
                      <b>
                        <input
                          {...getInputProps()}
                          className="hidden"
                          type="file"
                          id="upload-file"
                          name="uploadedFile"
                          onChange={handleFileChange}
                          accept=".jpg"
                        />
                      </b>
                    </div>
                  </div>
                  <div className="flex justify-between py-3">
                    <Typography
                      variant={TYPOGRAPHY_VARIANT.BODY_2}
                      className="text-[#999999] text-[17px]"
                    >
                      Supported formats: JPEG, JPG, PNG
                    </Typography>
                    <Typography
                      variant={TYPOGRAPHY_VARIANT.BODY_2}
                      className="text-right text-[#999999] text-[17px]"
                    >
                      Maximum size: 10MB
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className="rounded-full px-10px">
                  <Cropper
                    src={image}
                    onChange={onChange}
                    className={
                      "cropper w-[350px] min-w-[350px] h-[350px] min-h-[350px] m-auto"
                    }
                    stencilComponent={CircleStencil}
                  />
                  <div className="flex justify-between px-9 pt-2">
                    <span
                      className="flex items-center"
                      onClick={() => setImage(null)}
                    >
                      <Refresh />
                    </span>
                    <Button className="mt-3 ml-[53%]" onClick={handleSave}>
                      SAVE
                    </Button>
                  </div>
                </div>
              )}
              {error && (
                <PopupMessage variant={POPUP_VARIANT.WARNING} className="mt-1">
                  {error}
                </PopupMessage>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageEditModal;

const SnackbarReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_SUCCESS_SNACKBAR":
      return [
        ...state,
        {
          show: true,
          color: "success",
          snackbarContent: action.payload,
        },
      ];
    case "SHOW_ERROR_SNACKBAR":
      return [
        ...state,
        {
          show: true,
          color: "danger",
          snackbarContent: action.payload,
        },
      ];
    case "HIDE_SNACKBAR":
      let currentState = [...state];
      currentState.splice(action.payload, 1);
      return [...currentState];
    default:
      return state;
  }
};

export default SnackbarReducer;

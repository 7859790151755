export const TAB_VARIANT = {
  COLORED: "colored",
  BORDERED: "bordered",
  UNDERLINE: "underline",
};
const Tabs = ({
  names = [],
  active = "",
  onChangeActive = () => { },
  variant = TAB_VARIANT.COLORED,
}) => {
  if (!Object.values(TAB_VARIANT).includes(variant)) {
    console.error("You need to access Tab variant from TAB_VARIANT");
    return "";
  }
  const getActiveClass = () => {
    if (variant === TAB_VARIANT.COLORED) {
      return "bg-[#1E1E4C] text-white bg-[#A0CCFB] px-[23px] py-[8px] rounded-full flex";
    } else if (variant === TAB_VARIANT.BORDERED) {
      return "text-black border-2 border-sky-blue px-[23px] py-[8px] rounded-full flex";
    } else if (variant === TAB_VARIANT.UNDERLINE) {
      return "text-[#3138A8] border-b-2 border-[#3138A8]  py-[2px] flex";
    }
  };
  const getInactiveClass = () => {
    if (variant === TAB_VARIANT.COLORED) {
      return "text-[#000000] bg-[#dddddd] rounded-full px-[23px] py-[8px] flex";
    } else if (variant === TAB_VARIANT.BORDERED) {
      return "text-[#999999] border border-[#999999] rounded-full px-[23px] py-[8px] flex";
    } else if (variant === TAB_VARIANT.UNDERLINE) {
      return "text-[#999999] py-[2px] flex";
    }
  };

  return (
    <ul className="flex flex-wrap gap-[24px]">
      {names.map((name) => {
        let label;
        let value;
        if (typeof name === "string") {
          label = name;
          value = name;
        } else {
          label = name.label;
          value = name.value;
        }
        return (
          <li>
            <a
              href="#"
              className={
                value === active ? getActiveClass() : getInactiveClass()
              }
              onClick={() => onChangeActive(value)}
            >
              {label}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Tabs;

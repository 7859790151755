import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import LogoWhite from "../svg/LogoWhite";
import DashboardFill from "../svg/DashboardFill";
import DatasetFill from "../svg/DatasetFill";
import OtherHosuesFill from "../svg/OtherHosuesFill";
import NotificationsFill from "../svg/NotificationsFill";
import CircleFill from "../svg/CircleFill";
// import SettingsFill from "../svg/SettingsFill";
import LogoutFill from "./LogoutFill";
import NavigateBeforeFill from "../svg/NavigateBeforeFill";
import LogoCompressed from "../svg/LogoCompressed";
import DefaultProfilePic from "../../components/svg/DefaultProfilePic";
import { NotificationsMenuBody } from "../../pages/projects/components/drawerBody";
import MenuNotificationDrawer from "../common/MenuNotificationDrawer";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import ApiIcon from "../svg/ApiIcon";
import BetaIcon from "../svg/BetaIcon";
import QuickStartDrawer from "../common/QuickStartDrawer";
import LightBulb from "../svg/LightBulb";

const Sidebar = () => {
  const navitage = useNavigate();
  const { dispatch, currentUser } = useContext(AuthContext);
  const { userDetails, uid } = currentUser;
  const { profileUrl, firstName, showModelHub } = userDetails;
  const currentPath = window.location.pathname;
  const [isCompressed, setIsCompressed] = useState(false);
  const [defaultNotifications, setDefaultNotifications] = useState({});
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotificationsDrawer, setShowNotificationsDrawer] = useState(false);
  const [showQuickStartDrawer, setShowQuickStartDrawer] = useState(false);

  useEffect(() => {
    // LISTEN (REALTIME) Notification fetch
    const unsub = onSnapshot(
      doc(db, "notifications", uid),
      (snapShot) => {
        const jsonData = snapShot.data();
        setDefaultNotifications(jsonData);
        let sortedKeys = Object.keys(jsonData).sort((a, b) => b - a);
        let sortedArray = [];
        let newNotifi = false;
        sortedKeys.forEach((key) => {
          sortedArray.push(jsonData[key]);
          if (jsonData[key].seen === false) {
            newNotifi = true;
          }
        });
        setNotifications(sortedArray);
        setHasNewNotifications(newNotifi);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const handleToggle = () => {
    setIsCompressed(!isCompressed);
  };

  const openNotification = () => {
    setShowNotificationsDrawer(true);
  };

  const closeNotification = () => {
    let updatedNotifications = JSON.parse(JSON.stringify(defaultNotifications));
    const keys = Object.keys(defaultNotifications);
    keys.forEach((key) => {
      updatedNotifications[key].seen = true;
    });

    updateDoc(doc(db, "notifications", uid), updatedNotifications);
    setShowNotificationsDrawer(false);
  };

  const openQuickStart = () => {
    setShowQuickStartDrawer(true);
  };
  return (
    <div
      className={
        !isCompressed
          ? "sidebar-tailwind ease-in duration-500 flex justify-between flex-col"
          : "flex justify-between flex-col ease-in duration-500 sidebar-compressed"
      }
    >
      <div className="top">
        <div className="pb-[20px] flex justify-center">
          {!isCompressed ? (
            <Link to="/">
              <LogoWhite />
              <div className={"ml-[70px] items-center mt-[10px]"}>
                {<BetaIcon />}
              </div>
            </Link>
          ) : (
            <Link to="/">
              <LogoCompressed className="ml-[5px]" />
              <div className={"items-center mt-[10px]"}>{<BetaIcon />}</div>
            </Link>
          )}
        </div>
        <ul>
          <Link to="/">
            <li
              className={
                currentPath === "/"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <DashboardFill />
              {!isCompressed && <span>Dashboard</span>}
            </li>
          </Link>

          <Link to="/datasets">
            <li
              className={
                currentPath === "/datasets"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <DatasetFill />
              {!isCompressed && <span>Datasets</span>}
            </li>
          </Link>

          <Link to="/projects">
            <li
              className={
                currentPath === "/projects"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <OtherHosuesFill />
              {!isCompressed && <span>Projects</span>}
            </li>
          </Link>

          {/* <Link to="/apis">
            <li
              className={
                currentPath === "/apis"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <ApiIcon />
              {!isCompressed && <span>APIs</span>}
            </li>

          </Link> */}

          {showModelHub && (
            <Link to="/models">
              <li
                className={
                  currentPath === "/models"
                    ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                    : "inline-flex gap-[16px] items-center"
                }
              >
                <ApiIcon />
                {!isCompressed && <span>Models</span>}
              </li>
            </Link>
          )}
        </ul>
      </div>

      <div className="bottom">
        <ul>
          <Link to="/profile">
            <li
              className={
                currentPath === "/profile"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              {!!profileUrl ? (
                <img
                  src={profileUrl}
                  alt=""
                  className="w-[40px] min-w-[40px] h-[40px] rounded-full"
                />
              ) : (
                <span className="bg-[#dddddd] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                  <DefaultProfilePic height="20px" width="20px" />
                </span>
              )}
              {!isCompressed && <span>{firstName}</span>}
            </li>
          </Link>

          <Link to={"#"} onClick={() => openNotification()}>
            <li className="inline-flex gap-[16px] items-center">
              <NotificationsFill />
              {!isCompressed && <span>Notifications</span>}
              <div className="">
                {hasNewNotifications ? <CircleFill fill="#FF0000" /> : ""}
              </div>
            </li>
          </Link>

          <Link to={"#"} onClick={() => openQuickStart()}>
            <li className="inline-flex gap-[16px] items-center">
              <LightBulb fill="white" />
              {!isCompressed && <span>Quick Start</span>}
            </li>
          </Link>
          {/* <li className="inline-flex gap-[16px] items-center">
            <SettingsFill />
            {!isCompressed && <span>Settings</span>}
          </li> */}

          <li className="flex gap-[16px] items-center">
            <div
              className="flex gap-[16px] items-center"
              onClick={async () => {
                signOut(auth);
                dispatch({ type: "LOGOUT" });
                navitage("/login");
              }}
            >
              <LogoutFill className="cursor-pointer" />
              {!isCompressed && <span className="cursor-pointer">Logout</span>}
            </div>
          </li>
        </ul>
      </div>

      <button
        onClick={handleToggle}
        className="absolute right-[-23px] top-[55px] bg-[#1e1e4c] py-[8px] pr-[4px] pl-[4px] text-text-marine-blue rounded-md"
      >
        {!isCompressed ? (
          <NavigateBeforeFill />
        ) : (
          <NavigateBeforeFill className="rotate-180" />
        )}
      </button>
      <MenuNotificationDrawer
        show={showNotificationsDrawer}
        hideDrawer={closeNotification}
        title="Notifications"
        body={<NotificationsMenuBody notifications={notifications} />}
        isSidebarCompressed={!isCompressed}
      />
      <QuickStartDrawer
        show={showQuickStartDrawer}
        hideDrawer={() => setShowQuickStartDrawer(false)}
        isSidebarCompressed={!isCompressed}
      />
    </div>
  );
};

export default Sidebar;

export default ({ className = "", id = "", fill, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      className={className}
      id={id}
    >
      <path
        d="M4.50016 20.4999V11.2403L2.63286 12.673L1.73096 11.4769L12.0001 3.625L22.2693 11.4769L21.3674 12.6576L19.5001 11.2403V20.4999H4.50016ZM6.00013 18.9999H18.0001V10.0999L12.0001 5.52495L6.00013 10.0999V18.9999ZM8.00013 14.8846C7.75527 14.8846 7.54662 14.7983 7.37418 14.6259C7.20175 14.4535 7.11553 14.2448 7.11553 13.9999C7.11553 13.7551 7.20175 13.5464 7.37418 13.374C7.54662 13.2016 7.75527 13.1153 8.00013 13.1153C8.245 13.1153 8.45365 13.2016 8.62608 13.374C8.79852 13.5464 8.88473 13.7551 8.88473 13.9999C8.88473 14.2448 8.79852 14.4535 8.62608 14.6259C8.45365 14.7983 8.245 14.8846 8.00013 14.8846ZM12.0001 14.8846C11.7553 14.8846 11.5466 14.7983 11.3742 14.6259C11.2017 14.4535 11.1155 14.2448 11.1155 13.9999C11.1155 13.7551 11.2017 13.5464 11.3742 13.374C11.5466 13.2016 11.7553 13.1153 12.0001 13.1153C12.245 13.1153 12.4536 13.2016 12.6261 13.374C12.7985 13.5464 12.8847 13.7551 12.8847 13.9999C12.8847 14.2448 12.7985 14.4535 12.6261 14.6259C12.4536 14.7983 12.245 14.8846 12.0001 14.8846ZM16.0001 14.8846C15.7553 14.8846 15.5466 14.7983 15.3742 14.6259C15.2017 14.4535 15.1155 14.2448 15.1155 13.9999C15.1155 13.7551 15.2017 13.5464 15.3742 13.374C15.5466 13.2016 15.7553 13.1153 16.0001 13.1153C16.245 13.1153 16.4537 13.2016 16.6261 13.374C16.7985 13.5464 16.8847 13.7551 16.8847 13.9999C16.8847 14.2448 16.7985 14.4535 16.6261 14.6259C16.4537 14.7983 16.245 14.8846 16.0001 14.8846Z"
        fill="white"
      />
    </svg>
  );
};

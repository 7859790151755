import React, { useContext, useState } from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Close from "../svg/Close";
import Button, { BUTTON_VARIANT } from "../common/Button";
import InputTextArea from "../common/InputTextArea";
import { LABEL_TYPE } from "../common/InputTextField";
import api, { API_CONFIG, API_REQUEST_TYPE } from "../../api/api";
import { AuthContext } from "../../context/AuthContext";
import { SnackbarContext } from "../../context/SnackbarContext";
import OverlayLoader from "../common/OverlayLoader";

const QuickStartModal = ({ handleClose = () => {} }) => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthContext);
  const [isloading, setIsLoading] = useState(false);
  const { uid, userDetails } = currentUser;
  const { email, firstName } = userDetails;
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFeedbackSubmit = async () => {
    try {
      setIsLoading(true);
      if (feedbackMessage.trim() === "") {
        setErrorMessage("Enter Your feedback");
        setIsLoading(false);
        return;
      }
      const mailBody = `<body><div><p><b>User Id:</b> ${uid}</p><p><b>User Email:</b> ${email}</p></div><br/><br/><div><p><b>Feedback:</b></p>${feedbackMessage}</div></body>`;
      await api(API_REQUEST_TYPE.POST, API_CONFIG.END_POINTS.MAIL.SEND_MAIL, {
        emailId: "hello@pryvx.com",
        subject: `Feedback from ${firstName}`,
        body: mailBody,
      });
      snackbarDispatch({
        type: "SHOW_SUCCESS_SNACKBAR",
        payload: "Feedback Submitted successfully.",
      });
      setFeedbackMessage("");
      setIsLoading(false);
      handleClose();
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Got error while sending your feedback",
      });
      setIsLoading(false);
    }
  };

  const updateFeedbackMessage = (e) => {
    const value = e.target.value;
    setFeedbackMessage(value);
    if (value !== "") {
      setErrorMessage(null);
    }
  };
  return (
    <div
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
        <div className="bg-white py-[15px] px-[25px] w-[550px] rounded-lg">
          <div className="flex flex-col h-[auto] gap-[24px] relative">
            <div>
              <button
                className="ml-auto absolute right-[0] mt-[30px]"
                onClick={handleClose}
              >
                <Close />
              </button>
            </div>
            <div>
              <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}>
                Share your thoughts
              </Typography>
            </div>
            <div className="mt-[30px]">
              <InputTextArea
                value={feedbackMessage}
                onChange={updateFeedbackMessage}
                label={
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>
                    What's on your mind?
                  </Typography>
                }
                labelType={LABEL_TYPE.OUTLINED}
                rows={10}
                placeholder="Feedback text"
                type="text"
                errorMessage={errorMessage}
                error={!!errorMessage}
              />
            </div>
            <div className="flex gap-2 px-[20px] mt-[30px]">
              <Button onClick={handleClose} variant={BUTTON_VARIANT.SECONDARY}>
                Cancel
              </Button>
              <Button onClick={handleFeedbackSubmit}>Send feedback</Button>
            </div>
          </div>
        </div>
      </div>
      {isloading && <OverlayLoader />}
    </div>
  );
};

export default QuickStartModal;

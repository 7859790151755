import {
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../firebase";
import CrossIcon from "../svg/CrossIcon";
import InputTextField from "../common/InputTextField";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Button from "../common/Button";
import PopupMessage, { POPUP_VARIANT } from "../common/PopupMessage";
import { EmailAuthProvider } from "firebase/auth/cordova";
import HidePasswordIcon from "../svg/hidePasswordIcon";
import ShowPasswordIcon from "../svg/showPasswordIcon";
import DefaultProfilePic from "../../components/svg/DefaultProfilePic";

const ResetPassword = ({
  handleClose = () => { },
  setLoading = () => { },
  profileImage = "",
  firstName = "",
  setShowPasswordResetMessage = () => { },
}) => {
  //   const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const checkOldPass = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      var credential = EmailAuthProvider.credential(
        user.email,
        oldPassword // User's current password
      );
      await reauthenticateWithCredential(user, credential)
      setAuthSuccess(true);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleResetPass = async () => {
    setLoading(true);
    try {
      if (newPassword !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      const user = auth.currentUser;
      if (!user) {
        throw new Error("No user is currently signed in.");
      }

      updatePassword(user, newPassword)
        .then(() => {
          console.log("Password updated successfully");
        })
        .catch((error) => {
          console.log(error.message);
          setError(error.message);
        });
      setSuccessMessage("Password changed successfully.");
      setLoading(false);
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabindex="-1"
      className="overflow-y-auto fixed top-0  z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]  translate-x-unset"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full">
        <div className="relative bg-white rounded-lg shadow flex p-[24px] flex-col gap-[15px] lg:w-[480px] max-w-[90%]">
          <div className="flex items-center justify-between rounded-t">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="static-modal"
              onClick={handleClose}
            >
              <CrossIcon />
            </button>
          </div>
          <div className="px-10">
          <div className="flex gap-[10px] items-center pb-5">
            {!!profileImage ?
              <img
                src={profileImage}
                alt="Cropped"
                className="rounded-full"
                width="70px"
                height="70px"
              /> :
              <span className="bg-[#dddddd] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                <DefaultProfilePic height="20px" width="20px" />
              </span>
            }
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
              {firstName}
            </Typography>
          </div>
          <div className="flex flex-col gap-[32px] mt-5">
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3} className="text-center">
              Change Password?
            </Typography>
            {successMessage ? (
              <>
                {successMessage && (
                  <PopupMessage
                    variant={POPUP_VARIANT.SUCCESS}
                    className="mt-1"
                  >
                    {successMessage}
                  </PopupMessage>
                )}
              </>
            ) : (
              <>
                {!authSuccess ? (
                  <InputTextField
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="********"
                    value={oldPassword}
                    label="Old Password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    passWordEyeIcon={
                      <div
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? (
                          <HidePasswordIcon />
                        ) : (
                          <ShowPasswordIcon />
                        )}
                      </div>
                    }
                  />
                ) : (
                  <>
                    <InputTextField
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="********"
                      value={newPassword}
                      label="New Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      passWordEyeIcon={
                        <div
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVisible)
                          }
                        >
                          {isPasswordVisible ? (
                            <HidePasswordIcon />
                          ) : (
                            <ShowPasswordIcon />
                          )}
                        </div>
                      }
                    />
                    <InputTextField
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="********"
                      value={confirmPassword}
                      label="Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      passWordEyeIcon={
                        <div
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVisible)
                          }
                        >
                          {isPasswordVisible ? (
                            <HidePasswordIcon />
                          ) : (
                            <ShowPasswordIcon />
                          )}
                        </div>
                      }
                    />
                  </>
                )}
              </>
            )}

            {error && (
              <PopupMessage variant={POPUP_VARIANT.WARNING} className="mt-1">
                {error}
              </PopupMessage>
            )}
            {!authSuccess ? (
              <Button onClick={checkOldPass}>NEXT</Button>
            ) : (
              <Button onClick={handleResetPass}>RESET MY PASSWORD</Button>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ResetPassword;

import React, { useContext, useState } from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import WarningFill from "../svg/WarningFill";
import { deleteDoc, doc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import OverlayLoader from "../common/OverlayLoader";
import InputTextField from "../common/InputTextField";
import PopupMessage, { POPUP_VARIANT } from "../common/PopupMessage";
import Button, { BUTTON_VARIANT } from "../common/Button";
import { deleteUser, reauthenticateWithCredential, signOut } from "firebase/auth";
import HidePasswordIcon from "../svg/hidePasswordIcon";
import ShowPasswordIcon from "../svg/showPasswordIcon";
import { EmailAuthProvider } from "firebase/auth/cordova";
import DefaultProfilePic from "../../components/svg/DefaultProfilePic";
import CrossIcon from "../svg/CrossIcon";

const DeleteProfileModal = ({
  handleClose = () => { },
  // handleDelete = () => {},
  profileImage = "",
  firstName = "",
  deleteButtonText = "Delete",
}) => {
  const [isloading, setIsLoading] = useState(false);
  const { currentUser, dispatch } = useContext(AuthContext);
  const [email, setEmail] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [error, setError] = useState(null);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { uid: currentUserId, userDetails } = currentUser;

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      console.log("dalete currentUserId ", currentUserId);
      await deleteDoc(doc(db, "users", currentUserId));
      await deleteUser(auth.currentUser)
      dispatch({ type: "LOGOUT" });
      signOut(auth)
    } catch (err) {
      console.log(err);
      setError(err)
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthentication = async () => {
    if (email !== currentUser.userDetails.email) {
      setError("Enter correct email....")
      return
    }
    else {
      setIsLoading(true);
      try {
        const user = auth.currentUser;
        var credential = EmailAuthProvider.credential(
          user.email,
          currentPassword // User's current password
        );
        await reauthenticateWithCredential(user, credential)
        setAuthSuccess(true);
        setIsLoading(false);
        setError(null)
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    }
  }

  return (
    <div
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#32358280]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full">
        <div className="relative bg-white rounded-lg shadow flex p-[24px] flex-col gap-[15px] lg:w-[480px] max-w-[90%]">
          <div className="text-center flex flex-col relative ">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
              data-modal-hide="static-modal"
              onClick={handleClose}
            >
              <CrossIcon />
            </button>
            <div className="px-10">
              {!authSuccess ? (<>
                <div className="flex gap-[10px] items-center pb-5">
                  {!!profileImage ?
                    <img
                      src={profileImage}
                      alt="Cropped"
                      className="rounded-full"
                      width="70px"
                      height="70px"
                    /> :
                    <span className="bg-[#dddddd] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                      <DefaultProfilePic height="20px" width="20px" />
                    </span>
                  }
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1} className="text-center mr-20 flex-none">
                    {firstName}
                  </Typography>
                </div>
                <div className="">
                  <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4} className="text-center mb-10" >
                    Delete Profile?
                  </Typography>
                  <InputTextField
                    type="text"
                    placeholder="email"
                    value={email}
                    label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <InputTextField
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="********"
                    value={currentPassword}
                    label="Current Password"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    passWordEyeIcon={
                      <div
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? (
                          <HidePasswordIcon />
                        ) : (
                          <ShowPasswordIcon />
                        )}
                      </div>
                    }
                  />
                </div>
                <div className="mb-[30px]">
                  <Button
                    onClick={handleAuthentication}
                  >
                    CONTINUE
                  </Button>
                </div>
              </>) : (<>
                <div className="px-10">
                  <div className="text-center mb-[30px]">
                    <span className="flex items-center justify-center bg-[#d2333a33] rounded-full p-[8px] m-auto w-[40px] min-w-[40px] h-[40px]">
                      <WarningFill />
                    </span>
                  </div>
                  <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4} className="text-center mb-[30px]">
                    Delete Profile?
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}></Typography>
                  <div className="mb-[30px]">
                    <Button
                      onClick={handleDelete}
                      variant={BUTTON_VARIANT.PRIMARY}

                    >
                      Delete Profile
                    </Button>
                  </div>
                  <div className="mb-[30px]">
                    <Button
                      onClick={handleClose}
                      variant={BUTTON_VARIANT.SECONDARY}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>)}
              {error && (
                <PopupMessage variant={POPUP_VARIANT.WARNING} className="mt-1">
                  {error}
                </PopupMessage>
              )}
            </div>
          </div>
          {isloading && <OverlayLoader />}
        </div>
      </div>
    </div>
  );
};

export default DeleteProfileModal;

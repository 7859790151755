export default () => {
  return (
    <svg
      width="138"
      height="120"
      viewBox="0 0 138 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-spin m-auto"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"gout
        d="M64.9132 0H34.5786C34.5951 0 34.5854 2.39044 34.5786 3.58566H62.8114C62.8422 3.58566 64.6486 6.77291 65.548 8.36653H29.7585V11.9837H67.6527L70.3887 16.8127H24.933L24.9178 20.3984H72.4777L75.2294 25.1793H20.1008V28.7765H77.2927L80.0702 33.5458H15.287L0 60L3.09488 61.753L17.2996 37.1315H22.7752L4.84071 68.3665L7.9356 70.1992L26.9017 37.1315H32.4566L9.68143 76.8128L12.7763 78.5657L36.5831 37.1315H42.0587L14.4428 85.1793L17.5377 86.9323L46.1852 37.1315H51.7161L19.2835 93.5458L34.5198 120L37.6147 118.167L23.41 93.5458L26.1875 88.7649L44.1219 120L47.2168 118.167L28.2507 85.1793L31.0282 80.3984L53.8033 120L56.8982 118.167L33.0914 76.7331L35.8689 71.9522L63.4848 120L66.5796 118.167L37.9321 68.3665L40.6302 63.5857L73.0868 120H103.48V116.414H75.1501L72.452 111.633L108.321 111.554V107.968H70.3094L67.6113 103.187H113.162V99.6016H65.5087L62.7706 94.8207H117.977V91.2351H60.7073L57.9298 86.4542H122.764L138 60L134.905 58.1673L120.7 82.8685H115.225L133.159 51.6335L130.064 49.8008L111.098 82.8685H105.543L128.319 43.1873L125.224 41.4343L101.417 82.8685H95.9414L123.478 34.8207L120.383 33.0677L91.7355 82.8685H86.2599L118.717 26.4542L103.48 0L100.385 1.83267L114.59 26.4542L111.813 31.2351L93.7987 0L90.7039 1.83267L109.749 34.8207L106.972 39.6016L84.1967 0L81.1018 1.83267L104.909 43.1873L102.131 47.9681L74.5152 0L71.4204 1.83267L100.068 51.6335L97.2904 56.4143L64.9132 0ZM55.8676 37.1315H82.1456L95.2281 60L82.1344 82.7888H55.8676L42.7739 60L55.8676 37.1315Z"
        fill="url(#paint0_radial_645_7673)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_645_7673"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(72.9701 56.5385) rotate(116.693) scale(66.5114 76.6149)"
        >
          <stop offset="0.290742" stopColor="#569EF8" />
          <stop offset="0.99842" stopColor="#3138A8" />
        </radialGradient>
      </defs>
    </svg>
  );
};

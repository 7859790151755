import "./style.scss";
import { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  // getDocFromCache,
  getDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

import { db, storage } from "../../firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import InfoFill from "../../components/svg/InfoFill";
import UploadFileFill from "../../components/svg/UploadFileFill";
import CloseFill from "../../components/svg/CloseFill";
import ArrowBack from "../../components/svg/ArrowBack";
import InputTextField, {
  LABEL_TYPE,
} from "../../components/common/InputTextField";
import InputTextArea from "../../components/common/InputTextArea";
// import InputTagContainer from "../../components/common/InputTagContainer";
import UploadDatasetModal from "../../components/modals/UploadDatasetModal";
import { INFO_MESSAGE } from "../../utils/const";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import OverlayLoader from "../../components/common/OverlayLoader";
import CustomTooltip from "../../components/common/CustomTooltip/CustomTooltip";
import { read as xlsxRead, utils as xlsxUtils } from "xlsx";
import { SnackbarContext } from "../../context/SnackbarContext";
import NotFound from "../404notfound/NotFound";
import PopupMessage, {
  POPUP_VARIANT,
} from "../../components/common/PopupMessage";

const Register = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    userId: "",
    csvFile: null,
  });

  const [loading, setLoading] = useState(false);
  const [projectsAssociated, setprojectsAssociated] = useState(0);
  const [isUploadDatasetDialogOpen, setIsUploadDatasetDialogOpen] =
    useState(false);
  // const [dbOperatorTags, setDbOperatorTags] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const {
    register,
    handleSubmit: handleFormSubmit,
    // getValues,
    setValue: setFormValue,
    formState,
  } = useForm();
  const { errors, isSubmitted } = formState;
  // console.log("getValues", getValues(), "values", values, "errors", errors);
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const snapShot = await getDoc(doc(db, "datasets", id));
          const idValue = snapShot.data();
          console.log("idValue", idValue);
          if (idValue === undefined) {
            setValues(null);
            return;
          }
          setValues({
            ...values,
            userId: idValue.userId,
            csvFile: { name: idValue.fileName || "", fileURL: idValue.fileURL },
          });
          setCsvFile({
            name: idValue.fileName || "",
            fileURL: idValue.fileURL,
          });
          setFormValue("datasetName", idValue.datasetName ?? "");
          setFormValue("desc", idValue.description ?? "");
          setFormValue("datasetColumns", idValue.datasetColumns ?? []);
          // setDbOperatorTags(idValue.dataOperator ?? []);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

      const fetchAssosiatedProjects = async () => {
        try {
          setLoading(true);
          const q = query(
            collection(db, "projects"),
            where("datasetId", "==", id)
          );
          const snapShot = await getDocs(q);
          setprojectsAssociated(snapShot.size);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

      fetchData();
      fetchAssosiatedProjects();
    }
  }, [id]);

  const handleFileChange = async (data) => {
    const file = data.target.files[0];
    if (file) {
      const fileNameWithoutExt = file.name.split(".").slice(0, -1).join(".");
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileResult = e.target.result;

        const fileExtention = file.name.split(".").slice(-1).toString();
        // Parse the CSV data and set the tableData state
        let customDesc = "";
        let datasetColumns = [];

        if (fileExtention === "csv") {
          customDesc =
            "This dataset contains a single file with the following columns:\n";
          console.log("fileResult", fileResult);
          parseCsvData(fileResult)[0].forEach((header) => {
            customDesc += `- ${header}: Add more details about this field\n`;
            datasetColumns = [...datasetColumns, header];
          });
          setFormValue("datasetName", fileNameWithoutExt ?? "");
          setFormValue("desc", customDesc ?? "");
          setFormValue("datasetColumns", datasetColumns);
          setValues((prevState) => ({ ...prevState, csvFile: file }));
        } else if (fileExtention === "xlsx") {
          const xlsxToCsv = async () => {
            const ab = await file.arrayBuffer();
            const wb = xlsxRead(ab);
            var res = "";
            wb.SheetNames.forEach((n) => {
              const ws = wb.Sheets[n];
              res += xlsxUtils.sheet_to_csv(ws) + "\n\n";
            });
            customDesc =
              "This dataset contains a single file with the following columns:\n";
            parseCsvData(res)[0].forEach((header) => {
              customDesc += `- ${header}: Add more details about this field\n`;
              datasetColumns = [...datasetColumns, header];
            });
            const csvBlob = new Blob([res], { type: "text/csv" });
            const csvFile = new File([csvBlob], fileNameWithoutExt + ".csv", {
              type: "text/csv",
            });
            console.log("res", res, "blob", csvBlob, "file", csvFile);
            setFormValue("datasetName", fileNameWithoutExt ?? "");
            setFormValue("desc", customDesc ?? "");
            setFormValue("datasetColumns", datasetColumns);
            setValues((prevState) => ({ ...prevState, csvFile: csvFile }));
          };
          xlsxToCsv();
        }
      };
      reader.readAsText(file);
    }
  };

  const parseCsvData = (csvData) => {
    const parsedData = Papa.parse(csvData, { header: false });
    return parsedData.data;
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    if (id) {
      if (values.userId !== user.uid) {
        setLoading(false);
        snackbarDispatch({
          type: "SHOW_ERROR_SNACKBAR",
          payload: "Not Autherized to update this dataset",
        });
        return;
      }
      try {
        let downloadURL = null;
        const updatedDataset = {
          userId: user.uid,
          datasetName: data.datasetName,
          description: data.desc,
          datasetColumns: data.datasetColumns,
          // dataOperator: dbOperatorTags,
          lastModifiedDate: Date.now(),
        };
        console.log("updatedDataset", updatedDataset);
        if (values.csvFile?.size) {
          const date = new Date().getTime();
          const fileName = `${data.datasetName}_${date}.${values.csvFile.name
            .split(".")
            .slice(-1)
            .toString()}`;
          const storageRef = ref(storage, `database-files/${fileName}`);
          const uploadTaskSnapshot = await uploadBytesResumable(
            storageRef,
            values.csvFile
          );
          downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
          updatedDataset.fileURL = downloadURL;
          updatedDataset.fileName = values?.csvFile?.name;
        } else if (values.csvFile === null) {
          updatedDataset.fileURL = null;
          updatedDataset.fileName = null;
        } else if (!values.csvFile?.size && values.csvFile) {
          updatedDataset.fileURL = values.csvFile.fileURL;
          updatedDataset.fileName = values.csvFile.name;
        }

        await updateDoc(doc(db, "datasets", id), updatedDataset);

        snackbarDispatch({
          type: "SHOW_SUCCESS_SNACKBAR",
          payload: "Dataset updated successfully.",
        });
        setLoading(false);
        navigate("/datasets");
      } catch (error) {
        snackbarDispatch({
          type: "SHOW_ERROR_SNACKBAR",
          payload: "Error while updating dataset.",
        });
        console.error(error);
        setLoading(false);
      }
    } else {
      try {
        let downloadURL = null;
        if (values.csvFile) {
          const date = new Date().getTime();
          const fileName = `${data.datasetName}_${date}.${values.csvFile.name
            .split(".")
            .slice(-1)
            .toString()}`;
          const storageRef = ref(storage, `database-files/${fileName}`);
          const uploadTaskSnapshot = await uploadBytesResumable(
            storageRef,
            values.csvFile
          );
          downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
        }
        await addDoc(collection(db, "datasets"), {
          userId: user.uid,
          datasetName: data.datasetName,
          description: data.desc,
          datasetColumns: data.datasetColumns,
          // dataOperator: dbOperatorTags,
          fileURL: values.csvFile ? downloadURL : "",
          fileName: values.csvFile?.name ?? "",
          createDate: Date.now(),
          lastModifiedDate: Date.now(),
        });

        snackbarDispatch({
          type: "SHOW_SUCCESS_SNACKBAR",
          payload: "Dataset created successfully.",
        });
        setLoading(false);
        navigate("/datasets");
      } catch (error) {
        snackbarDispatch({
          type: "SHOW_ERROR_SNACKBAR",
          payload: "Error while creating dataset.",
        });
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handleRemoveFile = () => {
    setValues({ ...values, csvFile: null });
    setFormValue("datasetColumns", []);
    setFormValue("desc", "This dataset contains the following columns:\n");
  };

  const handleOpenDatasetUploadModal = () => {
    setIsUploadDatasetDialogOpen(true);
  };

  const handleUploadDatasetDialogClose = () => {
    setIsUploadDatasetDialogOpen(false);
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const showFileError = errors?.csvFile?.message && isSubmitted;

  if (loading) {
    return <OverlayLoader />;
  }
  if (values === null || (id && user && values.userId !== user.uid)) {
    return <NotFound pageName="dataset" />;
  }

  return (
    <div className="register bg-white h-full">
      <div className="registerContainer px-[40px] py-[32px] flex flex-col gap-[40px] overflow-auto">
        <div className="bg-white py-[40px] 2xl:w-[60%] m-auto lg:w-[70%] md:w-[100%] sm:w-[100%]">
          <div className="flex gap-4 mb-8 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
            <Link
              to="/datasets"
              className="flex gap-2 hover:fill-brand-marine-blue"
            >
              <ArrowBack />
            </Link>
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
              {id ? "Edit Dataset" : "Register New Dataset"}
            </Typography>
          </div>
          <form
            className="px-[50px]"
            onSubmit={handleFormSubmit(handleSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <div className="flex flex-col gap-[40px]">
              <div className="">
                <div className="flex gap-4 flex-wrap">
                  <div className="w-max">
                    <div className="flex relative items-center gap-[4px]">
                      <Button
                        variant={BUTTON_VARIANT.SECONDARY}
                        className={`flex gap-2 items-center h-auto ${
                          showFileError ? "border-warning" : "border-ui-grey-50"
                        }`}
                        buttonClassName="flex items-center gap-2"
                        onClick={handleOpenDatasetUploadModal}
                        type={"button"}
                      >
                        <UploadFileFill />
                        <label className="cursor-pointer flex-col leading-[14px] font-medium">
                          UPLOAD TEST DATASET
                        </label>
                      </Button>
                      <CustomTooltip
                        title={INFO_MESSAGE.DATASET_REGISTER.UPLOAD_BUTTON}
                      >
                        <div>
                          <InfoFill className="relative right-[0] top-[0px] fill-ui-grey-50 hover:fill-brand-marine-blue" />
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                  {!!values?.csvFile?.name && (
                    <div className="flex items-center gap-3">
                      <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>
                        {values.csvFile.name}
                      </Typography>
                      <button onClick={handleRemoveFile}>
                        <CloseFill />
                      </button>
                    </div>
                  )}
                </div>
                {showFileError && (
                  <Typography
                    variant={TYPOGRAPHY_VARIANT.BODY_2}
                    className="text-warning"
                  >
                    {"Dummy Dataset is required."}
                  </Typography>
                )}
              </div>
              <InputTextField
                label={"Dataset Name*"}
                labelType={LABEL_TYPE.OUTLINED}
                placeholder="Enter Dataset Name"
                type="text"
                toolTipTitle={INFO_MESSAGE.DATASET_REGISTER.DATASET_NAME}
                innerRef={register("datasetName").ref}
                {...register("datasetName", {
                  required: "Dataset Name is required.",
                })}
                errorMessage={errors?.datasetName?.message}
                isError={!!errors?.datasetName?.message}
              />
              <InputTextArea
                label={"Description*"}
                labelType={LABEL_TYPE.OUTLINED}
                placeholder="Describe the Dataset Content"
                rows={5}
                type="text"
                toolTipTitle={INFO_MESSAGE.DATASET_REGISTER.DATASET_DESC}
                innerRef={register("desc").ref}
                {...register("desc", {
                  required: "Description is required.",
                })}
                errorMessage={errors?.desc?.message}
                isError={!!errors?.desc?.message}
              />
              {/* <InputTagContainer
                label="Dataset Operator"
                toolTipTitle={INFO_MESSAGE.DATASET_REGISTER.OPERATOR_EMAIL}
                errorMessage={errors?.dataOperetor?.message}
                isError={!!errors?.dataOperetor?.message}
                tags={dbOperatorTags}
                setTags={setDbOperatorTags}
              /> */}

              {!!projectsAssociated && (
                <PopupMessage variant={POPUP_VARIANT.INFO} className="mt-1">
                  You will not be able to update this dataset, as it is already
                  associated with {projectsAssociated} projects.
                </PopupMessage>
              )}

              <div className="flex justify-end gap-[16px]">
                <Button
                  type="button"
                  variant={BUTTON_VARIANT.SECONDARY}
                  onClick={() => navigate("/datasets")}
                >
                  CANCEL
                </Button>
                <Button
                  variant={
                    errors.datasetName || errors.desc
                      ? BUTTON_VARIANT.DISABLED
                      : BUTTON_VARIANT.PRIMARY
                  }
                  type={"submit"}
                  disabled={!!projectsAssociated}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <OverlayLoader />}
      {isUploadDatasetDialogOpen && (
        <UploadDatasetModal
          handleClose={handleUploadDatasetDialogClose}
          heading={"Upload Test Dataset"}
          handleFileChange={handleFileChange}
          fileName={values?.csvFile?.name}
          handleRemoveFile={handleRemoveFile}
        />
      )}
    </div>
  );
};

export default Register;

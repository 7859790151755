export default () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1838 13.0088L16.2052 12.9875H16.175H4.0125V11.0125H16.175H16.2052L16.1838 10.9912L10.5928 5.40008L11.9999 4.0176L19.9823 12L11.9999 19.9824L10.5928 18.5999L16.1838 13.0088Z"
          fill="white"
          stroke="white"
          stroke-width="0.025"
        />
      </svg>
    </>
  );
};

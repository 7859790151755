export default ({ className = "w-full" }) => {
  return (
    <svg
      width="687"
      height="364"
      viewBox="0 0 687 364"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M486.981 171.656L342.825 11.3945L278.932 171.656L413.583 264.064L486.981 171.656Z"
        stroke="#F2F2F2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M427.84 312.117C505.123 312.117 567.772 249.468 567.772 172.186C567.772 94.9035 505.123 32.2539 427.84 32.2539C350.558 32.2539 287.909 94.9035 287.909 172.186C287.909 249.468 350.558 312.117 427.84 312.117ZM428.104 231.855C461.204 231.855 488.037 205.022 488.037 171.922C488.037 138.821 461.204 111.988 428.104 111.988C395.004 111.988 368.171 138.821 368.171 171.922C368.171 205.022 395.004 231.855 428.104 231.855Z"
        fill="url(#paint0_linear_1704_45231)"
      />
      <mask id="path-3-inside-1_1704_45231" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M487.509 311.59C564.791 311.59 627.441 248.94 627.441 171.658C627.441 94.3761 564.791 31.7266 487.509 31.7266C410.227 31.7266 347.577 94.3761 347.577 171.658C347.577 248.94 410.227 311.59 487.509 311.59ZM487.774 231.327C520.874 231.327 547.707 204.494 547.707 171.394C547.707 138.294 520.874 111.461 487.774 111.461C454.673 111.461 427.841 138.294 427.841 171.394C427.841 204.494 454.673 231.327 487.774 231.327Z"
        />
      </mask>
      <path
        d="M626.441 171.658C626.441 248.388 564.239 310.59 487.509 310.59V312.59C565.343 312.59 628.441 249.493 628.441 171.658H626.441ZM487.509 32.7266C564.239 32.7266 626.441 94.9284 626.441 171.658H628.441C628.441 93.8238 565.343 30.7266 487.509 30.7266V32.7266ZM348.577 171.658C348.577 94.9284 410.779 32.7266 487.509 32.7266V30.7266C409.674 30.7266 346.577 93.8238 346.577 171.658H348.577ZM487.509 310.59C410.779 310.59 348.577 248.388 348.577 171.658H346.577C346.577 249.493 409.674 312.59 487.509 312.59V310.59ZM546.707 171.394C546.707 203.942 520.321 230.327 487.774 230.327V232.327C521.426 232.327 548.707 205.047 548.707 171.394H546.707ZM487.774 112.461C520.321 112.461 546.707 138.846 546.707 171.394H548.707C548.707 137.742 521.426 110.461 487.774 110.461V112.461ZM428.841 171.394C428.841 138.846 455.226 112.461 487.774 112.461V110.461C454.121 110.461 426.841 137.742 426.841 171.394H428.841ZM487.774 230.327C455.226 230.327 428.841 203.942 428.841 171.394H426.841C426.841 205.047 454.121 232.327 487.774 232.327V230.327Z"
        fill="white"
        mask="url(#path-3-inside-1_1704_45231)"
      />
      <circle cx="487.509" cy="172.185" r="79.7627" stroke="white" />
      <circle
        cx="487.245"
        cy="171.922"
        r="101.148"
        stroke="white"
        stroke-dasharray="8 8"
      />
      <circle cx="487.773" cy="169.279" r="120.158" stroke="white" />
      <circle
        cx="489.621"
        cy="167.432"
        r="195.932"
        stroke="white"
        stroke-dasharray="8 8"
      />
      <circle
        cx="486.981"
        cy="169.543"
        r="20.5937"
        fill="url(#paint1_linear_1704_45231)"
      />
      <circle
        cx="413.054"
        cy="264.594"
        r="8.97675"
        fill="url(#paint2_linear_1704_45231)"
      />
      <path
        d="M523.678 39.7064L531.037 49.2926L542.428 45.2564L535.585 55.2172L542.943 64.8034L531.356 61.3733L524.512 71.334L524.194 59.2533L512.606 55.8232L523.997 51.7871L523.678 39.7064Z"
        fill="#64ABF8"
      />
      <circle cx="557.749" cy="41.1286" r="7.1286" fill="#64ABF8" />
      <path
        d="M552.255 60.5594L565.404 67.9649L552.416 75.6494L552.255 60.5594Z"
        fill="#64ABF8"
      />
      <path
        d="M101.163 201.153L87.2493 215.688L81.619 196.37L101.163 201.153Z"
        fill="#64ABF8"
      />
      <path
        d="M87.8542 230.444L72.6891 234.469L76.7859 219.323L87.8542 230.444Z"
        fill="#64ABF8"
      />
      <circle cx="104.268" cy="235.032" r="6.33653" fill="#64ABF8" />
      <circle
        cx="341.504"
        cy="8.75467"
        r="25.6101"
        fill="url(#paint3_linear_1704_45231)"
      />
      <g filter="url(#filter0_f_1704_45231)">
        <path d="M555 173L138.75 85.5314V260.469L555 173Z" fill="#A0CCFB" />
      </g>
      <circle
        cx="258.724"
        cy="167.17"
        r="67.8537"
        fill="url(#paint4_linear_1704_45231)"
      />
      <circle
        cx="183.5"
        cy="114.5"
        r="114.5"
        fill="url(#paint5_linear_1704_45231)"
        fill-opacity="0.3"
      />
      <defs>
        <filter
          id="filter0_f_1704_45231"
          x="38.75"
          y="-14.4688"
          width="616.25"
          height="374.938"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="50"
            result="effect1_foregroundBlur_1704_45231"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1704_45231"
          x1="427.84"
          y1="32.2539"
          x2="427.84"
          y2="312.117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3138A8" />
          <stop offset="1" stop-color="#569EF8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1704_45231"
          x1="486.981"
          y1="148.949"
          x2="486.981"
          y2="190.137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E8E8" />
          <stop offset="1" stop-color="#D6D6D6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1704_45231"
          x1="413.054"
          y1="255.617"
          x2="413.054"
          y2="273.571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E8E8" />
          <stop offset="1" stop-color="#D6D6D6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1704_45231"
          x1="341.504"
          y1="-16.8555"
          x2="341.504"
          y2="34.3648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A0CCFB" />
          <stop offset="1" stop-color="#569EF8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1704_45231"
          x1="258.724"
          y1="99.3164"
          x2="258.724"
          y2="235.024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8167B7" />
          <stop offset="1" stop-color="#323582" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1704_45231"
          x1="183.5"
          y1="0"
          x2="183.5"
          y2="229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8167B7" />
          <stop offset="1" stop-color="#323582" />
        </linearGradient>
      </defs>
    </svg>
  );
};

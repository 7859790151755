export default ({ className = "", id = "", fill, width, height }) => {
  return (
    <svg
      className={className}
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 40}
      height={height ?? 35}
      viewBox="0 0 40 35"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6519 0H9.93569C9.94043 0 9.93766 0.684056 9.93569 1.02608H18.048C18.0569 1.02608 18.5759 1.93816 18.8343 2.3942H8.5507V3.4293H19.4391L20.2253 4.8112H7.16416L7.15979 5.83728H20.8255L21.6162 7.20539H5.77568V8.23478H22.209L23.0071 9.59959H4.39251L0 17.1698L0.889273 17.6715L4.97081 10.6257H6.54414L1.39091 19.564L2.28019 20.0885L7.72984 10.6257H9.32597L2.78183 21.981L3.6711 22.4826L10.5117 10.6257H12.085L4.14994 24.3752L5.03921 24.8768L13.2707 10.6257H14.8599L5.54086 26.7694L9.91882 34.3396L10.8081 33.8152L6.72655 26.7694L7.52462 25.4013L12.6778 34.3396L13.5671 33.8152L8.11747 24.3752L8.91553 23.0071L15.4597 34.3396L16.3489 33.8152L9.50838 21.9582L10.3064 20.5901L18.2415 34.3396L19.1308 33.8152L10.8993 19.564L11.6746 18.1959L21.0005 34.3396H29.7336V33.3135H21.5934L20.8181 31.9454L31.1246 31.9226V30.8965H20.2025L19.4272 29.5284H32.5155V28.5023H18.823L18.0363 27.1342H33.8992V26.1081H17.4434L16.6454 24.74H35.2745L39.6525 17.1698L38.7632 16.6454L34.6817 23.7139H33.1083L38.2615 14.7756L37.3723 14.2512L31.9226 23.7139H30.3265L36.8706 12.3586L35.9814 11.857L29.1408 23.7139H27.5675L35.4797 9.96442L34.5904 9.46278L26.359 23.7139H24.7856L34.1116 7.57022L29.7336 0L28.8444 0.524443L32.9259 7.57022L32.1278 8.93833L26.9518 0L26.0625 0.524443L31.535 9.96442L30.7369 11.3325L24.1928 0L23.3035 0.524443L30.1441 12.3586L29.346 13.7267L21.411 0L20.5217 0.524443L28.7532 14.7756L27.9551 16.1437L18.6519 0ZM16.0522 10.6255H23.6028L27.3619 17.1696L23.5996 23.6909H16.0522L12.2899 17.1696L16.0522 10.6255Z"
        fill="white"
      />
    </svg>
  );
};

const algorithm = [
  {
    label: "",
    value: "",
    disabled: true,
  },
  {
    label: "Logistic Regression",
    value: "Logistic Regression",
  },
  {
    label: "Linear Regression",
    value: "Linear Regression",
  },
  {
    label: "Isolation Forest",
    value: "Isolation Forest",
  },
  // { label: "Random Forest", value: "Random Forest" },
  // {
  //   label: "Neural Networks",
  //   value: "Neural Networks",
  // },
  // { label: "Auto-Encoders", value: "Auto-Encoders" },
  // {
  //   label: "Other-Custom Model",
  //   value: "Other-Custom Model",
  // },
];

const task = [
  { label: "", value: "", disabled: true },
  { label: "Classification", value: "Classification" },
  { label: "Regression", value: "Regression" },
  // {
  //   label: "Anomaly Detection",
  //   value: "Anomaly Detection",
  // },
];

const dummy = [
  {
    label: "",
    value: "",
    disabled: true,
  },
  { label: "Option 1", value: "Option 1" },
  { label: "Option 2", value: "Option 2" },
];

const penalty = [
  {
    label: "",
    value: "",
    disabled: true,
  },
  { label: "l1", value: "l1" },
  { label: "l2", value: "l2" },
  { label: "elasticnet", value: "elasticnet" },
  { label: "none", value: "none" },
];
const solver = [
  { label: "", value: "", disabled: true },
  { label: "lbfgs", value: "lbfgs" },
  { label: "liblinear", value: "liblinear" },
  { label: "newton-cg", value: "newton-cg" },
  { label: "newton-cholesky", value: "newton-cholesky" },
  { label: "sag", value: "sag" },
  { label: "saga", value: "saga" },
];
const activation = [
  { label: "", value: "", disabled: true },
  { label: "e.g", value: "e.g" },
  { label: "relu", value: "relu" },
  { label: "sigmoid", value: "sigmoid" },
];

const Solver = [
  {
    label: "",
    value: "",
    disabled: true,
  },

  { label: "lbfgs", value: "lbfgs" },
  { label: "sgd", value: "sgd" },
  { label: "adam", value: "adam" },
];
const learning_rate = [
  {
    label: "",
    value: "",
    disabled: true,
  },
  { label: "constant", value: "constant" },
  { label: "invscaling", value: "invscaling" },
  { label: "adaptive", value: "adaptive" },
];

const bootstrap = [
  { label: "False", value: "False" },
  { label: "True", value: "True" },
];
const multi_class = [
  { label: "auto", value: "auto" },
  { label: "ovr", value: "ovr" },
  { label: "multinomial", value: "multinomial" },
];
const loss = [
  { label: "e.g", value: "e.g" },
  { label: "binary_crossentropy", value: "binary_crossentropy" },
  { label: "mean_squared_error", value: "mean_squared_error" },
];

const constants = {
  algorithmOptions: algorithm,
  taskOptions: task,
  dummyOptions: dummy,
  penaltyOptions: penalty,
  solverOptions: solver,
  activationOptions: activation,
  SolverOptions: Solver,
  learning_rateOptions: learning_rate,
  bootstrapOptions: bootstrap,
  multiOptions: multi_class,
  lossOptions: loss,
};

export default constants;

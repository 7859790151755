import React from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Close from "../svg/Close";
import MailIcon from "../svg/MailIcon";

const CollaboratorInviteAction = ({
  handleClose = () => { },
  handleAccept = () => { },
  handleDeny = () => { },
  body = "",
}) => {
  return (
    <div
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="overflow-y-auto fixed top-0 right-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#F5F5F5F5]/sm backdrop-blur-[10px] "
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex items-center justify-center h-full">
        <div className="flex flex-col rounded-lg gap-[26px] items-center justify-between p-7 md:p-5 border-b rounded-t dark:border-gray-600 bg-white drop-shadow-md w-[500px]">
          <div className="text-center flex flex-col gap-[24px] relative">
            <div>
              <button
                className="ml-auto absolute right-[0]"
                onClick={handleClose}
              >
                <Close />
              </button>
            </div>
            {/* <div className="text-center">
              <span className="flex items-center justify-center bg-[#a0ccfb66] rounded-full p-[8px] m-auto w-[40px] min-w-[40px] h-[40px]">
                <MailIcon />
              </span>
            </div> */}
            {/* <Typography variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}>
              Collaborator Invitation
            </Typography> */}
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
              {body}
            </Typography>
            <button
              onClick={handleAccept}
              className="px-10 py-3 bg-[#3138A8] text-white rounded-full w-full"
            >
              ACCEPT REQUEST
            </button>
            <button
              onClick={handleDeny}
              className="px-10 py-2 bg-[#D2333A] text-white rounded-full w-full"
            >
              Deny
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorInviteAction;

import React from "react";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import Datasets from "../pages/datasets/Datasets";
import ViewProject from "../pages/projects/ViewProject";
import RegisterProject from "../pages/projects/RegisterProject";
import Project from "../pages/projects/Projects";
import Register from "../pages/datasets/Register";
import Signup from "../pages/login/Register";
import ViewDataset from "../pages/datasets/ViewDataset";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Layout from "../layout/Layout";
import Profile from "../pages/profile/Profile";
import ApiHub from "../pages/apiHub/ApiHub.jsx";
import ViewApi from "../pages/apiHub/components/ViewApi.jsx";
import ModelHub from "../pages/modelHub/ModelHub.jsx";
import ModelSummary from "../pages/modelHub/ModelSummary.jsx";
import NotFound from "../pages/404notfound/NotFound.jsx";

const Router = () => {
  const { currentUser } = useContext(AuthContext);
  const showModelHub = currentUser?.userDetails?.showModelHub;

  const RequireAuth = ({ children }) => {
    return currentUser ? <Layout>{children}</Layout> : <Navigate to="/login" />;
  };
  const UnauthRoute = ({ children }) => {
    return currentUser ? <Navigate to="/" /> : children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="login"
          element={
            <UnauthRoute>
              <Login />
            </UnauthRoute>
          }
        />
        <Route
          path="register"
          element={
            <UnauthRoute>
              <Signup />
            </UnauthRoute>
          }
        />
        <Route path="profile">
          <Route
            index
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="datasets">
          <Route
            index
            element={
              <RequireAuth>
                <Datasets user={currentUser} />
              </RequireAuth>
            }
          />
          <Route
            path=":id"
            element={
              <RequireAuth>
                <ViewDataset />
              </RequireAuth>
            }
          />
          <Route path="register">
            <Route
              index
              element={
                <RequireAuth>
                  <Register user={currentUser} />
                </RequireAuth>
              }
            />
            <Route
              path=":id"
              element={
                <RequireAuth>
                  <Register user={currentUser} />
                </RequireAuth>
              }
            />
          </Route>
        </Route>
        <Route path="projects">
          <Route
            index
            element={
              <RequireAuth>
                <Project user={currentUser} />
              </RequireAuth>
            }
          />
          <Route
            path=":id"
            element={
              <RequireAuth>
                <ViewProject user={currentUser} />
              </RequireAuth>
            }
          />
          <Route
            path="new"
            element={
              <RequireAuth>
                <RegisterProject user={currentUser} />
              </RequireAuth>
            }
          >
            <Route
              path=":id"
              element={
                <RequireAuth>
                  <RegisterProject user={currentUser} />
                </RequireAuth>
              }
            />
          </Route>
        </Route>
        <Route path="apis">
          <Route
            index
            element={
              <RequireAuth>
                <ApiHub />
              </RequireAuth>
            }
          />
          <Route
            path=":id"
            element={
              <RequireAuth>
                <ViewApi />
              </RequireAuth>
            }
          />
        </Route>

        {showModelHub && (
          <Route path="models">
            <Route
              index
              element={
                <RequireAuth>
                  <ModelHub user={currentUser} />
                </RequireAuth>
              }
            />
            <Route
              path=":id"
              element={
                <RequireAuth>
                  <ModelSummary />
                </RequireAuth>
              }
            />
          </Route>
        )}
        <Route
          path="*"
          element={
            <RequireAuth>
              <NotFound />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import { useEffect } from "react";
import "./widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ArrowDownward from "../svg/ArrowDownward";
import ArrowUpward from "../svg/ArrowUpward";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";

const Widget = ({ type, value, diff }) => {
  let data;

  switch (type) {
    case "user":
      data = {
        title: "Total Events Scored",
        isMoney: false,
        link: "See all transactions",
        query: "users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "Total Fraud Events Detected",
        isMoney: false,
        link: "View all events",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "Data Monetization Revenue",
        isMoney: true,
        link: "View net earnings",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "product":
      data = {
        title: "PRODUCTS",
        query: "products",
        link: "See details",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      const today = new Date();
      const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
      const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2));

      // const lastMonthQuery = query(
      //   collection(db, data.query),
      //   where("timeStamp", "<=", today),
      //   where("timeStamp", ">", lastMonth)
      // );
      // const prevMonthQuery = query(
      //   collection(db, data.query),
      //   where("timeStamp", "<=", lastMonth),
      //   where("timeStamp", ">", prevMonth)
      // );

      // const lastMonthData = await getDocs(lastMonthQuery);
      // const prevMonthData = await getDocs(prevMonthQuery);

      // setAmount(lastMonthData.docs.length);
      // setDiff(
      //   ((lastMonthData.docs.length - prevMonthData.docs.length) /
      //     prevMonthData.docs.length) *
      //     100
      // );
    };
    fetchData();
  }, []);

  const diffIcon =
    diff == 0 ? (
      <ArrowUpward fill="#646464" />
    ) : diff < 0 ? (
      <ArrowDownward />
    ) : (
      <ArrowUpward />
    );

  return (
    <div className={`widget flex-col bg-gradient-to-t from-[#FFFFFF] bg-white`}>
      <div className="left">
        <span className="title">
          <Typography
            variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}
            className="text-[#646464] font-bold"
          >
            {data.title}
          </Typography>
        </span>
        <span className="flex gap-2 mt-2">
          <Typography
            variant={TYPOGRAPHY_VARIANT.HEADING_2}
            className="flex-none"
          >
            {value}
          </Typography>
          {data.title === "Data Monetization Revenue" && (
            <span className="text-[#999999]">
              <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                per month
              </Typography>
            </span>
          )}
        </span>
        {/* <span className="link">{data.link}</span> */}
      </div>
      <div className="right mt-2">
        <div className="flex gap-4">
          <span
            className={`percentage ${
              diff < 0 ? "negative font-bold gap-1" : "positive font-bold gap-1"
            }`}
          >
            {diffIcon}
            {diff} %
          </span>
          <span className="text-[#999999] font-bold">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
              than yesterday
            </Typography>
          </span>
        </div>
        {/* {data.icon} */}
      </div>
    </div>
  );
};

export default Widget;

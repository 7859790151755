import React, { useEffect, useState } from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import InputWarningIcon from "../svg/InputWarningIcon";
import InputSuccessIcon from "../svg/InputSuccessIcon";
import CustomTooltip from "./CustomTooltip/CustomTooltip";
import InfoFill from "../svg/InfoFill";
import { Controller } from "react-hook-form";
import Select from "react-select";

export const LABEL_TYPE = {
  OUTLINED: "OUTLINED",
  STANDARD: "STANDARD",
};

const SelectField = ({
  name,
  innerRef,
  className = "",
  label = "",
  endAdornment,
  defaultValue,
  isError = false,
  isSuccess = false,
  errorMessage = "",
  options = [],
  labelType = LABEL_TYPE.STANDARD,
  toolTipTitle = null,
  multiple = false,
  control,
  infoIconOutsideContainer,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");
  useEffect(() => {
    setSelectedValue(defaultValue || "");
  }, [defaultValue]);

  const customStyles = {
    control: (styles, { isFocused, isSelected }) => ({
      ...styles,
      boxShadow: "none",
      borderRadius: "8px",
      padding: "7px",
      borderColor: isFocused ? "#323582" : "#000000",
      "&:hover": {
        borderColor: null,
      },
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),

    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#F2F2F2",
      borderRadius: "25px",
      padding: "3px",
      // color: '#333',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "rgb(148 163 184)",
        color: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#F2F2F2" : null,
        color: "#333333",
        "&:active": {
          backgroundColor: "#F2F2F2",
        },
      };
    },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      transition: "transform ease-in-out",
    }),
  };
  return (
    <div
      className="relative w-full flex flex-col items-start justify-start gap-[8px] z-[1]"
      style={infoIconOutsideContainer ? { width: "calc(100% + 32px)" } : {}}
    >
      {labelType === LABEL_TYPE.STANDARD && (
        <div className="ml-[-4px] h-[22px] flex flex-row items-start justify-start pt-0 px-1 pb-0 box-border">
          <Typography variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}>
            {label}
          </Typography>
        </div>
      )}
      {labelType === LABEL_TYPE.OUTLINED && (
        <div className="absolute top-[-12px] bg-white ml-2 z-[2]">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>{label}</Typography>
        </div>
      )}
      <div className="w-full relative flex items-start">
        <Controller
          control={control}
          name={name}
          defaultValue={selectedValue}
          render={({ field }) => (
            <Select
              {...field}
              error={isError}
              helperText={errorMessage}
              options={options}
              styles={customStyles}
              isMulti={multiple}
              value={
                multiple
                  ? options.filter((option) =>
                      selectedValue.includes(option.value)
                    )
                  : options.find((option) => option.value === selectedValue)
              }
              onChange={(selectedOption) => {
                const value = multiple
                  ? selectedOption.map((option) => option.value)
                  : selectedOption.value;
                field.onChange(value);
                setSelectedValue(value);
              }}
              className="w-full"
              menuPlacement="auto"
              menuPosition="fixed"
              menuPortalTarget={document.body}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "100%",
                  },
                },
              }}
              isClearable={false}
            />
          )}
        />
        <span
          className={`absolute top-[15px] flex ${
            toolTipTitle ? "right-[62px]" : "right-[16px]"
          }`}
        >
          {endAdornment && <span className="">{endAdornment}</span>}
          {isError && <span className="">{<InputWarningIcon />}</span>}
          {isSuccess && <span className="">{<InputSuccessIcon />}</span>}
        </span>
        {toolTipTitle && (
          <CustomTooltip title={toolTipTitle}>
            <span>
              <InfoFill className="relative ml-[4px] right-[0] top-[16px] fill-ui-grey-50 hover:fill-brand-marine-blue" />
            </span>
          </CustomTooltip>
        )}
      </div>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_2} className="text-warning">
        {errorMessage}
      </Typography>
    </div>
  );
};

export default SelectField;

import React from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import InputWarningIcon from "../svg/InputWarningIcon";
import InputSuccessIcon from "../svg/InputSuccessIcon";
import InfoFill from "../svg/InfoFill";
import CustomTooltip from "./CustomTooltip/CustomTooltip";

export const LABEL_TYPE = {
  OUTLINED: "OUTLINED",
  STANDARD: "STANDARD",
};

export const INPUT_VARIENT = {
  GENERAL: "GENERAL",
  SHADED: "SHADED",
};


const InputTextArea = ({
  type = "text",
  className = "",
  label = "",
  endAdornment,
  startAdornment,
  passWordEyeIcon,
  isError = false,
  isSuccess = false,
  errorMessage = "",
  innerRef,
  labelType = LABEL_TYPE.STANDARD,
  variant = INPUT_VARIENT.GENERAL,
  toolTipTitle = null,
  ...props
}) => {
  const borderColor = () => {
    if (isError) return "border-warning";
    else if (isSuccess) return "border-success";
    else return variant === "GENERAL"
      ? "border-ui-dark-grey"
      : "border-transparent";
  };
  const inputClass = () => {
    if (variant === "GENERAL") return "w-full rounded-md py-3 pl-3 pe-10 rounded-md";
    else if (variant === "SHADED")
      return "bg-[#ECF5FE]  pt-[32px] px-[24px] pb-[70px] rounded-[20px]";
  };
  return (
    <div className="relative w-full flex flex-col items-start justify-start gap-[8px] z-[1]">
      {labelType === LABEL_TYPE.STANDARD && (
        <div className="ml-[-4px] h-[22px] flex flex-row items-start justify-start pt-0 px-1 pb-0 box-border">
          <Typography variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}>
            {label}
          </Typography>
        </div>
      )}
      {labelType === LABEL_TYPE.OUTLINED && (
        <div
          className={`absolute ml-2 z-[2] ${variant === "GENERAL" ? "top-[-12px] bg-white" : "top-[12px] pl-[18px] text-ui-dark-grey"
            }`}
        >
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>{label}</Typography>
        </div>
      )}
      <div className="w-full relative flex items-start">
        <textarea
          className={`w-full border-solid border-[1px] focus:border-[#323582] focus:border-[1.5px] hover:border-[1.5px] hover:border-[#000000] ${borderColor()} ${inputClass()}`}
          type={type}
          ref={innerRef}
          {...props}
        />
        <span
          className={`absolute top-[15px] flex ${toolTipTitle ? "right-[56px]" : "right-[8px]"
            }`}
        >
          {passWordEyeIcon && <span className="">{passWordEyeIcon}</span>}
          {endAdornment && <span className="">{endAdornment}</span>}
          {isError && <span className="">{<InputWarningIcon />}</span>}
          {isSuccess && <span className="">{<InputSuccessIcon />}</span>}
        </span>
        {toolTipTitle && (
          <CustomTooltip title={toolTipTitle}>
            <div>
              <InfoFill className="relative ml-[4px] right-[0] top-[16px] fill-ui-grey-50 hover:fill-brand-marine-blue" />
            </div>
          </CustomTooltip>
        )}
      </div>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_2} className="text-warning">
        {errorMessage}
      </Typography>
    </div>
  );
};

export default InputTextArea;

import React, { useContext, useEffect } from "react";
import { useSessionTimeOut } from "../hooks/sessionTimeOut";
import Sidebar from "../components/sidebar/Sidebar";
import { auth } from "../firebase";
import { AuthContext } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import Snackbar from "@mui/joy/Snackbar";
import { SnackbarContext } from "../context/SnackbarContext";
import Close from "../components/svg/Close";

const Layout = ({ children }) => {
  useSessionTimeOut();

  const { currentUser, dispatch } = useContext(AuthContext);
  const { state: snackbarState, dispatch: snackbarDispatch } =
    useContext(SnackbarContext);
  console.log("snackbarState", snackbarState);

  // To check token when app is closed and again opened if token is present or expired
  useEffect(() => {
    console.log('to logout, auth: ', auth, 'currentUser: ', currentUser)
    if (currentUser?.stsTokenManager.expirationTime < new Date() || !currentUser.auth.currentUser) {
      console.log("Logout due to token expiration, currentUser: ", currentUser);
      dispatch({ type: "LOGOUT" });
      signOut(auth);
    }
  });

  // To update the token in every hour aotomatically according to firebase
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(
      (user) => {
        if (
          user &&
          currentUser &&
          currentUser?.userDetails &&
          // user?.currentUser &&
          user?.stsTokenManager?.accessToken !==
            currentUser?.stsTokenManager?.accessToken
        ) {
          console.log(
            "in onIdTokenChanged ",
            "user",
            user,
            "currentUser",
            currentUser
          );
          dispatch({
            type: "UPDATE",
            payload: { ...user, userDetails: currentUser?.userDetails },
          });
        }
      },
      (e) => {
        console.log("Error on onIdTokenChanged: ", e);
      },
      (e) => {
        console.log("Completed onIdTokenChanged: ", e);
      }
    );

    // Clean up the listener
    return () => unsubscribe();
  }, []);

  const handleCloseSnackbar = (index) => {
    snackbarDispatch({ type: "HIDE_SNACKBAR", payload: index && 0 });
  };

  return (
    <div className="flex w-full">
      <Sidebar />
      <div className="flex-[6] h-[100vh] overflow-auto bg-[#f8f8f8] 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
        {children}
      </div>
      {snackbarState.map((snackbarValue, index) => {
        return (
          <Snackbar
            autoHideDuration={10000}
            color={snackbarValue.color}
            size="md"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbarValue.show}
            onClose={handleCloseSnackbar}
            style={{
              marginTop: `${index * 60}px`,
            }}
            variant="soft"
          >
            <span
              className="hover: cursor-pointer"
              onClick={() => handleCloseSnackbar(index)}
            >
              <Close />
            </span>{" "}
            {snackbarValue.snackbarContent}
          </Snackbar>
        );
      })}
    </div>
  );
};

export default Layout;

export default ({
  className = "",
  id = "",
  fill = "#309E63",
  width,
  height,
}) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="28" height="28" rx="14" fill={fill} fill-opacity="0.2" />
      <path
        d="M13 22V9.825L7.4 15.425L6 14L14 6L22 14L20.6 15.425L15 9.825V22H13Z"
        fill={fill}
      />
    </svg>
  );
};

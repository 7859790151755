import ClockIcon from "../../../components/svg/ClockIcon.js";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

const ModelCard = (props) => {
  const { api, isRowLayout } = props;

  const formatDate = (date) => {
    const now = new Date();
    const diff = now - date;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (hours <= 24) {
      return `Updated ${hours} hours ago`;
    } else if (hours > 24) {
      return `Updated ${days} days ago`;
    } else {
      return date.toDateString();
    }
  };

  return (
    <>
      {isRowLayout ? (
        <>
          <Card
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "auto",
              borderRadius: "25px",
              borderColor: "#999999",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  justifyContent: "center",
                  marginTop: "1%",
                }}
              >
                <Typography variant="h4"></Typography>
              </Box>
              <Typography gutterBottom variant="h4" color="#2C3850">
                {api.name}
              </Typography>
              <Typography variant="body1" color="#646464" marginBottom="5px">
                {api.type}
              </Typography>
            </CardContent>
            <Box sx={{ flexGrow: 1, height: "5px" }} />
            <Divider />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={10}
              sx={{ p: 2 }}
            >
              <Stack alignItems="center" direction="row" spacing={1}>
                <SvgIcon
                  style={{ color: "#999999", marginLeft: "20px" }}
                  fontSize="large"
                >
                  <ClockIcon />
                </SvgIcon>
                <Typography color="#999999" display="inline" variant="body2">
                  {formatDate(new Date(api.lastModifiedDate))}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </>
      ) : (
        <>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "auto",
              borderRadius: "12%",
              px: "0",
              borderColor: "#999999",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  justifyContent: "center",
                  marginTop: "10%",
                  py: "15px",
                }}
              >
                <Typography variant="h4"></Typography>
              </Box>
              <Typography
                align="center"
                gutterBottom
                variant="h4"
                color="#2C3850"
              >
                {api.name}
              </Typography>
              <Typography
                align="center"
                variant="body1"
                color="#646464"
                marginBottom="30px"
              >
                {api.type}
              </Typography>
            </CardContent>
            <Box sx={{ flexGrow: 1, height: "10px" }} />
            <Divider />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={10}
              sx={{ p: 2 }}
            >
              <Stack alignItems="center" direction="row" spacing={1}>
                <SvgIcon
                  style={{ color: "#999999", marginLeft: "20px" }}
                  fontSize="large"
                >
                  <ClockIcon />
                </SvgIcon>
                <Typography color="#999999" display="inline" variant="body2">
                  {formatDate(new Date(api.lastModifiedDate))}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </>
      )}
    </>
  );
};

export default ModelCard;

import React from "react";
import InputWarningIcon from "../svg/InputWarningIcon";
import InputSuccessIcon from "../svg/InputSuccessIcon";

export const POPUP_VARIANT = {
    SUCCESS: "success",
    MESSAGE: "message",
    WARNING: "warning",
    INFO: "info",
}
export default ({
  className = "",
  variant="success",
  message = "",
  options = [],
  children,
  ...props
}) => {
  const borderColor = () => {
    if (variant==='warning') return "border-warning";
    else if (variant==='success') return "border-success";
    else if (variant==='message') return "border-message";
    else if (variant==='info') return "border-info";
    else return "border-ui-dark-grey";
  };

  const bgColor = () => {
    if (variant==='warning') return "bg-warning-light";
    else if (variant==='success') return "bg-success-light";
    else if (variant==='message') return "bg-message-light";
    else if (variant==='info') return "bg-info-light";
    else return "bg-transparent";
  };
  const popupIcon = () => {
    if (variant==='warning') return <InputWarningIcon/>;
    else if (variant==='success') return <InputSuccessIcon/>;
    else return <></>;
  };
  if(!Object.values(POPUP_VARIANT).includes(variant)){
    console.error(
        "You need to access Popup variant from POPUP_VARIANT"
      );
    return ''
  }
  return (
    <div className={`rounded-lg ${bgColor()} flex flex-row items-center justify-start py-8 px-5 gap-[16px] border-[2px] border-solid ${borderColor()} ${className}`}>
        <div className="flex flex-row items-center justify-center">
          {popupIcon()}
        </div>
        <div className="relative leading-[16px]">
          <p className="m-0">{children}</p>
        </div>
        {/* <div className="w-[68px] h-6 flex flex-row items-center justify-end">
          <img
            className="w-6 relative h-6 hidden"
            alt=""
            src="/buttonclosesmallvariant3.svg"
          />
        </div> */}
      </div>
  );
};

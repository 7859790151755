import { useContext, useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import OverlayLoader from "../../components/common/OverlayLoader";
import ArrowBack from "../../components/svg/ArrowBack";
import { Link, useParams } from "react-router-dom";
import api, { API_CONFIG, API_REQUEST_TYPE } from "../../api/api";
import { Box } from "@mui/material";
import NotFound from "../404notfound/NotFound";
import { SnackbarContext } from "../../context/SnackbarContext";

const ModelSummary = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const fetchDataFromAPI = async () => {
    setIsLoading(true);
    try {
      const response = await api(
        API_REQUEST_TYPE.GET,
        API_CONFIG.END_POINTS.MODEL.GET_MODAL_SUMMARY(id)
      ).then((res) => res.json());
      console.log("response", response);

      if (response?.data) {
        // Add an id property to each row
        const dataWithId = response.data.map((row, index) => ({
          id: index + 1, // Use index + 1 as a unique id
          ...row,
        }));
        setData(dataWithId);
        setColumns(
          response.columns.map((col) => ({
            field: col,
            headerName: col,
            flex: 1,
            minWidth: 150,
          }))
        );
      } else if (response?.detail === "Model not found") {
        setData(null);
      }
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while fetching model.",
      });
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);

  const CustomToolbar = () => {
    const primaryButtonsCss = {
      lineHeight: "24px",
      borderRadius: "32px",
      position: "relative",
      background: "#3138a8",
      padding: "10px 20px",
      color: "white",
      marginBottom: "20px",
      border: "2px solid #3138a8",
      "&:hover": {
        background: "#323582",
      },
    };
    const secondaryButtonsCss = {
      lineHeight: "24px",
      borderRadius: "32px",
      position: "relative",
      background: "white",
      padding: "10px 20px",
      color: "black",
      marginBottom: "20px",
      border: "2px solid #3138a8",
      "&:hover": {
        background: "#ddd",
      },
    };
    return (
      <GridToolbarContainer
        sx={{
          background: "#f8f8f8",
          gap: "10px",
        }}
      >
        <GridToolbarColumnsButton
          title="Columns Visibility"
          sx={secondaryButtonsCss}
        />
        <GridToolbarFilterButton title="Data Filter" sx={secondaryButtonsCss} />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          sx={primaryButtonsCss}
          title="Export data"
          allColumns={true}
          allRows={true}
        />
      </GridToolbarContainer>
    );
  };

  if (data === null) {
    return <NotFound pageName="model" />;
  }

  return (
    <div className="p-[32px]">
      <div className="flex items-center gap-5">
        <Link to="/models" className="flex gap-2 hover:fill-brand-marine-blue">
          <ArrowBack />
        </Link>

        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
          {"CDR Output Summary"}
        </Typography>
      </div>

      <div className="w-full h-full shadow-lg bg-white mt-4">
        <DataGrid
          initialState={{
            columns: { columnVisibilityModel: { A_Number: false } },
          }}
          className="table-datagrid"
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
          components={{
            Toolbar: CustomToolbar,
          }}
          sx={{
            border: 0,
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
            "& .MuiDataGrid-menuIconButton": {
              opacity: 1,
              color: "white",
            },
          }}
        />
      </div>
      {isLoading && <OverlayLoader />}
    </div>
  );
};

export default ModelSummary;

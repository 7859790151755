export default ({ fill }) => {
  return (
    <>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 20C7.45 20 6.97917 19.8042 6.5875 19.4125C6.19583 19.0208 6 18.55 6 18H10C10 18.55 9.80417 19.0208 9.4125 19.4125C9.02083 19.8042 8.55 20 8 20ZM4 17V15H12V17H4ZM4.25 14C3.1 13.3167 2.1875 12.4 1.5125 11.25C0.8375 10.1 0.5 8.85 0.5 7.5C0.5 5.41667 1.22917 3.64583 2.6875 2.1875C4.14583 0.729167 5.91667 0 8 0C10.0833 0 11.8542 0.729167 13.3125 2.1875C14.7708 3.64583 15.5 5.41667 15.5 7.5C15.5 8.85 15.1625 10.1 14.4875 11.25C13.8125 12.4 12.9 13.3167 11.75 14H4.25ZM4.85 12H11.15C11.9 11.4667 12.4792 10.8083 12.8875 10.025C13.2958 9.24167 13.5 8.4 13.5 7.5C13.5 5.96667 12.9667 4.66667 11.9 3.6C10.8333 2.53333 9.53333 2 8 2C6.46667 2 5.16667 2.53333 4.1 3.6C3.03333 4.66667 2.5 5.96667 2.5 7.5C2.5 8.4 2.70417 9.24167 3.1125 10.025C3.52083 10.8083 4.1 11.4667 4.85 12Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ({ className = "", id = "", fill, width, height }) => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      className={className}
      id={id}
    >
      <path
        d="M6 8.37656L0 2.37656L1.4 0.976562L6 5.57656L10.6 0.976562L12 2.37656L6 8.37656Z"
        fill="#323582"
      />
    </svg>
  );
};

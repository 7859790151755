export default () => {
  return (
    <>
      <svg
        width="40"
        height="2"
        viewBox="0 0 14 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 2V0H14V2H0Z" fill="#2C2C2C" />
      </svg>
    </>
  );
};

import { createContext, useReducer } from "react";
import SnakbarReducer from "./SnackbarReducer";

const INITIAL_STATE = [];

export const SnackbarContext = createContext(INITIAL_STATE);

export const SnackbarContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SnakbarReducer, INITIAL_STATE);

  return (
    <SnackbarContext.Provider value={{ state, dispatch }}>
      {children}
    </SnackbarContext.Provider>
  );
};

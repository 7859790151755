import React, { useEffect, useState } from "react";
import CustomTooltip from "../../../components/common/CustomTooltip/CustomTooltip";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../components/common/Typography";
import CopyIcon from "../../../components/svg/CopyIcon";
import { copyClientCode } from "../../../utils/helper";
import ArrowBack from "../../../components/svg/ArrowBack";
import { Link, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import OverlayLoader from "../../../components/common/OverlayLoader";

const ViewApi = () => {
  const [tooltipText, setTooltipText] = useState("Copy Text");
  const [apis, setApis] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const datasetRef = doc(db, "api_hub", id);
        const datasetSnapshot = await getDoc(datasetRef);
        if (datasetSnapshot.exists()) {
          const datasetData = datasetSnapshot.data();
          setApis(datasetData);
          setIsLoading(false);
        } else {
          console.error("APIs not found!");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching APIs:", error);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <div className="mt-[50px] 2xl:pr-[36%] xl:ps-[0] lg:pr-[36%] md:w-[100%] ps-[90px]">
      {""}
      <div className="flex items-center gap-5">
        <Link to="/apis" className="flex gap-2 hover:fill-brand-marine-blue">
          <ArrowBack />
        </Link>
        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
          {apis.type}
        </Typography>
      </div>
      <div className="mt-[25px] px-[20px] py-[15px] bg-white">
        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
          Description{" "}
        </Typography>
        <div className="mt-[10px]">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {apis.description}
          </Typography>
        </div>
      </div>
      <div className="mt-[20px] py-[20px] pl-[20px] bg-white">
        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
          Parameters
        </Typography>
        <div className="mt-[7px]">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {apis.parameters && Object.keys(apis.parameters).length > 0
              ? Object.keys(apis.parameters).map((parameterKey) => (
                  <p
                    className="mt-[20px]"
                    key={parameterKey}
                  >{`${parameterKey}: ${apis.parameters[parameterKey]}`}</p>
                ))
              : null}
          </Typography>
        </div>
      </div>
      <div className="px-[20px] py-[15px] bg-white mt-[20px]">
        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
          Code example
        </Typography>
        <div className="py-[20px] px-[10px]  mt-[25px] text-white border-2 bg-[#1E1E4C] rounded-[10px]">
          <div className="border-b text-end">
            <CustomTooltip title={tooltipText}>
              <span
                className="inline-block mb-[10px]"
                onClick={() => {
                  copyClientCode("client-code");
                  setTooltipText("Text Copied...");
                }}
                onMouseLeave={() =>
                  setTimeout(() => setTooltipText("Copy Text"), 1000)
                }
              >
                <CopyIcon />
              </span>
            </CustomTooltip>
          </div>
          <div id="client-code">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              {apis.code}
            </Typography>
          </div>
        </div>
      </div>
      {isLoading && <OverlayLoader />}
    </div>
  );
};

export default ViewApi;

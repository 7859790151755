import React, { useState } from "react";
import "./login.scss";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { ERROR_MAPPER } from "../../utils/const";
import LogoWhite from "../../components/svg/LogoWhite";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import InputTextField from "../../components/common/InputTextField";
import HidePasswordIcon from "../../components/svg/hidePasswordIcon";
import ShowPasswordIcon from "../../components/svg/showPasswordIcon";
import PopupMessage, {
  POPUP_VARIANT,
} from "../../components/common/PopupMessage";
import PopupModal from "../../components/modals/EmailVarificationModal";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import OverlayLoader from "../../components/common/OverlayLoader";

const Register = () => {
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [showVerificationAlert, setShowVerificationAlert] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data) => {
    setLoading(true);
    const companyName = data.name;
    const email = data.email;
    const password = data.password;
    const userName = email.split("@")[0];
    // const role = data.role;
    try {
      setErr(null);
      setShowVerificationAlert(false);
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);
      console.log("response", res);

      //Update profile
      await updateProfile(res.user, {
        displayName: userName,
      });

      //Send verification email
      await sendEmailVerification(res.user, {
        url: process.env.REACT_APP_REDIRECT_URL,
        handleCodeInApp: true,
      });
      //Set remaining data in database
      const docRes = await setDoc(doc(db, "users", res.user.uid), {
        company: companyName,
        firstName: userName,
        lastName: "",
        email,
        profileUrl: "",
        showModelHub: false,
      });
      //Crerate notification collection for new user
      await setDoc(doc(db, "notifications", res.user.uid), {});
      setShowVerificationAlert(true);
      reset();
      setErr(null);
      setLoading(false);
    } catch (err) {
      console.log("error", err);
      setErr(err.code);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex h-full">
        <div className="animation w-[624px] h-full overflow-hidden login items-start justify-start fpt-[370px] px-20 cpb-[374px] box-border relative min-w-[624px] max-w-full text-left text-29xl text-white font-body-1">
          <div className="login-gradient"></div>
          <div className="m-auto flex flex-col gap-[45px]">
            <div className="mb-3 relative login-shadow">
              <LogoWhite className="subtract-icon" />
            </div>
            <Typography
              variant={TYPOGRAPHY_VARIANT.HEADING_1}
              className="text-white"
            >
              Discover how PryvX can revolutionize your data collaboration
              efforts.
            </Typography>
          </div>
        </div>
        <div className="w-full bg-ui-light-grey overflow-auto h-full flex px-[16px]">
          <div className="text-center lg:w-[455px] m-auto">
            <Typography
              variant={TYPOGRAPHY_VARIANT.HEADING_2}
              className="text-brand-marine-blue"
            >
              Register to the Platform
            </Typography>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="m-auto pt-[20px] flex flex-col gap-[10px]">
                <InputTextField
                  className="relative leading-[16px] inline-block min-w-[83px]"
                  type="text"
                  placeholder="Enter Company"
                  label={
                    <span className="text-brand-marine-blue">Company</span>
                  }
                  errorMessage={errors?.name?.message}
                  isError={!!errors?.name?.message}
                  {...register("name", { required: "Name field is required." })}
                  innerRef={register("name").ref}
                />
                <InputTextField
                  className="relative leading-[16px] inline-block min-w-[83px]"
                  type="email"
                  placeholder="Enter Email"
                  label={<span className="text-brand-marine-blue">Email</span>}
                  errorMessage={errors?.email?.message}
                  isError={!!errors?.email?.message}
                  isSuccess={!errors?.email?.message && getValues("email")}
                  innerRef={register("email").ref}
                  {...register("email", {
                    required: "Email field is required.",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Enter valid Email.",
                    },
                    minLength: {
                      value: 6,
                      message:
                        "Password needs to be more than or equal to 6 characters.",
                    },
                  })}
                />
                <InputTextField
                  className="relative leading-[16px] inline-block min-w-[83px]"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter Password"
                  label={
                    <span className="text-brand-marine-blue">Password</span>
                  }
                  errorMessage={errors?.password?.message}
                  isError={!!errors?.password?.message}
                  innerRef={register("password").ref}
                  {...register("password", {
                    required: "Password is required.",
                  })}
                  passWordEyeIcon={
                    <div
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <HidePasswordIcon />
                      ) : (
                        <ShowPasswordIcon />
                      )}
                    </div>
                  }
                />
                {/* <SelectField
                  className="relative leading-[16px] inline-block min-w-[83px]"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter Password"
                  label={<span className="text-brand-marine-blue">Role</span>}
                  defaultValue={""}
                  errorMessage={errors?.role?.message}
                  isError={!!errors?.role?.message}
                  innerRef={register("role").ref}
                  {...register("role", { required: "Role is required." })}
                  options={[
                    { data: "Select Role", value: "", disabled: true },
                    { data: "Data Owner", value: "Data Owner" },
                    { data: "Model Owner", value: "Model Owner" },
                  ]}
                /> */}
                <Button variant={BUTTON_VARIANT.PRIMARY} type="submit">
                  SIGN UP
                </Button>
                {loading && <OverlayLoader />}
                {err && (
                  <PopupMessage
                    variant={POPUP_VARIANT.WARNING}
                    className="mt-1"
                  >
                    {ERROR_MAPPER[err] ? ERROR_MAPPER[err] : err}
                  </PopupMessage>
                )}
                {showVerificationAlert && (
                  <PopupModal
                    onClose={() => setShowVerificationAlert(false)}
                  ></PopupModal>
                )}
                <div className="flex flex-row items-center justify-center p-2 text-center text-text-marine-blue">
                  <div className="relative">
                    <span className="leading-[16px]">{`Already a user? `}</span>
                    <Link
                      to="/login"
                      className="leading-[24px] font-bold cursor-pointer decoration-none"
                    >
                      LOGIN
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

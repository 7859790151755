export default ({ className = "", id = "", fill, width, height }) => {
  return (
    <svg
      width="180"
      height="32"
      viewBox="0 0 180 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.9549 0H33.8325C33.8369 0 33.8343 0.63745 33.8325 0.956175H41.3921C41.4003 0.956175 41.884 1.80611 42.1249 2.23108H32.5419V3.19565H42.6884L43.421 4.4834H31.2498L31.2457 5.43958H43.9803L44.7172 6.71448H29.9559V7.67373H45.2696L46.0133 8.94555H28.667L24.5737 16L25.4024 16.4675L29.2059 9.90173H30.672L25.8699 18.2311L26.6986 18.7198L31.7769 9.90173H33.2643L27.166 20.4834L27.9947 20.9509L34.3692 9.90173H35.8353L28.4409 22.7145L29.2696 23.1819L36.9403 9.90173H38.4212L29.7371 24.9456L33.8168 32L34.6454 31.5113L30.842 24.9456L31.5857 23.6707L36.3878 32L37.2165 31.5113L32.1381 22.7145L32.8818 21.4396L38.9801 32L39.8088 31.5113L33.4343 20.4622L34.178 19.1873L41.5724 32L42.4011 31.5113L34.7304 18.2311L35.4529 16.9562L44.1434 32H52.2816V31.0438H44.6959L43.9735 29.7689L53.5777 29.7477V28.7915H43.3998L42.6773 27.5166H54.8739V26.5604H42.1143L41.3812 25.2855H56.1633V24.3293H40.8287L40.085 23.0544H57.4449L61.5246 16L60.6959 15.5113L56.8925 22.0983H55.4263L60.2284 13.7689L59.3998 13.2802L54.3214 22.0983H52.834L58.9323 11.5166L58.1036 11.0491L51.7291 22.0983H50.263L57.6361 9.28552L56.8075 8.81806L49.1368 22.0983H47.6707L56.3612 7.05445L52.2816 0L51.4529 0.488712L55.2563 7.05445L54.5126 8.32935L49.6893 0L48.8606 0.488712L53.9602 9.28552L53.2165 10.5604L47.1182 0L46.2895 0.488712L52.664 11.5166L51.9203 12.7915L44.5259 0L43.6972 0.488712L51.3679 13.7689L50.6242 15.0438L41.9549 0ZM39.5325 9.90172H46.5687L50.0717 16L46.5657 22.077H39.5325L36.0265 16L39.5325 9.90172Z"
        fill="white"
      />
      <path
        d="M66.1746 24.7725C65.5428 24.7725 65.2268 24.4514 65.2268 23.8091V7.315C65.2268 6.67271 65.5428 6.35156 66.1746 6.35156H71.2388C74.4885 6.35156 76.7408 6.85255 77.9955 7.85453C79.2593 8.85651 79.8912 10.2781 79.8912 12.1194C79.8912 13.9606 79.2593 15.3822 77.9955 16.3842C76.7408 17.3862 74.4885 17.8871 71.2388 17.8871H69.1806V23.8091C69.1806 24.4514 68.8647 24.7725 68.2328 24.7725H66.1746ZM69.1806 14.7528H71.2253C73.0307 14.7528 74.2583 14.5515 74.9083 14.149C75.5582 13.7379 75.8832 13.0614 75.8832 12.1194C75.8832 11.1773 75.5582 10.5051 74.9083 10.1026C74.2583 9.69149 73.0307 9.48595 71.2253 9.48595H69.1806V14.7528Z"
        fill="white"
      />
      <path
        d="M83.985 24.7725C83.3531 24.7725 83.0371 24.4514 83.0371 23.8091V7.315C83.0371 6.67271 83.3531 6.35156 83.985 6.35156H90.2542C93.0436 6.35156 95.0792 6.76263 96.361 7.58477C97.6428 8.39834 98.2838 9.72574 98.2838 11.567C98.2838 12.9372 97.7647 14.0291 96.7266 14.8427C95.6885 15.6562 94.3706 16.1958 92.7728 16.4613V16.4869C93.6394 16.7952 94.2938 17.2234 94.7361 17.7715C95.1785 18.3196 95.5396 18.7992 95.8194 19.2103L98.7983 23.6678C99.2948 24.4043 99.1233 24.7725 98.2838 24.7725H95.9277C95.4312 24.7725 95.025 24.5199 94.7091 24.0146L91.9739 19.6856C91.676 19.2231 91.1299 18.7178 90.3355 18.1698C89.5501 17.6131 88.7693 17.3348 87.993 17.3348H86.991V23.8091C86.991 24.4514 86.675 24.7725 86.0431 24.7725H83.985ZM86.991 14.149H89.4147C91.2201 14.149 92.502 13.9563 93.2602 13.5709C94.0275 13.177 94.4112 12.5561 94.4112 11.7083C94.4112 10.7834 94.0411 10.1839 93.3008 9.90987C92.5697 9.62726 91.4909 9.48595 90.0647 9.48595H86.991V14.149Z"
        fill="white"
      />
      <path
        d="M99.6047 7.45631C99.1443 6.71981 99.3565 6.35156 100.241 6.35156H102.611C103.08 6.35156 103.495 6.64702 103.856 7.23793L106.741 12.0037C106.822 12.1322 107.061 12.5347 107.458 13.2113C107.855 13.8792 108.086 14.5301 108.149 15.1638H108.203C108.266 14.5301 108.496 13.8792 108.894 13.2113C109.291 12.5347 109.53 12.1322 109.611 12.0037L112.495 7.23793C112.856 6.64702 113.272 6.35156 113.741 6.35156H116.111C116.995 6.35156 117.207 6.71981 116.747 7.45631L110.153 18.0285V24.066C110.153 24.537 109.837 24.7725 109.205 24.7725H107.147C106.515 24.7725 106.199 24.537 106.199 24.066V18.0285L99.6047 7.45631Z"
        fill="white"
      />
      <path
        d="M118.543 7.19939C118.317 6.63417 118.529 6.35156 119.179 6.35156H121.332C121.937 6.35156 122.352 6.64702 122.578 7.23793L126.721 17.9642C126.929 18.5123 127.05 18.8806 127.087 19.069C127.123 19.2488 127.163 19.5528 127.208 19.981H127.263C127.308 19.5528 127.348 19.2488 127.384 19.069C127.421 18.8806 127.542 18.5123 127.75 17.9642L131.893 7.23793C132.119 6.64702 132.534 6.35156 133.139 6.35156H135.292C135.942 6.35156 136.154 6.63417 135.929 7.19939L129.456 23.732C129.185 24.4257 128.761 24.7725 128.183 24.7725H126.288C125.71 24.7725 125.286 24.4257 125.015 23.732L118.543 7.19939Z"
        fill="white"
      />
      <path
        d="M138.34 24.7725C137.473 24.7725 137.302 24.4043 137.825 23.6678L143.824 15.2666L138.313 7.45631C137.789 6.71981 137.961 6.35156 138.827 6.35156H141.563C142.041 6.35156 142.447 6.6042 142.781 7.10947L146.478 12.6589L150.174 7.10947C150.508 6.6042 150.915 6.35156 151.393 6.35156H154.128C154.995 6.35156 155.166 6.71981 154.643 7.45631L149.118 15.2666L155.13 23.6678C155.654 24.4043 155.482 24.7725 154.616 24.7725H151.84C151.37 24.7725 150.964 24.5199 150.621 24.0146L146.478 17.8743L142.334 24.0146C141.991 24.5199 141.585 24.7725 141.116 24.7725H138.34Z"
        fill="white"
      />
    </svg>
  );
};

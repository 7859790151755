export const copyClientCode = (id) => {
  const textElement = document.getElementById(id);
  // Create a temporary textarea element
  const textArea = document.createElement("textarea");
  // Set the value of the textarea to the text content
  textArea.value = textElement.textContent.replace(/\\n/g, "\n");
  textArea.value = textArea.value.replace(/\\t/g, "\t");
  // Append the textarea to the body
  document.body.appendChild(textArea);
  // Select the text inside the textarea
  textArea.select();
  // Copy the selected text
  document.execCommand("copy");
  // Remove the textarea from the body
  document.body.removeChild(textArea);
};

export const convertBase64ToFile = function (image) {
  const byteString = atob(image.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: "image/jpeg",
  });
  return newBlob;
};

export const getMonthName = (intMonth) => {
  switch (intMonth) {
    case 0:
      return "Jan";
    case 1:
      return "Fab";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "Aug";
    case 8:
      return "Sept";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      console.error("Wrong Month error.");
  }
};

export default ({
  className = "",
  id = "",
  fill = "#A0CCFB",
  width = "8",
  height = "8",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4.13672" r="4" fill={fill} />
    </svg>
  );
};

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "pryvx-platform.firebaseapp.com",
  projectId: "pryvx-platform",
  storageBucket: "pryvx-platform.appspot.com",
  messagingSenderId: "878783007469",
  appId: "1:878783007469:web:48fa5b734fbbe5b4c29ae4",
  measurementId: "G-09KYFZN4VZ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);

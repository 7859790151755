import React from "react";

export const TYPOGRAPHY_VARIANT = {
  HEADING_1: "HEADING_1",
  HEADING_2: "HEADING_2",
  HEADING_3: "HEADING_3",
  HEADING_4: "HEADING_4",
  SUB_HEADING_3: "SUB_HEADING_3",
  SUB_HEADING_4: "SUB_HEADING_4",
  SUB_HEADING_5: "SUB_HEADING_5",
  MOBILE_HEADING_1: "MOBILE_HEADING_1",
  MOBILE_HEADING_2: "MOBILE_HEADING_2",
  MOBILE_HEADING_3: "MOBILE_HEADING_3",
  MOBILE_HEADING_4: "MOBILE_HEADING_4",
  BODY_1: "BODY_1",
  BODY_2: "BODY_2",
  BODY_3: "BODY_3",
  BODY_BOLD_1: "BODY_BOLD_1",
  BODY_BOLD_2: "BODY_BOLD_2",
  BODY_BOLD_3: "BODY_BOLD_3",
  LABEL_REGULAR: "LABEL_REGULAR",
  LABEL_BOLD: "LABEL_BOLD",
  LABEL_LARGE_REGULAR: "LABEL_LARGE_REGULAR",
  LABEL_LARGE_BOLD: "LABEL_LARGE_BOLD",
  LABEL_BUTTON_REGULAR: "LABEL_BUTTON_REGULAR",
};

const TYPOGRAPHY_CLASSES = {
  HEADING_1:
    "m-0 flex-1 relative leading-[56px] font-bold font-inherit inline-block max-w-full z-[1] text-[48px] text-avenir",
  HEADING_2:
    "m-0 flex-1 relative leading-[48px] font-bold font-inherit inline-block max-w-full z-[1] text-[40px] text-avenir",
  HEADING_3:
    "m-0 flex-1 relative leading-[40px] font-bold font-inherit inline-block max-w-full z-[1] text-[32px] text-avenir",
  HEADING_4:
    "m-0 flex-1 relative leading-[32px] font-bold font-inherit inline-block max-w-full z-[1] text-[24px] text-avenir",
  SUB_HEADING_3:
    "m-0 flex-1 relative leading-[40px] font-medium font-inherit inline-block max-w-full z-[1] text-[32px] text-avenir",
  SUB_HEADING_4:
    "m-0 flex-1 relative leading-[32px] font-medium font-inherit inline-block max-w-full z-[1] text-[24px] text-avenir",
  SUB_HEADING_5:
    "m-0 flex-1 relative leading-[32px] font-normal font-inherit inline-block max-w-full z-[1] text-[20px] text-avenir",
  MOBILE_HEADING_1:
    "m-0 flex-1 relative leading-[46px] font-bold font-inherit inline-block max-w-full z-[1] text-[39px] text-avenir",
  MOBILE_HEADING_2:
    "m-0 flex-1 relative leading-[39px] font-bold font-inherit inline-block max-w-full z-[1] text-[32px] text-avenir",
  MOBILE_HEADING_3:
    "m-0 flex-1 relative leading-[32px] font-bold font-inherit inline-block max-w-full z-[1] text-[26px] text-avenir",
  MOBILE_HEADING_4:
    "m-0 flex-1 relative leading-[26px] font-bold font-inherit inline-block max-w-full z-[1] text-[20px] text-avenir",
  BODY_1:
    "m-0 flex-1 relative leading-[24px] font-normal font-inherit inline-block max-w-full z-[1] text-[16px] text-avenir",
  BODY_2:
    "m-0 flex-1 relative leading-[20px] font-normal font-inherit inline-block max-w-full z-[1] text-[13px] text-avenir",
  BODY_3:
    "m-0 flex-1 relative leading-[16px] font-normal font-inherit inline-block max-w-full z-[1] text-[10px] text-avenir",
  BODY_BOLD_1:
    "m-0 flex-1 relative leading-[24px] font-bold font-inherit inline-block max-w-full z-[1] text-[16px] text-avenir",
  BODY_BOLD_2:
    "m-0 flex-1 relative leading-[20px] font-bold font-inherit inline-block max-w-full z-[1] text-[13px] text-avenir",
  BODY_BOLD_3:
    "m-0 flex-1 relative leading-[16px] font-bold font-inherit inline-block max-w-full z-[1] text-[10px] text-avenir",
  LABEL_REGULAR:
    "m-0 flex-1 relative leading-[24px] font-medium font-inherit inline-block max-w-full z-[1] text-[13px] text-avenir",
  LABEL_BOLD:
    "m-0 flex-1 relative leading-[24px] font-bold font-inherit inline-block max-w-full z-[1] text-[13px] text-avenir",
  LABEL_LARGE_REGULAR:
    "m-0 flex-1 relative leading-[28px] font-medium font-inherit inline-block max-w-full z-[1] text-[16px] text-avenir",
  LABEL_LARGE_BOLD:
    "m-0 flex-1 relative leading-[28px] font-bold font-inherit inline-block max-w-full z-[1] text-[16px] text-avenir",
  LABEL_BUTTON_REGULAR:
    "m-0 flex-1 relative leading-[16px] font-medium font-inherit inline-block max-w-full z-[1] text-[16px] text-avenir",
};

const Typography = ({ variant, children, className = "" }) => {
  if (!Object.values(TYPOGRAPHY_VARIANT).includes(variant)) {
    console.error(
      "You need to access Typography variant from TYPOGRAPHY_VARIANT"
    );
    return "";
  }

  switch (variant) {
    case "HEADING_1":
      return (
        <h1 className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </h1>
      );
    case "HEADING_2":
    case "MOBILE_HEADING_1":
      return (
        <h2 className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </h2>
      );
    case "HEADING_3":
    case "MOBILE_HEADING_2":
      return (
        <h3 className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </h3>
      );
    case "HEADING_4":
    case "MOBILE_HEADING_3":
      return (
        <h4 className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </h4>
      );
    case "SUB_HEADING_3":
    case "MOBILE_HEADING_4":
      return (
        <h5 className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </h5>
      );
    case "SUB_HEADING_4":
      return (
        <h6 className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </h6>
      );
    case "SUB_HEADING_5":
      return (
        <h6 className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </h6>
      );
    case "BODY_1":
    case "BODY_2":
    case "BODY_3":
    case "BODY_BOLD_1":
    case "BODY_BOLD_2":
    case "BODY_BOLD_3":
      return (
        <p className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </p>
      );
    case "LABEL_REGULAR":
    case "LABEL_BOLD":
    case "LABEL_LARGE_REGULAR":
    case "LABEL_LARGE_BOLD":
    case "LABEL_BUTTON_REGULAR":
      return (
        <span className={`${TYPOGRAPHY_CLASSES[variant]} ${className}`}>
          {children}
        </span>
      );
  }
};

export default Typography;
